import Property from "src/interfaces/property";

function getDebtStackPDFfileName(title?: string, address?: string) {
    const filename = title?.split("/").pop();
    const pdfFileName = (`${address || filename}`).replace(/\s/g, "_");
    return pdfFileName ? pdfFileName : '';
}


function getOfferPDFfilename(offerID: number, property: Property) {
  const address = property.fullAddress.replace(/\s/g, "_").replace(/,/g, "");

  console.log(address);
  return `Offer_${address}`
}
  
export { getDebtStackPDFfileName, getOfferPDFfilename };