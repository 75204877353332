import { ReactNode } from "react";
import getHostName from "src/helpers/getAppFromHost";
import useHelpDrawer from "src/hooks/private/useHelpDrawer";
import useApp from "src/hooks/useAppContext";

const TeamHelp = () => {
  const { setShowHelpDrawer, openHelpDrawerWith } = useHelpDrawer();

  const { app } = useApp();

  const handleHelp = (title: string, body: ReactNode) => {
    setShowHelpDrawer(false);
    openHelpDrawerWith({
      title: title,
      body: body,
    });
  };

  // !TODO: handle the Platinum and Pro case here
  return (
    <>
      <div>
        <p>
          Team's on Pro and Platinum level subscriptions are able to invite other users to join their team. Your new teammate will receive an email from {app?.name || getHostName()} inviting them to register. 
          </p>
          <br></br>
          <p>
          Once invited Team Leaders may adjust the permissions of each teammate. Permissions include: Adding to Watchlist, Researching, updating Property Status, Skip Tracing, ordering Debt Stacks, sending Sequences, creating Offers, making Purchases, and Inviting other teammates. You may also deactivate a teammate to disallow them from logging in to your team until reactivated.
        </p>
      </div>
    </>
  )
};

export default TeamHelp;
