import PropertyType from "src/interfaces/property";
import { parseMonthDayYear } from "src/helpers/parseEpochs";
import useLoanContext from "src/hooks/private/useLoanContext";
import useViewport from "src/hooks/useViewport";

interface LoanTableProps {
  property: PropertyType;
}
const LoansTable = ({ property }: LoanTableProps) => {
  const { loans } = useLoanContext();

  const { isMobile } = useViewport();

  const propertyLoans = (loans || []).filter(
    (loan) => loan.propertyID === property.id
  );

  return (
    <>
      <table
        className={
          isMobile
            ? "table-compact block table w-full rounded-lg shadow-lg"
            : "block table w-full rounded-lg shadow-lg"
        }
      >
        <thead>
          <tr className="border-b-2 border-secondary text-secondary">
            <th className="bg-card-light text-xs dark:bg-card-dark sm:text-sm">
              Position
            </th>
            <th className="bg-card-light text-xs dark:bg-card-dark sm:text-sm">
              Date
            </th>
            <th className="bg-card-light text-xs dark:bg-card-dark sm:text-sm">
              Loan
            </th>
          </tr>
        </thead>
        <tbody className="">
          {propertyLoans.length ? (
            propertyLoans
              .sort((a: any, b: any) => a.position - b.position)
              ?.map((loan, index) => (
                <tr className="" key={index}>
                  <td className="bg-card-light text-xs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light sm:text-sm">
                    {loan.position || "-"}
                  </td>
                  <td className="bg-card-light text-xs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light sm:text-sm">
                    {parseMonthDayYear(loan.date)
                      ? parseMonthDayYear(loan.date)
                      : "?"}
                  </td>
                  <td className="bg-card-light text-xs font-semibold text-text-dark dark:bg-card-dark  dark:text-text-light sm:text-sm">
                    $
                    {loan.amount
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                </tr>
              ))
          ) : (
            <tr>
              <td
                className="bg-card-light text-center text-xs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light xs:text-sm sm:text-base"
                colSpan={3}
              >
                No loans found for this property
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default LoansTable;
