import { Helmet } from "react-helmet";
import Spinner from "./components/loading/Spinner";
import Routes from "./routes";
import useApp from "./hooks/useAppContext";
import getHostName from "./helpers/getAppFromHost";

function App() {
  const { app } = useApp();
  return (
    <>
      <Helmet>
        <title>{app?.name || getHostName()}</title>
      </Helmet>
      <Spinner />
      <Routes />
    </>
  );
}

export default App;
