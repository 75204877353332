import useUserContext from "src/hooks/private/useUserContext";
import { userApi } from "src/api";
import { useState } from "react";
import useViewport from "src/hooks/useViewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

const UserName = () => {
  const { user, userSettings, fetchUser } = useUserContext();

  const { width } = useViewport();

  const [isTooltips, setIsTooltips] = useState<boolean>(
    userSettings?.isTooltips || true
  );

  const handleToggleTooltips = async () => {
    if (user) {
      const resp = await userApi.updateSettings(user?.id, {
        isTooltips: !isTooltips,
      });
      setIsTooltips(resp.isTooltips);
      fetchUser();
    }
  };
  return (
    <>
      <div className="dropdown-end dropdown-hover dropdown">
        <label
          tabIndex={0}
          className="btn-ghost btn mr-2 select-none text-xl font-bold text-primary hover:bg-hover-back-light dark:hover:bg-hover-back-dark"
        >
          {width < 1280 || !user?.firstName || !user?.lastName ? (
            <FontAwesomeIcon className="text-primary" icon={faUser} />
          ) : (
            `${user?.firstName} ${user?.lastName}`
          )}
        </label>
        <ul
          tabIndex={0}
          className="dropdown-content menu rounded-box mt-0.5 w-52 border-2 border-primary bg-card-light p-2 text-primary drop-shadow-xl dark:bg-card-dark md:dark:bg-card-dark"
        >
          <li className="mb-1">
            <button
              onClick={handleToggleTooltips}
              className="btn-ghost btn font-bold hover:bg-hover-card-light dark:hover:bg-hover-card-dark"
            >
              {isTooltips ? "Disable Tips" : "Enable Tips"}
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default UserName;
