
import { useNavigate } from "react-router-dom";
import { watchlistApi } from "src/api";
import useTeamContext from "src/hooks/private/useTeamContext";
import useUserContext from "src/hooks/private/useUserContext";
import useWatchlist from "src/hooks/private/useWatchlist";
import useAlert from "src/hooks/useAlert";
import useModal from "src/hooks/useModal";
import Button from "../buttons/Button";
import ResearchAddressButton from "../buttons/ResearchAddressButton";

interface ResearchModalProps {
  address: string;
  placeID: string;
  showWatchlist?: boolean;
}

const ResearchModal = ({
  address,
  placeID,
  showWatchlist,
}: ResearchModalProps) => {
  const { setShowModal } = useModal();

  const { fetchWatchlist } = useWatchlist();

  const { setAlert } = useAlert();

  const { user } = useUserContext();

  const { team } = useTeamContext();

  const navigate = useNavigate();

  const handleAddToWatchlist = async () => {
    setShowModal(false);
    try {
      const watchlist = await watchlistApi.createWatchlist({
        userID: user?.id,
        teamID: team?.id,
        fullAddress: address,
        placeID: placeID,
      });
      if (watchlist) {
        setAlert({
          display: true,
          message: "Address added to Watchlist",
          type: "success",
        });
      }
      fetchWatchlist();
      navigate("/watchlist");
    } catch (err) {
      setAlert({
        display: true,
        message: "An error occurred adding this address to Watchlist",
        type: "error",
      });
    }
  };

  return (
    <>
      <ResearchAddressButton address={address} placeID={placeID}/>
      {showWatchlist && (
        <div className="w-full mt-4">
          <Button
            className="btn btn-ghost text-lg text-text-dark dark:text-text-light hover:bg-secondary hover:text-text-light hover:dark:bg-secondary hover:dark:text-black font-bold mr-1 w-64"
            onClick={handleAddToWatchlist}
          >
            Add To Watchlist
          </Button>
        </div>
      )}
    </>
  );
};

export default ResearchModal;
