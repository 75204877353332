import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { campaignApi } from "src/api";
import { Campaign } from "src/interfaces/campaign";
import useCampaignContext from "src/hooks/private/useCampaignContext";
import useUserContext from "src/hooks/private/useUserContext";
import useAlert from "src/hooks/useAlert";
import SequenceCadence from "../SequenceCadence";
import SequenceQuantity from "../SequenceQuantity";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TemplateSummary from "../TemplateSummary";
import {
  faArrowRight,
  faFloppyDisk,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import CampaignBuilder from "./CampaignBuilder";
import useCampaignTemplate, {
  CampaignTemplateForm,
} from "src/hooks/private/useCampaignTemplate";

const EditCampaign = () => {
  const { campaignID } = useParams();

  const { fetchCampaigns } = useCampaignContext();

  const { user } = useUserContext();

  const { setAlert } = useAlert();

  const navigate = useNavigate();

  const [campaign, setCampaign] = useState<Campaign | null>(null);

  useEffect(() => {
    const fetchCampaign = async () => {
      if (campaignID) {
        try {
          const resp = await campaignApi.getByID(parseInt(campaignID));
          setCampaign(resp);
          setFormData({
            campaignName: resp?.name || "",
            numLetters: resp?.quantity || 0,
            numDays: resp?.cadence || 0,
            selectedTemplates: resp?.templateIDs || [],
            target: resp?.target
          });
        } catch (e) {
          return navigate("/sequences");
        }
      }
    };
    fetchCampaign();
  }, [campaignID]);

  const initialState: CampaignTemplateForm = {
    campaignName: campaign?.name || "",
    numLetters: campaign?.quantity || 0,
    numDays: campaign?.cadence || 0,
    selectedTemplates: campaign?.templateIDs || [],
    target: campaign?.target || "absentee",
  };

  const {
    formData,
    setFormData,
    dateStrings,
    handleChange,
    validationError,
    handleTemplateSelection,
  } = useCampaignTemplate(initialState);

  const handleSubmit = async (type: "save" | "new") => {
    if (!validationError) {
      const { campaignName, numLetters, numDays, selectedTemplates } = formData;
      try {
        if (user && campaign) {
          if (type === "save") {
            await campaignApi.edit({
              id: campaign.id,
              name: campaignName,
              teamID: user.teamID,
              userID: user.id,
              cadence: numDays,
              quantity: numLetters,
              target: campaign.target,
              templateIDs: Object.values(selectedTemplates),
            });
            setAlert({
              type: "success",
              message: `Campaign: ${campaignName} has been saved`,
              display: true,
            });
          } else {
            await campaignApi.create({
              name: campaignName,
              teamID: user.teamID,
              userID: user.id,
              cadence: numDays,
              quantity: numLetters,
              target: campaign.target,
              templateIDs: Object.values(selectedTemplates),
            });
          }
        }
      } catch (error: any) {
        setAlert({
          type: "error",
          message: error?.message ?? "Campaign edit failed to save",
          display: true,
        });
      } finally {
        fetchCampaigns();
        navigate("/sequences/campaigns");
        setAlert({
          type: "success",
          message: `Campaign: ${campaignName} has `,
          display: true,
        });
      }
    } else {
      setAlert({
        display: true,
        type: "warning",
        message: "Please select a template for each piece of mail.",
      });
    }
  };

  return (
    <>
      {campaign && (
        <>
          <div className="mb-2 rounded-md bg-card-light shadow-xl dark:bg-card-dark">
            <div className="flex w-full items-center justify-between py-1 md:justify-around lg:justify-center lg:gap-12">
              <div className="px-4">
                <span className="text-sm font-bold  text-secondary xs:text-base sm:text-xl">
                  Edit Campaign
                </span>
              </div>
              <div>
                <FontAwesomeIcon
                  className="text-icon-light dark:text-icon-dark"
                  icon={faArrowRight}
                />
              </div>
              <div className="flex items-center justify-center">
                <input
                  type="text"
                  name="campaignName"
                  placeholder="Campaign Name"
                  className="input-bordered input-secondary input z-0 w-full bg-back-light font-medium text-text-dark placeholder:text-icon-light  dark:bg-back-dark dark:text-text-light lg:w-80"
                  value={formData.campaignName}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="mb-2 flex w-full flex-col gap-2 lg:flex-row">
            <SequenceQuantity
              quantity={formData.numLetters}
              onChange={handleChange}
            />
            <SequenceCadence
              cadence={formData.numDays}
              onChange={handleChange}
            />
          </div>
          <CampaignBuilder
            quantity={formData.numLetters}
            type={campaign.target}
            onTemplateSelect={handleTemplateSelection}
            selectedTemplates={formData.selectedTemplates}
          />
          <TemplateSummary
            templateIDs={formData.selectedTemplates}
            sendDays={dateStrings}
          />
          <div className="mb-20 flex w-full items-center justify-center gap-2 lg:mb-0">
            <button
              className="btn-primary btn mb-2 flex-grow gap-2 text-lg"
              onClick={() => handleSubmit("new")}
            >
              <FontAwesomeIcon icon={faPlus} />
              <span className="">Create New</span>
            </button>
            <button
              className="btn-primary btn mb-2 flex-grow gap-2 text-lg"
              onClick={() => handleSubmit("save")}
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
              <span className="">Save Edit</span>
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default EditCampaign;
