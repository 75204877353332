import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { teamApi, userApi } from "src/api";
import { TokenContext } from "src/contexts/TokenGuardContext";

const useTokenContext = () => useContext(TokenContext);

const TOKEN_KEY = "register_token";

const useTokenGuard = (purpose?: string) => {
  const { token } = useParams();

  const { tokenData, email, setState } = useTokenContext();

  const [storedToken, setStoredToken] = useState({
    token,
    purpose,
  });
  const [onChecking, setOnChecking] = useState(true);
  const [redirection, setRedirectTo] = useState<string>("");

  const verifyToken = useCallback(
    async (mainPurpose) => {
      if (!storedToken && mainPurpose) {
        setOnChecking(false);
        return;
      }

      const { token, purpose } = storedToken;

      if (!token || !purpose || tokenData) {
        return;
      }

      let result = null;

      try {
        switch (purpose) {
          case "invite-to-v2":
            result = await userApi.verifyInvitationTokenToV2(token);
            break;
          case "invite-to-demo":
            result = await teamApi.verifyDemoModeToken(token);
            break;
          case "failed-payment":
            result = await teamApi.verifyUpdateCardToken(token);
            break;
        }
        if (result) {
          const { redirectTo, email, ...rest } = result;

          setState(email, purpose, {
            subscriptionID: 0,
            ...rest,
          });

          if (redirectTo) {
            setRedirectTo(redirectTo);
          }
        }
      } catch (e) {
        setRedirectTo("/invalid-token");
      } finally {
        setOnChecking(false);
      }
    },
    [storedToken, tokenData, setState]
  );

  const checkExistingEmailInLocalStorage = useCallback(() => {
    const localToken = localStorage.getItem(TOKEN_KEY);

    if (!token && localToken) {
      return setStoredToken(JSON.parse(localToken));
    }
    if (token && purpose) {
      localStorage.setItem(TOKEN_KEY, JSON.stringify({ token, purpose }));
    }
  }, [token, purpose]);

  useEffect(() => {
    checkExistingEmailInLocalStorage();
  }, [checkExistingEmailInLocalStorage]);

  useEffect(() => {
    verifyToken(purpose);
  }, [verifyToken]);

  return {
    tokenData,
    email,
    token,
    onChecking,
    storedToken,
    redirection,
  };
};

export default useTokenGuard;
