import useHelpDrawer from "src/hooks/private/useHelpDrawer";
import SequencesHelp from "./SequencesHelp";
import useAuth from "src/hooks/useAuth";

const CancelSequenceHelp = () => {
  const { helpLink } = useHelpDrawer();

  const { currentUser } = useAuth();

  return (
    <>
      <p>
        All future postcards in this{" "}
        <span
          className="font-bold hover:underline text-primary cursor-pointer"
          onClick={() => helpLink("Sequences", <SequencesHelp />)}
        >
          Sequence
        </span>{" "}
        will no longer be sent. Your team's account will not be refunded the
        difference in {currentUser?.coinName} from the cancellation.
      </p>
    </>
  );
};

export default CancelSequenceHelp;
