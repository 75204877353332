import { forwardRef, useEffect } from "react";
// import useViewport from "src/hooks/useViewport";
import loadImage from "./loadImage";

interface SuspenseImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  noCache?: boolean;
  defaultImg?: string;
}

const SuspenseImage = forwardRef<HTMLImageElement, SuspenseImageProps>(
  ({ noCache, defaultImg, ...props }: SuspenseImageProps, ref) => {
    // const { isMobile } = useViewport();
    loadImage(props.src as string, noCache, defaultImg).read();
    return (
        <img className={"w-full h-full"} {...props} ref={ref} alt={props.alt} />
    );
  }
);

export default SuspenseImage;
