import React, { ChangeEventHandler } from "react";
import Property from "src/interfaces/property";
import { Option } from "./SelectInput";
import "src/css/removeSafariInputOutline.css";

interface SelectOwnerProps {
  property: Property;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
}

const SelectOwner = ({ property, onChange }: SelectOwnerProps) => {
  const { owner1, owner2 } = property;

  const createOptions = () => {
    let options: Option[] = [];

    if (owner1 !== undefined && owner1.length > 1) {
      options.push({ label: owner1, value: owner1 });
    }

    if (owner2 !== undefined && owner2.length > 1) {
      options.push({ label: owner2, value: owner2 });
    }
    return options.map((opt: Option) => (
      <option key={`op-${opt.value}`} value={opt.value}>
        {opt.label}
      </option>
    ));
  };

  return (
    <>
      <select
        name={"owner"}
        className="select-secondary select h-[20px] rounded-lg bg-back-light text-text-dark dark:bg-base-100 dark:text-text-light w-full"
        onChange={onChange}
      >
        {createOptions()}
      </select>
    </>
  );
};

export default SelectOwner;
