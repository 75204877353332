import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import watchlistApi from "src/api/watchlist";
import { Watchlist } from "src/interfaces/watchlist";
import { FilterParams } from "src/global/types";
import useErrorHandler from "src/hooks/private/useErrorHandler";
import useUserContext from "src/hooks/private/useUserContext";

interface WatchlistContextInterface {
  watchlist: Watchlist[] | null | undefined;
  fetchWatchlist: () => void;
}

const WatchlistContextDefaults: WatchlistContextInterface = {
  watchlist: null,
  fetchWatchlist: () => {},
};

const WatchlistContext = React.createContext<WatchlistContextInterface>(
  WatchlistContextDefaults
);

interface WatchlistProviderProps {}

export const WatchlistProvider = ({
  children,
}: PropsWithChildren<WatchlistProviderProps>) => {
  const [watchlist, setWatchlist] = useState<Watchlist[]>();

  const { user } = useUserContext();

  const { handleError } = useErrorHandler();

  const fetchWatchlist = useCallback(async () => {
    if (user) {
      try {
        const watchlist = await watchlistApi.getWatchlist();
        setWatchlist(watchlist);
      } catch (err) {
        handleError(err);
      }
    }
  }, [user, handleError]);

  useEffect(() => {
    fetchWatchlist();
  }, [fetchWatchlist]);

  return (
    <WatchlistContext.Provider
      value={{
        watchlist,
        fetchWatchlist,
      }}
    >
      {children}
    </WatchlistContext.Provider>
  );
};

export default WatchlistContext;
