import React from "react";
import AnimatedNumber from "react-awesome-animated-number";
import useAuth from "src/hooks/useAuth";

interface CoinDisplayProps {
  initialCoins: number;
  recurringCoins: number;
  isAnnual: boolean;
}

const CoinDisplay = ({
  initialCoins,
  recurringCoins,
  isAnnual,
}: CoinDisplayProps) => {
  const { currentUser } = useAuth();

  return (
    <>
      <div className="flex columns-1 flex-col rounded-lg border border-secondary py-1.5">
        <div className="relative flex w-full items-center justify-center">
          <div className="btn-secondary btn-xs btn absolute -top-[22px] z-50 w-2/3 normal-case text-text-light shadow-md">
            {currentUser?.coinName}
          </div>
        </div>
        <div className="flex items-end justify-center">
          <h2 className="mr-1 text-lg font-extrabold text-black dark:text-white">
          <AnimatedNumber
              className=""
              value={initialCoins}
              size={17}
              duration={200}
            />
          </h2>
          <div>
            <p className="mt-1 text-gray-500">/ instant</p>
          </div>
        </div>
        <div className="flex items-end justify-center">
          <h2 className="mr-1 text-lg font-extrabold text-black dark:text-white">
            <AnimatedNumber
              className=""
              value={recurringCoins}
              size={17}
              duration={200}
            />
          </h2>
          <div>
            <p className="mt-1 text-gray-500">
              / {isAnnual ? "year" : "month"}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoinDisplay;
