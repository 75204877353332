import AnimatedNumber from "react-awesome-animated-number";
import classNames from "classnames";
import { ReactNode } from "react";

interface LabeledNumDisplayProps {
  label: string;
  value: number;
  bgLight: string;
  bgDark: string;
  unit?: string;
  size?: number;
  duration?: number;
  ref?: any;
  className?: string;
  hasComma?: boolean;
  prefix?: ReactNode;
}

const LabeledNumberDisplay = ({
  label,
  value,
  unit,
  size,
  duration,
  bgLight,
  bgDark,
  ref,
  className,
  hasComma = false,
  prefix,
}: LabeledNumDisplayProps) => {
  const _className = classNames(
    className,
    "relative w-full border rounded-[4px] flex items-center justify-center h-full"
  );

  return (
    <div className={_className}>
      {prefix && (
        <div className="absolute top-[10px] -left-[5px] z-50 bg-back-light dark:bg-base-100 text-text-dark dark:text-text-light">
          {prefix}
        </div>
      )}
      <div
        className={classNames(
          "absolute -top-2 left-2 h-[10px] px-1 text-text-dark dark:text-text-light",
          bgLight,
          bgDark
        )}
      >
        <p className="text-xxs font-bold dark:font-medium select-none">
          {label}
        </p>
      </div>
      <AnimatedNumber
        ref={ref}
        className="font-semibold text-text-dark dark:text-text-light select-none"
        value={value}
        size={size || 17}
        duration={duration || 200}
        hasComma={hasComma}
      />
      {unit && (
        <div>
          <span className="text-text-dark dark:text-text-light ml-1 text-xxs select-none">
            {unit}
          </span>
        </div>
      )}
    </div>
  );
};

export default LabeledNumberDisplay;
