import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { teamApi } from "src/api";
import { UserType as User } from "src/interfaces/user";
import { Team } from "src/interfaces/team";
import useUserContext from "src/hooks/private/useUserContext";
import useErrorHandler from "src/hooks/private/useErrorHandler";
import { TeamSettings } from "src/interfaces/teamSettings";

interface TeamContextInterface {
  team: Team | null | undefined;
  teamSettings: TeamSettings | null | undefined;
  teammates: User[];
  teamUsers: User[];
  fetchUserTeamData: () => void;
  fetchTeammates: () => void;
  fetchTeamSettings: () => void;
  fetchTeamUsers: () => void;
  checkCoinBalance: (coins: number) => boolean;
}

const teamContextDefaults: TeamContextInterface = {
  team: null,
  teamSettings: null,
  fetchUserTeamData: () => {},
  teammates: [],
  teamUsers: [],
  fetchTeamUsers: () => {},
  fetchTeammates: () => {},
  fetchTeamSettings: () => {},
  checkCoinBalance: () => false,
};

const TeamContext =
  React.createContext<TeamContextInterface>(teamContextDefaults);

interface TeamProviderProps {}

export const TeamProvider = ({
  children,
}: PropsWithChildren<TeamProviderProps>) => {
  const [team, setTeam] = useState<Team>();
  const [teammates, setTeammates] = useState<User[]>([]);
  const [teamSettings, setTeamSettings] = useState<TeamSettings>();
  const [teamUsers, setTeamUsers] = useState<User[]>([]);

  const { user } = useUserContext();

  const { handleError } = useErrorHandler();

  const fetchUserTeamData = useCallback(async () => {
    if (user) {
      try {
        const team = await teamApi.getTeam(user?.teamID);
        setTeam(team);
      } catch (err) {
        handleError(err);
      }
    }
  }, [user, handleError]);

  const fetchTeammates = useCallback(async () => {
    try {
      const teammates = await teamApi.getTeammates();
      setTeammates(teammates);
    } catch (err) {
      handleError(err);
    }
  }, [handleError]);

  const fetchTeamUsers = useCallback(async () => {
    try {
      const teamUsers = await teamApi.getTeamUsers();
      setTeamUsers(teamUsers);
    } catch (err) {
      handleError(err);
    }
  }, [handleError]);

  const fetchTeamSettings = useCallback(async () => {
    if (user) {
      try {
        const teamSettings = await teamApi.getTeamSettings(user?.teamID);
        setTeamSettings(teamSettings);
      } catch (err) {
        handleError(err);
      }
    }
  }, [user, handleError]);

  const checkCoinBalance = (coins: number) => {
    if (team?.isDemo) {
      return true;
    }
    if (team?.isVacation) {
      return (team?.coinsPurchased || 0) > coins;
    }
    const totalCoins = team
      ? (team?.coinsSubscription || 0) + (team?.coinsPurchased || 0)
      : 0;
    return totalCoins >= coins;
  };

  useEffect(() => {
    fetchUserTeamData();
  }, [fetchUserTeamData]);

  useEffect(() => {
    fetchTeammates();
  }, [fetchTeammates]);

  useEffect(() => {
    fetchTeamUsers();
  }, [fetchTeamUsers]);

  useEffect(() => {
    fetchTeamSettings();
  }, [fetchTeamSettings]);

  return (
    <TeamContext.Provider
      value={{
        team,
        teammates,
        teamUsers,
        fetchTeamUsers,
        teamSettings,
        fetchUserTeamData,
        fetchTeammates,
        fetchTeamSettings,
        checkCoinBalance,
      }}
    >
      {children}
    </TeamContext.Provider>
  );
};

export default TeamContext;
