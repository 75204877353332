import React from "react";
import Property from "src/interfaces/property";
import ZillowIcon from "src/assets/external/ZillowIcon.png";
import GoogleMapsIcon from "src/assets/external/GoogleMapsIcon.png";
import AppleMapsIcon from "src/assets/external/AppleMapsIcon.png";
import GoogleSearchIcon from "src/assets/external/GoogleSearchIcon.png";
import classnames from "classnames";

interface ExternalLinkProps {
  href: string;
  icon: string;
  alt: string;
  dataTip: string;
  dimension: number
  className?: string;
}

const ExternalLink = ({ href, icon, alt, dataTip, dimension, className }: ExternalLinkProps) => {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={href}
      className={classnames("tooltip", className)}
      data-tip={dataTip}
    >
      <img src={icon} width={dimension} height={dimension} alt={alt}></img>
    </a>
  );
};

interface ExternalToolbarProps {
  property: Property;
}

const ExternalToolbar = ({ property }: ExternalToolbarProps) => {
  const {
    houseNumber,
    preDirectional,
    street,
    suffix,
    city,
    state,
    zipcode,
    placeId,
  } = property;
  const createZillowLink = () => {
    return `https://zillow.com/homes/${houseNumber}-${preDirectional}-${street}-${suffix}-${city},-${state}-${zipcode}`;
  };

  const createGoogleMapsLink = () => {
    return `https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${placeId}`;
  };

  const createGoogleSearchLink = () => {
    return `https://www.google.com/search?q=${houseNumber}+${street}+${suffix}+${city}+${state}+${zipcode}`;
  };

  const createAppleMapsLink = () => {
    return `http://maps.apple.com/?address=${houseNumber},${street}+${suffix},${city},${state}`;
  }
  return (
    <>
      <div className="flex items-center justify-between">
        <ExternalLink
          href={createZillowLink()}
          icon={ZillowIcon}
          alt={"Open Zillow"}
          dataTip={"Zillow"}
          dimension={65}
        />
        <ExternalLink
          href={createGoogleSearchLink()}
          icon={GoogleSearchIcon}
          alt={"Google Search"}
          dataTip={"Search Google"}
          dimension={53}
          className=" rounded-full"
        />
        <ExternalLink
          href={createGoogleMapsLink()}
          icon={GoogleMapsIcon}
          alt={"Open In Google Maps"}
          dataTip={"Google Maps"}
          dimension={55}
          className="mb-[5px]"
        />
        <ExternalLink
          href={createAppleMapsLink()}
          icon={AppleMapsIcon}
          alt={"Open In Apple Maps"}
          dataTip={"Apple Maps"}
          dimension={51}
          className=""
        />
      </div>
    </>
  );
};

export default ExternalToolbar;
