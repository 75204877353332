import TimeAgo from "react-timeago";
import moment from "moment";
import useViewport from "./useViewport";

const useTimeAgo = () => {
  const { isMobile } = useViewport();

  const timeAgo = (date: Date | number | string) => {
    const refDate = moment.utc(date).local();


    const customFormatter = (value: any, unit: any) => {
      return `${value} ${unit}${value > 1 ? "s" : ""}`;
    };

    return (
      <TimeAgo
        date={refDate.toDate()}
        title={refDate.format("DD.MM.YYYY HH:mm")}
        className={"text-xxxs xs:text-xxs sm:text-xs md:text-sm"}
        formatter={isMobile ? customFormatter : undefined}
      />
    );
  };

  return { timeAgo };
};

export default useTimeAgo;
