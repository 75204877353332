import useHelpDrawer from "src/hooks/private/useHelpDrawer";
import FuseCoinsHelp from "./FuseCoinsHelp";
import useViewport from "src/hooks/useViewport";
import offerGIFDesktop from "src/assets/gifs/Desktop_Offer.gif";
import offerGIFMobile from "src/assets/gifs/Mobile_Offer.gif";
import useApp from "src/hooks/useAppContext";
import getHostName from "src/helpers/getAppFromHost";

const OffersHelp = () => {
  const { helpLink } = useHelpDrawer();

  const { app } = useApp();

  const { isMobile } = useViewport()
  
  // !TODO: handle the Platinum case here
  return (
    <>
      <div>
        <p>All offers in {app?.name || getHostName()} are based off of a property's ARV or after repair value. During offer creation you can adjust this number.</p>
        <br></br>
        <p>Teams subscribed to Platinum have the ability to create Three Tiered Offers. These contain an All Cash Offer, Seller Carry Offer, and Seller Financing Offer. </p>
        <br></br>
        <p>
          Offers are sent by US Mail to the property's owner along with a Proof
          of Funds Letter for the amount of the offer. Proof of Funds letters
          are not available for investment properties located in Minnesota,
          Nevada, South Dakota, Utah, and Vermont. Send Offer is reduced to 20
          {" "}
          <span
            className="font-bold hover:underline text-primary cursor-pointer"
            onClick={() => helpLink(app?.name || "FuseCoins™", <FuseCoinsHelp />)}
          >
            {app?.coinName || "FuseCoins™"}
          </span>{" "}
          in those states. For all other states both All Cash Offers and Three Tiered Offers cost 40
          {" "}
          <span
            className="font-bold hover:underline text-primary cursor-pointer"
            onClick={() => helpLink(app?.coinName || "FuseCoins™", <FuseCoinsHelp />)}
          >
            {app?.coinName || "FuseCoins™"}
          </span>.
        </p>
        <br></br>
        <img
          src={isMobile ? offerGIFMobile : offerGIFDesktop}
          alt={"How To Research"}
        />
      </div>
    </>
  );
};

export default OffersHelp;
