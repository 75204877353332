import React from "react";
import { parseMonthDayYear } from "src/helpers/parseEpochs";
import useViewport from "src/hooks/useViewport";
import UserAvatar from "../buttons/UserAvatar";

interface PhoneEmailResultsModalProps {
  callEmail: any;
}

const PhoneEmailResultsModal = ({ callEmail }: PhoneEmailResultsModalProps) => {
  const { isMobile } = useViewport();

  return (
    <>
      {callEmail && (
        <div className="w-full">
          <table
            className={
              isMobile
                ? "block table table-compact w-full shadow-xl"
                : "block table w-full shadow-xl"
            }
          >
            <thead>
              <tr className="text-secondary border-b-2 border-secondary">
                <td className="bg-card-light dark:bg-card-dark text-xs select-none text-center">
                  Created
                </td>
                <td className="bg-card-light dark:bg-card-dark text-xs select-none text-center">
                  Teammate
                </td>
                <td className="bg-card-light dark:bg-card-dark text-xs select-none text-center">
                  Phone/Email
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="bg-card-light dark:bg-card-dark text-text-dark dark:text-text-light font-semibold text-center">
                  {parseMonthDayYear(callEmail.createdAt)}
                </td>
                <td className="bg-card-light dark:bg-card-dark text-text-dark dark:text-text-light font-semibold text-base text-center">
                  <UserAvatar isSmall={true} userID={callEmail.userID} />
                </td>
                <td className="bg-card-light dark:bg-card-dark text-text-dark dark:text-text-light font-semibold text-center">
                  {callEmail.value}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default PhoneEmailResultsModal;
