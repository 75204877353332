import React from "react";

import ThemeSwitch from "../buttons/ModeSwitch";
import SearchBar from "../search/SearchBarDesktop";
import UserName from "../navigation/desktop/UserName";
import ThemeChooser from "src/components/buttons/ThemeChooser";
import useModal from "src/hooks/useModal";
import useAlert from "src/hooks/useAlert";
import Alert from "src/components/alert/Alert";
import { motion, AnimatePresence } from "framer-motion";

interface HeaderNavBirddogProps {
  props?: any
}

const HeaderNavBirddog = ({props}: HeaderNavBirddogProps) => {

  const { showModal } = useModal();

  const { alert, setAlert } = useAlert();

  return (
    <header
      className={
        showModal
          ? "fixed left-72 top-0 right-0 z-40 flex h-16 columns-1 flex-col bg-back-light dark:bg-back-dark"
          : "fixed left-72 top-0 right-0 z-50 flex h-16 columns-1 flex-col bg-back-light dark:bg-back-dark"
      }
    >
     
      <div className="flex w-full items-center justify-center bg-back-light px-6 pt-2 dark:bg-back-dark">
        <div className="flex flex-row justify-start gap-1">
          <div className="mt-[5px]">
            <SearchBar />
          </div>
        </div>
        <div className="flex w-full items-center justify-end">
          <ThemeSwitch />
          <ThemeChooser />
          <UserName />
        </div>
      </div>
        <div className="px-6">
          <AnimatePresence>
            {alert.display && (
              <motion.div
                initial={{ y: -20, opacity: 0.8 }}
                transition={{ duration: 0.15 }}
                animate={{ y: 8, opacity: 1 }}
                exit={{ y: -20, opacity: 0.5 }}
              >
                <Alert
                  alert={alert}
                  _className={"h-[30px] lg:h-[38px]"}
                  onClose={() =>
                    setAlert({
                      display: false,
                      type: alert.type,
                      message: alert.message,
                    })
                  }
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
    </header>
  );
};

export default HeaderNavBirddog;