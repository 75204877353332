import { ChangeEventHandler, ReactNode } from "react";
import classNames from "classnames";

interface FloatingInputProps {
  name: string;
  label?: string | ReactNode;
  type?: string;
  value?: string;
  onChange?: ChangeEventHandler;
  error?: string | null;
  prefix?: ReactNode;
  hasComma?: boolean;
  rows?: number;
  labelProps?: {
    className?: string;
    htmlFor?: string;
  };
  inputProps?: {
    className?: string;
  };
  errorProps?: {
    className?: string;
  };
  disabled?: boolean;
}

const FloatingInput = ({
  name,
  label,
  error,
  type,
  prefix,
  errorProps,
  labelProps,
  inputProps,
  ...props
}: FloatingInputProps) => {
  const errorClassName = classNames("absolute bg-back-light dark:bg-base-100 text-error text-2xs md:text-xs italic left-4 px-1", {
    "top-[37px]": type !== "textarea",
    "top-[225px]": type === "textarea",
    }, errorProps?.className
  );

  const _inputProps = {
    id: name,
    className: classNames("block w-full bg-transparent rounded-lg appearance-none dark:text-text-light focus:outline-none focus:ring-0 peer", {
      "px-2.5 pb-2.5 pt-3.5  text-gray-900 border-[2.5px] border-error  dark:border-error dark:focus:border-error focus:border-error": prefix && error,
      "pl-5 pb-2.5 pt-3.5 font-semibold border-[2.5px] border-secondary dark:border-secondary dark:focus:border-secondary focus:border-secondary": prefix && !error,
      "px-2.5 pb-2.5 pt-4 text-gray-900 border border-error dark:border-error dark:focus:border-error focus:border-error": !prefix && error,
      "pl-5 pb-2.5 pt-4 font-semibold border border-secondary dark:border-secondary dark:focus:border-secondary focus:border-secondary": !prefix && !error,
      "text-[17px]": type !== "textarea",
    }, inputProps?.className),
    style: {
      outline: "none",
    },
    type,
    name,
    ...props
  }

  const input = (<>
    {type === "textarea" ? <textarea { ..._inputProps }>{props.value}</textarea> : <input {..._inputProps} />}
    <label
      htmlFor={name}
      className={classNames("absolute text-sm text-text-dark dark:text-text-light duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-back-light dark:bg-base-100 px-2 peer-focus:px-3 peer-focus:text-text-dark  dark:peer-focus:text-text-light peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-2.5 font-medium", labelProps?.className)}
      {...labelProps}
    >
      {label}
    </label>
    {error && (
      <p {...errorProps} className={errorClassName}>
        {error}
      </p>
    )}
  </>);

  return (
    <>
      {prefix ? (
        <div className="relative flex items-center justify-center">
          <div className="absolute top-[15px] -left-[4px] z-50 bg-back-light dark:bg-base-100">
            {prefix}
          </div>
          {input}
        </div>
      ) : (
        <div className="relative">
          {input}
        </div>
      )}
    </>
  );
};

export default FloatingInput;
