import { ChangeEventHandler, ReactNode } from "react";
import classNames from "classnames";
const numeral = require("numeral");

interface FloatingInputProps {
  name: string;
  label?: string | ReactNode;
  type?: string;
  value?: string;
  onChange?: ChangeEventHandler;
  error?: string | null;
  prefix?: ReactNode;
  hasComma?: boolean;
  labelProps?: {
    className?: string;
    htmlFor?: string;
  };
  inputProps?: {
    className?: string;
  };
  errorProps?: {
    className?: string;
  };
  disabled?: boolean;
}

const FloatingInput = ({
  name,
  label,
  type,
  value,
  error,
  labelProps,
  inputProps,
  errorProps,
  onChange,
  prefix,
  hasComma = false,
  disabled = false,
}: FloatingInputProps) => {
  const errorClassName = classNames(
    "absolute bg-back-light dark:bg-base-100 text-error text-2xs md:text-xs italic left-4 top-[37px] px-1",
    errorProps?.className
  );

  return (
    <>
      {prefix ? (
        <div className="relative flex items-center justify-center">
          <div className="absolute top-[15px] -left-[4px] z-50 bg-back-light dark:bg-base-100">
            {prefix}
          </div>
          <input
            disabled={disabled}
            type={type}
            id={name}
            className={
              error
                ? "block px-2.5 pb-2.5 pt-3.5 w-full text-[17px] text-gray-900 bg-transparent rounded-lg border border-error appearance-none dark:text-text-light dark:border-error dark:focus:border-error focus:outline-none focus:ring-0 focus:border-error peer"
                : "block pl-5 pb-2.5 pt-3.5 w-full text-[17px] font-semibold bg-transparent rounded-[4px] border border-secondary appearance-none dark:text-text-light dark:border-secondary dark:focus:border-secondary focus:outline-none focus:ring-0 focus:border-secondary peer"
            }
            placeholder=" "
            style={{
              outline: "none",
            }}
            onChange={onChange}
            value={value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 1}
            name={name}
          />
          <label
            htmlFor={name}
            className="absolute text-sm text-text-dark dark:text-text-light duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-back-light dark:bg-base-100 px-3 peer-focus:px-3 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-2.5 font-medium"
          >
            {label}
          </label>
          {error && (
            <p {...errorProps} className={errorClassName}>
              {error}
            </p>
          )}
        </div>
      ) : (
        <div className="relative">
          <input
            type={type}
            id={name}
            className={
              error
                ? "block px-2.5 pb-2.5 pt-4 w-full text-[17px] text-gray-900 bg-transparent rounded-lg border border-error appearance-none dark:text-text-light dark:border-error dark:focus:border-error focus:outline-none focus:ring-0 focus:border-error peer"
                : "block pl-5 pb-2.5 pt-4 w-full text-[17px] font-semibold bg-transparent rounded-lg border border-secondary appearance-none dark:text-text-light dark:border-secondary dark:focus:border-secondary focus:outline-none focus:ring-0 focus:border-secondary peer"
            }
            placeholder=" "
            style={{
              outline: "none",
            }}
            onChange={onChange}
            value={value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 1}
            name={name}
          />
          <label
            htmlFor={name}
            className="absolute text-sm text-text-dark dark:text-text-light duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-back-light dark:bg-base-100 px-2 peer-focus:px-3 peer-focus:text-text-dark peer-focus:dark:text-text-light peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-2.5 font-medium"
          >
            {label}
          </label>
          {error && (
            <p {...errorProps} className={errorClassName}>
              {error}
            </p>
          )}
        </div>
      )}
    </>
  );
};

export default FloatingInput;
