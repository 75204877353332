import { PropsWithChildren } from "react";

/***** Context Provider *****/
import { UserProvider } from "src/contexts/private/UserContext";
import { TeamProvider } from "src/contexts/private/TeamContext";
import { PropertiesProvider } from "src/contexts/private/PropertiesContext";
import { SequenceLetterProvider } from "src/contexts/private/SequenceMailingContext";
import { TemplatePostcardsProvider } from "src/contexts/private/TemplatePostcardsContext";
import { TemplateLettersProvider } from "src/contexts/private/TemplateLettersContext";
import { DrawerProvider } from "src/contexts/private/DrawerContext";
import { ModalProvider } from "src/contexts/ModalContext";
import { DebtStackProvider } from "src/contexts/private/DebtStackContext";
import { LoanProvider } from "src/contexts/private/LoanContext";
import { OfferProvider } from "src/contexts/private/OfferContext";
import { CallsEmailsProvider } from "src/contexts/private/CallsEmailsContext";
import { PropertyUpdateProvider } from "src/contexts/private/PropertyUpdateContext";
import { SkipTraceProvider } from "src/contexts/private/SkipTraceContext";
import { SupportTicketProvider } from "src/contexts/private/SupportTicketContext";
import { ChartProvider } from "src/contexts/private/ChartsContext";
import { HelpDrawerProvider } from "src/contexts/private/HelpDrawerContext";
import { WatchlistProvider } from "src/contexts/private/WatchlistContext";
import { ImportProvider } from "src/contexts/private/ImportContext";
import { SubscriptionProvider } from "src/contexts/private/SubscriptionContext";
import { CampaignProvider } from "src/contexts/private/CampaignContext";

interface PrivateProviderProps {}

const PrivateProvider = ({
  children,
}: PropsWithChildren<PrivateProviderProps>) => (
  <SubscriptionProvider>
  <HelpDrawerProvider>
    <UserProvider>
      <TeamProvider>
        <PropertiesProvider>
          <ChartProvider>
            <TemplatePostcardsProvider>
              <TemplateLettersProvider>
                <SequenceLetterProvider>
                  <CampaignProvider>
                    <DebtStackProvider>
                      <LoanProvider>
                        <OfferProvider>
                          <CallsEmailsProvider>
                            <SkipTraceProvider>
                              <PropertyUpdateProvider>
                                <SupportTicketProvider>
                                  <WatchlistProvider>
                                    <ImportProvider>
                                      <ModalProvider>
                                        <DrawerProvider>
                                          {children}
                                        </DrawerProvider>
                                      </ModalProvider>
                                    </ImportProvider>
                                  </WatchlistProvider>
                                </SupportTicketProvider>
                              </PropertyUpdateProvider>
                            </SkipTraceProvider>
                          </CallsEmailsProvider>
                        </OfferProvider>
                      </LoanProvider>
                    </DebtStackProvider>
                  </CampaignProvider>
                </SequenceLetterProvider>
              </TemplateLettersProvider>
            </TemplatePostcardsProvider>
          </ChartProvider>
        </PropertiesProvider>
      </TeamProvider>
    </UserProvider>
  </HelpDrawerProvider>
</SubscriptionProvider>
);

export default PrivateProvider;
