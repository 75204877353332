import classnames from "classnames";
import { Notification } from "src/global/types";
import { keyEvents } from "src/helpers/constants";
import useNotification from "src/hooks/private/useNotification";
import useTimeAgo from "src/hooks/useTimeAgo";
import StaticActionBadge from "../badges/StaticActionBadge";

interface InlineNotificationProps {
  notification: Notification;
  readBy?: string;
}

const InlineNotification = ({
  notification,
  readBy,
}: InlineNotificationProps) => {
  const { timeAgo } = useTimeAgo();
  const { onClickNotification } = useNotification();

  return (
    <li
      className={classnames(
        "flex w-full cursor-pointer flex-wrap justify-between rounded-lg border-b border-icon-light px-2 text-text-dark hover:bg-hover-card-light dark:text-text-light dark:hover:bg-hover-card-dark"
      )}
      key={notification.id}
      onClick={() => onClickNotification(notification)}
    >
      <div
        className={classnames("w-full p-2", {
          "my-1 cursor-pointer rounded-lg bg-hover-card-light dark:bg-hover-card-dark":
            !notification.isRead,
          "tooltip tooltip-top": !!readBy,
        })}
        data-tip={readBy}
      >
        <div className="flex w-full flex-wrap justify-between">
          <label className="text-md font-bold italic">
            <StaticActionBadge
              title={
                keyEvents[notification.event] === "Offer"
                  ? notification?.notification.trim().startsWith("A Three")
                    ? "Three Tiered Offer"
                    : "Offer"
                  : keyEvents[notification.event]
              }
            />
          </label>
          <small className="italic">{timeAgo(notification.createdAt)}</small>
        </div>
        <div className="flex w-full text-xs">
          {notification?.notification.trim()}
        </div>
      </div>
    </li>
  );
};

export default InlineNotification;
