import React from "react";
import FuseCoinLight from "src/assets/images/FuseCoinLight.svg";
import FuseCoinDark from "src/assets/images/FuseCoinDark.svg";
import useTheme from "src/hooks/useTheme";
import useAuth from "src/hooks/useAuth";

interface FuseCoinProps {
  props?: any;
}

const FuseCoin = ({ props }: FuseCoinProps) => {
  const { mode } = useTheme();

  const { currentUser } = useAuth();

  return (
    <>
      {mode === "dark" ? (
        <img
          src={FuseCoinDark}
          alt={currentUser?.coinName}
          className="w-[26px]"
        />
      ) : (
        <img
          src={FuseCoinLight}
          alt={currentUser?.coinName}
          className="w-[26px]"
        />
      )}
    </>
  );
};

export default FuseCoin;
