import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";

const TOKEN_ID = "x-access-token";

const LogAsUserInvitationGuard = () => {
    const { token } = useParams();

    useEffect(() => {
        localStorage.setItem(TOKEN_ID, token as string);
    }, []);

    return (<Outlet />)
}

export default LogAsUserInvitationGuard;