import { actionCosts } from "src/configs/configs";
import useHelpDrawer from "src/hooks/private/useHelpDrawer";
import useViewport from "src/hooks/useViewport";
import CallsEmailsHelp from "./CallsEmailsHelp";
import FuseCoinsHelp from "./FuseCoinsHelp";
import skiptraceGIFDesktop from "src/assets/gifs/Desktop_Skiptrace.gif";
import skiptraceGIFMobile from "src/assets/gifs/Mobile_Skiptrace.gif";
import useAuth from "src/hooks/useAuth";

const SkipTracesHelp = () => {
  const { helpLink } = useHelpDrawer();

  const { isMobile } = useViewport();

  const { currentUser } = useAuth();

  return (
    <>
      <p>
        If you're looking to find emails or phone numbers that have been
        reported to be associated with a specific property owner, ordering a
        Skip Trace is your best action. It has the possibility of returning up
        to two email address and two phone numbers.
      </p>
      <br></br>
      <p>
        Ordering a Skip Trace costs
        <span className="font-bold"> {actionCosts["skipTrace"]} </span>
        <span
          className="cursor-pointer font-bold text-primary hover:underline"
          onClick={() => helpLink(currentUser?.coinName || "FuseCoins", <FuseCoinsHelp />)}
        >
          {currentUser?.coinName}
        </span>
        . Your team's account will not be charged if a Skip Trace is
        unsuccessful at finding data.
      </p>
      <br></br>
      <p>
        Once a successful Skip Trace has been completed you may begin making{" "}
        <span
          className="cursor-pointer font-bold text-primary hover:underline"
          onClick={() => helpLink("Calls/Emails", <CallsEmailsHelp />)}
        >
          Calls/Emails
        </span>{" "}
        on the property.
      </p>
      <br></br>
      <img
        src={isMobile ? skiptraceGIFMobile : skiptraceGIFDesktop}
        alt={"How To Research"}
      />
    </>
  );
};

export default SkipTracesHelp;
