import { PropsWithChildren, createContext, useState, useEffect } from "react";
import useModeSwitcher from "src/hooks/useModeSwitcher";
import useThemeSwitcher from "src/hooks/useThemeSwitcher";
import { themes } from "src/global/themes";

interface ThemeContextValue {
  mode: string;
  setMode: (mode: string) => void;
  theme: string;
  setTheme: (theme: string) => void;
  tailwindTheme: any;
}

const defaultState = {
  mode: "dark",
  setMode: () => {},
  theme: "legacy",
  setTheme: () => {},
  tailwindTheme: themes[0].tailwind,

};

export const ThemeContext = createContext<ThemeContextValue>({
  ...defaultState,
});

interface ThemeContextProps {}

export const ThemeProvider = ({
  children,
}: PropsWithChildren<ThemeContextProps>) => {
  const [mode, setMode] = useModeSwitcher();
  const [theme, setTheme] = useThemeSwitcher();
  const [tailwindTheme, setTailwindTheme] = useState(themes.find((_theme) => _theme.name === theme)?.tailwind);

  useEffect(() => {
    setTailwindTheme(themes.find((_theme) => _theme.name === theme)?.tailwind);
  }, [theme]);


  return (
    <ThemeContext.Provider value={{ mode, setMode, theme, setTheme, tailwindTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};




