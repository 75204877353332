function getHostName(): string {
  // const hostname = window.location.hostname;
  //   const parts = hostname.split('.');
    
  //   // If there are multiple subdomains (like staging.app.sendfuse.com)
  //   if (parts.length === 4) {
  //       return parts[2].charAt(0).toUpperCase() + parts[2].slice(1).toLowerCase();
  //   }
  //   // If there's a single subdomain (like app.sendfuse.com)
  //   else if (parts.length === 3) {
  //       return parts[1].charAt(0).toUpperCase() + parts[1].slice(1).toLowerCase();
  //   }
    
  //   return hostname.charAt(0).toUpperCase() + hostname.slice(1).toLowerCase();
  return "SendFuse"
}

export default getHostName;