import { CallsEmails } from 'src/interfaces/callsEmails';
import { FilterParams } from 'src/global/types';
import axios from 'src/utils/axios';
import ApiTokenSupport from './token.support';

class CallsEmailsApi extends ApiTokenSupport {

  /**
   * Get phone calls
   * @returns 
   */
  async getCallsEmails(filter?: FilterParams): Promise<CallsEmails[]> {
    const resp = await axios.post<CallsEmails[]>(`/api/callsemails/filter`, { ...filter } , this.withAuthorization());
    return resp.data;
  }

  async createCallsEmails(data: any): Promise<boolean> {
    const resp = await axios.post<boolean>(`/api/callsemails`, { ...data }, this.withAuthorization());
    return resp.data;
  }
  
}

export default new CallsEmailsApi();
