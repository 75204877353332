import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { notificationApi } from "src/api";
import Property from "src/interfaces/property";
import { Notification, NotificationParams } from "src/global/types";
import { uniqueValue } from "src/helpers/js-utils";
import useRealtimeSync from "../useRealtimeSync";
import useDrawer from "./useDrawer";
import useErrorHandler from "./useErrorHandler";

const useNotification = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [showUnreadNotifications, setShowUnreadNotifications] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const navigate = useNavigate();

  const { syncNotificationUpdater } = useRealtimeSync();
  const { handleError } = useErrorHandler();
  const { openPropertyDrawerWith } = useDrawer();

  const readNotification = async (
    notificationId: number | null,
    readAll?: boolean
  ) => {
    try {
      const ok = await notificationApi.readNotification(
        notificationId,
        readAll
      );
      if (ok) {
        syncNotificationUpdater();
      }
    } catch (error: any) {
      handleError(error);
    }
  };

  const onClickNotification = async (notif: Notification) => {
    if (!notif.isRead) {
      await readNotification(notif.id, false);
    }
    switch (notif.event) {
      case "invites":
        return navigate("/team");
      case "watchlist":
        return navigate("/watchlist");
      case "purchases":
      case "drives":
        return;
      default:
        if (notif.actionID) {
          openPropertyDrawerWith({
            property: { id: notif.actionID } as Property,
          });
        }
    }
  };

  const getUserNotifications = useCallback(
    async (params: NotificationParams) => {
      try {
        const data = await notificationApi.getUserNotifications(params);
        if (data) {
          if (currentPage === 1) {
            setNotifications(data.rows);
          } else {
            setNotifications((oldData) => uniqueValue(oldData, data.rows));
          }
          setTotalPage(data?.totalPages);
        }
      } catch (error: any) {
        handleError(error);
      }
    },
    [currentPage, handleError]
  );

  const showUnreadNotification = () => {
    setNotifications([]);
    setCurrentPage(1);
    setShowUnreadNotifications(!showUnreadNotifications);
  };

  return {
    notifications,
    totalPage,
    currentPage,
    showUnreadNotifications,
    readNotification,
    onClickNotification,
    showUnreadNotification,
    getUserNotifications,
    setCurrentPage,
  };
};

export default useNotification;
