import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import useAuth from "src/hooks/useAuth";
import userApi from "src/api/user";
import { UserType as User } from "src/interfaces/user";
import { UserSettings } from "src/interfaces/userSettings";
import { UserNotificationSetting } from "src/global/types";
import useErrorHandler from "src/hooks/private/useErrorHandler";

interface UserContextInterface {
  user: User | null | undefined;
  fetchUser: () => void;
  userSettings: UserSettings | null | undefined;
  fetchUserSettings: () => void;
  userNotificationSettings: UserNotificationSetting[];
  fetchUserNotifictionSettings: (
    flag: boolean,
    notificationData: UserNotificationSetting[]
  ) => void;
}

const UserContextDefaults: UserContextInterface = {
  user: null,
  fetchUser: () => {},
  userSettings: null,
  fetchUserSettings: () => {},
  userNotificationSettings: [],
  fetchUserNotifictionSettings: () => {},
};

const UserContext =
  React.createContext<UserContextInterface>(UserContextDefaults);

interface UserProviderProps {}

export const UserProvider = ({
  children,
}: PropsWithChildren<UserProviderProps>) => {
  const { currentUser } = useAuth();
  const userID = currentUser?.id;

  const [user, setUser] = useState<User>();
  const [userSettings, setUserSettings] = useState<UserSettings>();
  const [userNotificationSettings, setUserNotifictionSettings] = useState<
    UserNotificationSetting[]
  >([]);

  const { handleError } = useErrorHandler();

  const fetchUser = useCallback(async () => {
    if (userID) {
      try {
        const user = await userApi.getUser(userID);
        setUser(user);
      } catch (err) {
        handleError(err);
      }
    }
  }, [userID, handleError]);

  const fetchUserSettings = useCallback(async () => {
    if (user) {
      try {
        const userSettings = await userApi.getUserSettings(user?.id);
        setUserSettings(userSettings);
      } catch (err) {
        handleError(err);
      }
    }
  }, [user, handleError]);

  /**
   * fetchUserNotifictionSettings use for get user notifiction settings
   * @isUpdate use for without call api update local settings object which use when user toggle setting option
   * @updatedData  is array of updated user notifiction setting.
   */
  const fetchUserNotifictionSettings = useCallback(
    async (
      isUpdate: boolean,
      updatedData: UserNotificationSetting[] | null
    ) => {
      if (user) {
        try {
          if (isUpdate && updatedData !== null) {
            setUserNotifictionSettings(updatedData);
          } else {
            const notificationData =
              await userApi.getUserNotificationSettings();
            setUserNotifictionSettings(notificationData);
          }
        } catch (err) {
          handleError(err);
        }
      }
    },
    [user, handleError]
  );

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    fetchUserSettings();
  }, [fetchUserSettings]);

  useEffect(() => {
    fetchUserNotifictionSettings(false, null);
  }, [fetchUserNotifictionSettings]);

  return (
    <UserContext.Provider
      value={{
        user,
        fetchUser,
        userSettings,
        fetchUserSettings,
        userNotificationSettings,
        fetchUserNotifictionSettings,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
