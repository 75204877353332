import ModeSwitch from "src/components/buttons/ModeSwitch";
import logoLight from "../../../assets/images/SendFuse_Logo_Light.png";
import logoDark from "../../../assets/images/SendFuse_Logo_Dark.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import CoinBalance from "./CoinBalance";
import SideNavItems from "../desktop/SideNavItems";
import useNav from "src/hooks/useNav";
import useTheme from "src/hooks/useTheme";
import ThemeChooser from "src/components/buttons/ThemeChooser";
import AlertButton from "../desktop/AlertButton";
import HelpButton from "../desktop/HelpButton";
import BannerAlertMobile from "src/components/banners/BannerAlertMobile";
import useAuth from "src/hooks/useAuth";
import Upgrade from "../desktop/Upgrade";
import useApp from "src/hooks/useAppContext";

const HeaderBarNav = () => {
  const { open, setOpen, isDisabled } = useNav();

  const { mode } = useTheme();

  const { app } = useApp();

  return (
    <header className="flex-no-wrap fixed z-10 flex min-w-full items-center justify-around bg-card-light p-3 dark:bg-card-dark">
      <>
        {open && (
          <div
            style={{
              backgroundColor: "rgba(0,0,0,0.69)",
            }}
            onClick={() => setOpen(!open)}
            className={
              open
                ? "absolute top-0 bottom-0 left-0 z-40 min-h-screen w-full transition-all duration-300"
                : "absolute top-0 bottom-0 left-0 z-40 min-h-screen w-full transition-all duration-300"
            }
          ></div>
        )}
        <div
          id="menu"
          className={
            open
              ? "absolute top-0 bottom-0 right-0 z-40 -mr-0 min-h-screen w-64 bg-card-light duration-300 dark:bg-card-dark md:w-72"
              : "absolute top-0 bottom-0 right-0 z-40 -mr-[450px] min-h-screen w-64 bg-card-light duration-300 dark:bg-card-dark md:w-72"
          }
        >
          <nav className="fixed top-0 bottom-0 w-64 bg-card-light py-4 px-2 dark:bg-card-dark xs:px-1">
            <div className="flex w-full items-center justify-center">
              <div onClick={() => setOpen(!open)}>
                <HelpButton />
              </div>
              <AlertButton />
              <ModeSwitch />
              <ThemeChooser />
            </div>
            <SideNavItems />
            <div className="flex items-center justify-center">
              <Upgrade />
            </div>
            <BannerAlertMobile />
          </nav>
        </div>
      </>

      <div className="flex h-8 w-full items-center justify-between">
        <div className="-mr-10 flex w-full items-center justify-start">
          <CoinBalance />
        </div>

        <div className="mt-1 flex w-full items-center justify-center">
          <img
            src={mode === "light" ? logoLight : logoDark}
            className=""
            alt={`${app?.name} Logo`}
          />
        </div>

        <div className="-ml-10 mt-1.5 mr-0.5 flex w-full items-center justify-end">
          <button>
            <FontAwesomeIcon
              icon={faBars}
              className="h-6 text-primary md:h-8"
              onClick={isDisabled ? () => {} : () => setOpen(!open)}
            />
          </button>
        </div>
      </div>
    </header>
  );
};

export default HeaderBarNav;
