import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { propertyApi } from "src/api";
import update from "immutability-helper";
import Property from "src/interfaces/property";
import {
  FilterParams,
  Filters,
  LoadMorePage,
  Paginate,
} from "src/global/types";
import { sortFunctions, uniqueValue } from "src/helpers/js-utils";
import useErrorHandler from "src/hooks/private/useErrorHandler";
import useAuth from "src/hooks/useAuth";
import useRealtimeSync from "src/hooks/useRealtimeSync";

interface State {
  properties: Property[];
  limit: number;
  isSearching: boolean;
  onFetching: boolean;
  filters: Filters;
  pages: LoadMorePage;
  search: string;
}

type PropertiesContextInterface = Omit<State, "search"> & {
  setIsSearching: React.Dispatch<React.SetStateAction<boolean>>;
  fetchProperties: (filter?: FilterParams) => void;
  setFilters: (key: string, value: any) => void;
  setPage: (key: string, value: number) => void;
  countTotalPages: (key: string, filter?: FilterParams) => void;
  unshiftProperty: (property: Property) => void;
};

const defaultFilters: Filters = {
  view: "table",
  sort: "createdAt",
  sortDirection: "desc",
  filterValue: {
    minValue: 0,
    maxValue: Infinity,
  },
  filterBedBath: {
    bedValue: 0,
    bathValue: 0,
  },
};

const defaultPagination: Paginate = {
  currentPage: 1,
  totalPages: 0,
};

const defaultState = {
  properties: [],
  limit: 9,
  isSearching: false,
  onFetching: false,
  filters: { ...defaultFilters },
  search: "",
  pages: {
    allProperties: defaultPagination,
    watchlist: defaultPagination,
    sequences: defaultPagination,
    debStacks: defaultPagination,
    offers: defaultPagination,
  },
};

const defaultPropertiesContext: PropertiesContextInterface = {
  ...defaultState,
  setIsSearching: () => {},
  fetchProperties: () => {},
  setFilters: () => {},
  setPage: () => {},
  countTotalPages: () => {},
  unshiftProperty: () => {},
};

const PropertiesContext = React.createContext<PropertiesContextInterface>(
  defaultPropertiesContext
);

interface PropertiesProviderProps {}

interface Action {
  type:
    | "SET_PROPERTIES"
    | "SET_SEARCHING"
    | "SET_FILTERS"
    | "SET_CURRENT_PAGE"
    | "SET_TOTAL_PAGE"
    | "SET_ON_FETCH"
    | "SYNC_PROPERTY"
    | "SET_SEARCH_TEXT";
  payload?: any;
}

const stateReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "SET_PROPERTIES":
      const properties = uniqueValue(state.properties, action.payload);
      return { ...state, properties };
    case "SET_SEARCHING":
      return { ...state, isSearching: !state.isSearching };
    case "SET_FILTERS":
      return { ...state, filters: { ...state.filters, ...action.payload } };
    case "SET_CURRENT_PAGE":
      return update(state, {
        pages: {
          [action.payload.key]: {
            currentPage: { $set: action.payload.currentPage },
          },
        },
      });
    case "SET_TOTAL_PAGE":
      return update(state, {
        pages: {
          [action.payload.key]: {
            totalPages: { $set: action.payload.totalPages },
          },
        },
      });
    case "SYNC_PROPERTY":
      switch (action.payload.action) {
        case "insert":
          return update(state, {
            properties: { $unshift: [action.payload.property] },
          });
        case "update":
          const updateIndex = state.properties.findIndex(
            (p) => p.id === action.payload.property?.id
          );
          if (updateIndex !== -1) {
            return update(state, {
              properties: {
                [updateIndex]: {
                  $set: {
                    ...state.properties[updateIndex],
                    ...action.payload.property,
                  },
                },
              },
            });
            // restore from trash case
          } else if (action.payload.property.isActive) {
            return update(state, {
              properties: { $push: [action.payload.property] },
            });
          }
          break;
        case "delete":
          const deleteIndex = state.properties.findIndex(
            (p) => p.id === action.payload.property?.id
          );
          if (deleteIndex !== -1) {
            return update(state, {
              properties: { $splice: [[deleteIndex, 1]] },
            });
          }
          break;
      }
      return state;
    case "SET_ON_FETCH":
      return { ...state, onFetching: action.payload };
    case "SET_SEARCH_TEXT":
      return { ...state, search: action.payload };
    default:
      return state;
  }
};

export const PropertiesProvider = ({
  children,
}: PropsWithChildren<PropertiesProviderProps>) => {
  const { handleError } = useErrorHandler();
  const { currentUser } = useAuth();
  const { propertyUpdater, syncPropertyUpdater } = useRealtimeSync();

  const [state, dispatch] = useReducer(stateReducer, defaultState);

  const setFilters = (key: string, value: any) => {
    dispatch({ type: "SET_FILTERS", payload: { [key]: value } });
  };

  const setPage = (key: string, currentPage: number) => {
    dispatch({ type: "SET_CURRENT_PAGE", payload: { key, currentPage } });
  };

  const setIsSearching = () => {
    dispatch({ type: "SET_SEARCHING" });
  };

  const fetchProperties = useCallback(
    async (filter?: FilterParams) => {
      try {
        dispatch({ type: "SET_SEARCH_TEXT", payload: filter?.search || "" });

        dispatch({ type: "SET_ON_FETCH", payload: true });

        const properties = await propertyApi.getProperties(filter);

        dispatch({ type: "SET_PROPERTIES", payload: properties });
      } catch (err: any) {
        handleError(err);
      } finally {
        dispatch({ type: "SET_ON_FETCH", payload: false });
      }
    },
    [handleError]
  );

  const { properties, filters, limit, search } = state;

  const countTotalPages = useCallback(
    async (key: string, filter?: FilterParams) => {
      try {
        const count = await propertyApi.count({
          ...filter,
          query: { ...filter?.query, teamID: currentUser?.teamID },
        });
        dispatch({
          type: "SET_TOTAL_PAGE",
          payload: { key, totalPages: count / limit },
        });
      } catch (err: any) {
        handleError(err);
      }
    },
    [handleError, limit, currentUser]
  );

  useEffect(() => {
    const { action, property } = propertyUpdater;
    if (!action || !property) return;
    if (action === "insert") return;
    dispatch({ type: "SYNC_PROPERTY", payload: { action, property } });
    syncPropertyUpdater({ action: "" });
  }, [propertyUpdater]);

  const { sort, sortDirection, filterValue, filterBedBath } = filters;
  const sortFn = sortFunctions[sort];

  const filterProperties = useCallback(
    (property) =>
      property.appraisedValue >= filterValue.minValue &&
      property.appraisedValue <= filterValue.maxValue &&
      property.bed >= filterBedBath.bedValue &&
      property.bath >= filterBedBath.bathValue &&
      (search ? property.fullAddress.toLowerCase().includes(search) : true),
    [filterBedBath, filterValue, search]
  );

  // This will prevent the component from re-filtering the properties every time the component re-renders.
  const filteredProperties = useMemo(
    () =>
      properties
        .filter(filterProperties)
        .sort((a: Property, b: Property) => sortFn(a, b, sortDirection)),
    [properties, filterProperties, sortFn, sortDirection]
  );

  const unshiftProperty = (property: Property) => {
    dispatch({
      type: "SYNC_PROPERTY",
      payload: { action: "insert", property },
    });
  };

  return (
    <PropertiesContext.Provider
      value={{
        ...state,
        properties: filteredProperties,
        setPage,
        setFilters,
        setIsSearching,
        fetchProperties,
        countTotalPages,
        unshiftProperty,
      }}
    >
      {children}
    </PropertiesContext.Provider>
  );
};

export default PropertiesContext;
