import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ActionButtonProps {
  icon: any;
  label: string;
  className?: string;
  onClick: any;
}

const ActionButton = ({
  icon,
  label,
  className,
  onClick,
}: ActionButtonProps) => {
  const buttonClassnames = classNames(className, "btn gap-1.5 ");

  return (
    <div className="@container">
      <button className={buttonClassnames} onClick={onClick}>
        <FontAwesomeIcon className="@[7rem]:text-[.8rem] @[8rem]:text-[.85rem] @[9rem]:text-[.9rem] @[10rem]:text-[1rem] @[11rem]:text-[1.2rem]" icon={icon} />
        <div className="@[7rem]:text-[.8rem] @[8rem]:text-[.85rem] @[9rem]:text-[.9rem] @[10rem]:text-[1rem] @[11rem]:text-[1.2rem]">
          {label}
        </div>
      </button>
    </div>
  );
};

export default ActionButton;
