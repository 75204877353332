import { TemplateLetter } from 'src/interfaces/templateLetter';
import { FilterParams } from 'src/global/types';
import axios from 'src/utils/axios';
import ApiTokenSupport from './token.support';

class TemplateLettersApi extends ApiTokenSupport {

  /**
   * Get templates
   * @returns 
   */
  async getTemplateLetters(filter?: FilterParams): Promise<TemplateLetter[]> {
    const resp = await axios.post<TemplateLetter[]>(`/api/template-letter/filter`, { ...filter } ,this.withAuthorization());
    return resp.data;
  }

  // async getUserTemplateIds(postcardNumbers: number[], type?: string): Promise<number[]> {
  //   const resp = await axios.post<number[]>(`/api/template-letter/user-templates`, { postcardNumbers, type } ,this.withAuthorization());
  //   return resp.data;
  // }

  /**
   * Get preview of html template as img
   * @param id 
   * @param view 
   * @returns 
   */
  previewUrl(id: number, userID: number, noCache?: boolean, propertyID?: number): string {
    return `${process.env.REACT_APP_BACKEND_URL}/api/template-letter/preview/${id}/${userID}?cache=${!noCache}&propertyid=${propertyID || 0}`;
  }

  async getTemplate(id: number): Promise<TemplateLetter> {
    const resp = await axios.get<TemplateLetter>(`/api/template-letter/${id}`,this.withAuthorization());
    return resp.data;
  }

  
}

export default new TemplateLettersApi();
