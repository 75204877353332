import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { offerApi } from "src/api";
import { Offer } from "src/interfaces/offer";
import useUserContext from "src/hooks/private/useUserContext";
import useErrorHandler from "src/hooks/private/useErrorHandler";

interface OfferContextInterface {
  offers: Offer[];
  fetchOffers: () => void;
}

const offerContextDefaults: OfferContextInterface = {
  offers: [],
  fetchOffers: () => {},
};

const OfferContext =
  React.createContext<OfferContextInterface>(offerContextDefaults);

interface OfferProviderProps {}

export const OfferProvider = ({
  children,
}: PropsWithChildren<OfferProviderProps>) => {
  const [offers, setOffers] = useState<Offer[]>([]);

  const { user } = useUserContext();

  const { handleError } = useErrorHandler();

  const fetchOffers = useCallback(async () => {
    if (user) {
      try {
        // filtered by default by team ID
        const offers = await offerApi.getOffers();
        setOffers(offers);
      } catch (err) {
        handleError(err);
      }
    }
  }, [user, handleError]);

  useEffect(() => {
    fetchOffers();
  }, [fetchOffers]);

  return (
    <OfferContext.Provider
      value={{
        offers,
        fetchOffers,
      }}
    >
      {children}
    </OfferContext.Provider>
  );
};

export default OfferContext;
