import { useState, useEffect } from "react";
import { capitalize } from "../../helpers/parseStrings";
import { propertyApi } from "src/api";
import useAlert from "src/hooks/useAlert";
import useUserContext from "src/hooks/private/useUserContext";

interface UpdatePropertyStatusProps {
  propertyID: number;
  status: string;
  isLG?: boolean;
}

const UpdatePropertyStatus = ({
  propertyID,
  status,
  isLG,
}: UpdatePropertyStatusProps) => {
  const [statusData, setStatusData] = useState<string>(status);

  const { setAlert } = useAlert();

  const { user } = useUserContext();

  useEffect(() => {
    if (status) {
      setStatusData(status);
    }
  }, [status]);

  const handleChange = async (e: any) => {
    const { value } = e.target;
    try {
      const result = await propertyApi.updatePropertyStatus(
        propertyID,
        value.toLowerCase()
      );
      setStatusData(result?.status);
    } catch (err: any) {
      setAlert({
        display: true,
        message: err?.message || err || "Unable to update property status",
        type: "error",
      });
    }
  };

  return (
    <>
      {user?.canUpdateStatus ? (
        <div className="w-full">
          <select
            style={{
              outline: "none",
            }}
            className={
              statusData === "researching"
                ? "select select-sm bg-theme1 text-text-light dark:bg-theme1 dark:text-back-dark"
                : statusData === "contacted"
                ? "select select-sm bg-theme4 text-text-light dark:bg-theme4 dark:text-back-dark"
                : statusData === "negotiating"
                ? "select select-sm bg-theme5 text-text-light dark:bg-theme5 dark:text-back-dark"
                : statusData === "acquired"
                ? "select select-sm bg-theme2 text-text-light dark:bg-theme2 dark:text-back-dark"
                : "select select-sm bg-theme3 text-text-light dark:bg-theme3 dark:text-back-dark"
            }
            title="Update Property Status"
            value={statusData}
            onChange={handleChange}
          >
            <option value={statusData} className="">
              {capitalize(statusData)}
            </option>
            {statusData === "contacted" ? (
              <>
                <option value="researching">Researching</option>
                <option value="negotiating">Negotiating</option>
                <option value="acquired">Acquired</option>
                <option value="denied">Denied</option>
              </>
            ) : statusData === "negotiating" ? (
              <>
                <option value="researching">Researching</option>
                <option value="contacted">Contacted</option>
                <option value="acquired">Acquired</option>
                <option value="denied">Denied</option>
              </>
            ) : statusData === "denied" ? (
              <>
                <option value="researching">Researching</option>
                <option value="contacted">Contacted</option>
                <option value="negotiating">Negotiating</option>
                <option value="acquired">Acquired</option>
              </>
            ) : statusData === "acquired" ? (
              <>
                <option value="researching">Researching</option>
                <option value="contacted">Contacted</option>
                <option value="negotiating">Negotiating</option>
                <option value="denied">Denied</option>
              </>
            ) : (
              <>
                <option value="contacted">Contacted</option>
                <option value="negotiating">Negotiating</option>
                <option value="acquired">Acquired</option>
                <option value="denied">Denied</option>
              </>
            )}
          </select>
        </div>
      ) : (
        <div
          style={{
            outline: "none",
            textTransform: "none",
          }}
          className={
            status === "researching"
              ? "btn-sm btn border-none bg-theme1 text-text-light dark:bg-theme1 dark:text-back-dark"
              : status === "contacted"
              ? "btn-sm btn border-none bg-theme4 text-text-light dark:bg-theme4 dark:text-back-dark"
              : status === "negotiating"
              ? "btn-sm btn border-none bg-theme5 text-text-light dark:bg-theme5 dark:text-back-dark"
              : status === "acquired"
              ? "btn-sm btn border-none bg-theme2 text-text-light dark:bg-theme2 dark:text-back-dark"
              : "btn-sm btn border-none bg-theme3 text-text-light dark:bg-theme3 dark:text-back-dark"
          }
        >
          {capitalize(status)}
        </div>
      )}
    </>
  );
};

export default UpdatePropertyStatus;
