import { useState } from "react";
import AnimatedNumber from "react-awesome-animated-number";
import { stripeAccountApi } from "src/api";
import { fuseCoinCosts } from "src/configs/configs";
import useAlert from "src/hooks/useAlert";
import useModal from "src/hooks/useModal";
import Button from "../buttons/Button";
import FuseLogo from "src/assets/images/FuseLogo.png";
import useTeamContext from "src/hooks/private/useTeamContext";
import FuseCoin from "../icons/FuseCoin";
import useAuth from "src/hooks/useAuth";

const PurchaseCoinsModal = () => {
  const [amountToPurchase, setAmountToPurchase] = useState(4);
  const [onSubmitting, setOnSubmitting] = useState<boolean>(false);
  const { fetchUserTeamData } = useTeamContext();

  const { currentUser } = useAuth();

  const { setAlert } = useAlert();
  const { setShowModal } = useModal();

  const handleChange = (e: any) => {
    const { value } = e.target;
    setAmountToPurchase(value);
  };

  const onPurchaseCoinsSubmit = async () => {
    try {
      await stripeAccountApi.purchaseCoins(
        parseInt(fuseCoinCosts(amountToPurchase * 100)),
        amountToPurchase
      );
      setAlert({
        type: "success",
        message: `${amountToPurchase * 100} ${currentUser?.coinName}  purchased successfully`,
        display: true,
      });
      fetchUserTeamData();
      setShowModal(false);
    } catch (err: any) {
      setAlert({
        type: "error",
        message: err?.message,
        display: true,
      });
    }
  };

  const handleSubmitWithLoader = async () => {
    setOnSubmitting(true);
    await onPurchaseCoinsSubmit();
    setOnSubmitting(false);
  };

  const handleCancel = async () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="flex flex-col columns-1 items-center justify-center">
          <div className="px-1.5 h-12 border-2 border-primary flex items-center justify-center bg-card-light dark:bg-card-dark rounded-lg shadow-lg drop-shadow-lg space-x-1 cursor-pointer select-none mt-0.5 mb-2">
            <FuseCoin />
            <span className="text-text-dark dark:text-text-light text-sm font-extrabold select-none">
              <AnimatedNumber
                className="mr-0.5 lg:mr-1"
                value={amountToPurchase * 100}
                size={14}
                duration={200}
              />
            </span>
          </div>
        <input
          type="range"
          min="0"
          max="10"
          value={amountToPurchase}
          onChange={handleChange}
          className="range range-primary mb-4"
        />
        <div className="flex itesm-center justify-center gap-3">
          <button
            className="btn btn-sm bg-hover-back-light hover:bg-slate-300 dark:hover:bg-hover-back-dark dark:bg-slate-700 border-none text-primary dark:text-primary gap-1"
            onClick={() => setAmountToPurchase(2)}
          >
            <FuseCoin />
            200
          </button>
          <button
            className="btn btn-sm bg-hover-back-light hover:bg-slate-300 dark:hover:bg-hover-back-dark dark:bg-slate-700 border-none text-primary dark:text-primary gap-1"
            onClick={() => setAmountToPurchase(4)}
          >
            <FuseCoin />
            400
          </button>
          <button
            className="btn btn-sm bg-hover-back-light hover:bg-slate-300 dark:hover:bg-hover-back-dark dark:bg-slate-700 border-none text-primary dark:text-primary gap-1"
            onClick={() => setAmountToPurchase(10)}
          >
            <FuseCoin />
            1000
          </button>
        </div>
        <div className="flex items-center justify-center mt-4">
          <Button
            className="btn btn-ghost text-lg text-text-dark dark:text-text-light hover:bg-blue-200 hover:dark:bg-gray-900 font-bold mr-1 w-32"
            onClick={handleCancel}
            disabled={onSubmitting}
          >
            Cancel
          </Button>

          <Button
            className="btn bg-secondary text-text-light hover:text-text-dark hover:bg-accent font-bold ml-1 w-32 hover:drop-shadow-lg"
            onClick={handleSubmitWithLoader}
            disabled={onSubmitting}
            loading={onSubmitting}
          >
            Buy $
            <AnimatedNumber
              className=""
              value={parseInt(fuseCoinCosts(amountToPurchase * 100))}
              size={14}
              duration={200}
            />
          </Button>
        </div>
      </div>
    </>
  );
};

export default PurchaseCoinsModal;
