import ReactTooltip, { Place } from "react-tooltip";
import useUserContext from "src/hooks/private/useUserContext";
import useTheme from "src/hooks/useTheme";
import useViewport from "src/hooks/useViewport";

interface TooltipProps {
  id: string;
  place?: Place;
  backgroundColor?: string;
  content: any;
}

const Tooltip = ({ id, place, backgroundColor, content }: TooltipProps) => {
  const { isMobile } = useViewport();

  const { userSettings } = useUserContext();

  const { tailwindTheme } = useTheme();

  const defaultBackgroundColor = tailwindTheme?.theme3
  const defaultPlace: Place = "top";

  return (
    <>
      <ReactTooltip
        id={id}
        place={place || defaultPlace}
        effect="solid"
        disable={isMobile ? true : userSettings?.isTooltips ? false : true}
        backgroundColor={backgroundColor || defaultBackgroundColor}
        textColor="black"
        className="font-semibold"
      >
        {content}
      </ReactTooltip>
    </>
  );
};

export default Tooltip;
