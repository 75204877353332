import { useEffect } from "react";
import useTeamContext from "src/hooks/private/useTeamContext";
import useUserContext from "src/hooks/private/useUserContext";
import useModal from "src/hooks/useModal";
import PurchaseCoinsModal from "./PurchaseCoinsModal";
import useAuth from "src/hooks/useAuth";
import useApp from "src/hooks/useAppContext";
import getHostName from "src/helpers/getAppFromHost";

const InsufficientFundsModal = () => {
  const { user } = useUserContext();
  const { team, teammates, fetchUserTeamData } = useTeamContext();

  const { openModalWith, setShowModal } = useModal();

  const { app } = useApp();

  const findTeamLeaderEmail = () => {
    const teamLeaderID = team?.leaderID;
    if (user?.id === teamLeaderID) {
      return user?.email;
    } else {
      const teammate = teammates.find(
        (teammate: any) => teammate.id === teamLeaderID
      );
      return teammate?.email;
    }
  };

  const handleAddCoins = () => {
    openModalWith({
      title: `Add ${app?.coinName}`,
      body: <PurchaseCoinsModal />,
      hideButtons: true,
    });
  };

  useEffect(() => {
    fetchUserTeamData();
  }, [fetchUserTeamData]);

  return (
    <>
      <div className="">
        <p className="text-text-dark dark:text-text-light text-center">
          There are not enough {app?.coinName} in your team's account to perform this
          action.
        </p>
        {team?.isVacation && (
          <>
            <br></br>
            <p className="text-text-dark dark:text-text-light text-center">
              When your team is in <b>Vacation Mode</b>, you may only spend
              {app?.coinName} that have been purchased separately from your monthly subscription.
            </p>
          </>
        )}
        {user?.canPurchase || user?.isLeader ? (
          <div className="flex items-center justify-center mt-4">
            <button
              className="btn bg-secondary text-text-light dark:text-text-dark text-lg hover:bg-accent font-bold ml-1 w-48 hover:drop-shadow-lg text-center"
              onClick={handleAddCoins}
            >
              Add {app?.coinName}
            </button>
          </div>
        ) : (
          <>
            <p className="text-text-dark dark:text-text-light text-center mt-4">
              Please{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href={`mailto:${findTeamLeaderEmail()}?subject=Purchase Coins for ${app?.name || getHostName()} account`}
                className="hover:underline text-secondary"
                onClick={() => setShowModal(false)}
              >
                contact
              </a>{" "}
              your team leader to purchase more {app?.name || getHostName()}.
            </p>
          </>
        )}
      </div>
    </>
  );
};

export default InsufficientFundsModal;
