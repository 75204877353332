import { StripeAccount } from "src/interfaces/stripeAccount";
import { FilterParams } from "src/global/types";
import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";

class StripeAccountApi extends ApiTokenSupport {
  /**
   * Get stripe account
   *
   * @returns
   */
  async getStripeAccount(filter?: FilterParams): Promise<StripeAccount[]> {
    const resp = await axios.post<StripeAccount[]>(
      `/api/stripeAccount/filter`,
      { ...filter },
      this.withAuthorization()
    );
    return resp.data;
  }

  async purchaseCoins(amount: number, coins: number): Promise<StripeAccount> {
    //fixed negative coin pass in parameters.
    const resp = await axios.post<StripeAccount>(
      `/api/stripeaccount/purchase-coins`,
      { amount, coins: +coins },
      this.withAuthorization()
    );
    return resp.data;
  }

  async addNewCard(cardToken: any) {
    const resp = await axios.post(
      `/api/stripeaccount/add-new-card`,
      { cardToken, isDefault: true },
      this.withAuthorization()
    );
    return resp.data;
  }
}

export default new StripeAccountApi();
