import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faXmark } from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "src/components/LoadingSpinner";
import { shortenString } from "src/helpers/parseStrings";
import useSearchBar from "src/hooks/useSearchBar";
import useViewport from "src/hooks/useViewport";

const SearchBarDesktop = () => {
  const {
    ref,
    value,
    ready,
    status,
    isLoading,
    searchInput,
    isSearching,
    searchString,
    addressBeingResearched,
    handleInput,
    clearSearch,
    renderSuggestions,
    handleSearchProperties,
  } = useSearchBar();

  const { width } = useViewport();

  return (
    <>
      <div ref={ref} className="w-full">
        <div
          style={{
            width: width > 1122 ? "29rem" : "100%",
          }}
          className="rounded-lg"
        >
          <div className="bg-gradient-to-r from-gradient3 to-gradient4 shadow-md rounded-lg p-[2px] mr-6 min-w-full">
            <div className="flex flex-col justify-between h-full rounded-lg p-[.5px]">
              <form className="relative">
                {isLoading ? (
                  <>
                    <div className="absolute left-[12px] top-[5px]">
                      <div className="flex items-center justify-center gap-0.5 ">
                        <LoadingSpinner className={"text-secondary"} />
                        <div className="text-secondary text-sm font-bold animate-pulse">
                          {`Researching ${shortenString(
                            addressBeingResearched,
                            38
                          )}...`}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <span className="absolute inset-y-0 left-[8px] top-[1px] flex items-center">
                    <FontAwesomeIcon
                      className="text-secondary"
                      icon={faMagnifyingGlass}
                    />
                  </span>
                )}
                <input
                  type="text"
                  name="search"
                  ref={searchInput}
                  onKeyDown={(e: any) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  placeholder={
                    isLoading
                      ? ""
                      : isSearching
                      ? "Search Saved Properties"
                      : "Search New Properties"
                  }
                  className={
                    isSearching
                      ? "min-w-full placeholder:italic placeholder:text-icon-light bg-back-light font-medium dark:bg-back-dark w-64 h-8 pl-9 focus:outline-none text-slate-dark text-back-dark dark:text-text-light rounded-md focus:text-text-dark focus:dark:text-text-light"
                      : "min-w-full placeholder:italic placeholder:text-icon-light bg-back-light font-medium dark:bg-back-dark w-64 h-8 pl-9 focus:outline-none text-slate-dark text-back-dark dark:text-text-light rounded-md focus:text-text-dark focus:dark:text-text-light"
                  }
                  // change to be uncontrolled input
                  // value={isSearching ? searchString : value}
                  onChange={isSearching ? handleSearchProperties : handleInput}
                  // onSubmit={handleSubmit}
                  disabled={!ready}
                />

                {isSearching && searchString && (
                  <div className="relative">
                    <FontAwesomeIcon
                      icon={faXmark}
                      className="absolute -top-[26px] right-[10px] text-gradient4 text-xl md:text-3xl cursor-pointer"
                      onClick={() => {
                        clearSearch("search");
                      }}
                    />
                  </div>
                )}

                {value.length > 0 && (
                  <div className="relative">
                    <FontAwesomeIcon
                      icon={faXmark}
                      className="absolute -top-[26px] right-[10px] text-gradient4 text-xl md:text-3xl cursor-pointer"
                      onClick={() => {
                        clearSearch("value");
                      }}
                    />
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
        <div className="fixed mt-1 bg-card-light dark:bg-card-dark rounded-lg px-2 shadow-xl dark:shadow-md dark:shadow-slate-500">
          {status === "OK" && renderSuggestions()}
        </div>
      </div>
    </>
  );
};

export default SearchBarDesktop;
