import { RegisterInput, UserType as User } from "src/interfaces/user";
import { UserSettings } from "src/interfaces/userSettings";
import { UserNotificationSetting } from "src/global/types";
import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";

class UserApi extends ApiTokenSupport {
  /**
   * Login
   *
   * @param email string
   * @param password string
   * @returns
   */
  async login(
    email: string,
    password: string,
    rememberMe?: boolean
  ): Promise<string> {
    const resp = await axios.post<string>("/api/user/login", {
      email,
      password,
      rememberMe,
    });
    return resp.data;
  }

  /**
   * Register
   *
   * @param data any
   * @returns
   */
  async register(data: RegisterInput): Promise<string> {
    const resp = await axios.post<string>("/api/user/register", { ...data });
    return resp.data;
  }

  /**
   * Recover password
   * @param email
   * @returns
   */
  async recoverPassword(email: string): Promise<boolean> {
    const resp = await axios.post<boolean>(`/api/user/password-recovery`, {
      email,
    });
    return resp.data;
  }

  /**
   * Reset password
   * @param email
   * @returns
   */
  async resetPassword(newPassword: string, token: string): Promise<boolean> {
    const resp = await axios.post<boolean>(`/api/user/password-reset`, {
      newPassword,
      token,
    });
    return resp.data;
  }

  /**
   * Get user by ID
   * @param userID
   * @returns
   */
  async getUser(userID: number): Promise<User> {
    const resp = await axios.get<User>(
      `/api/user/${userID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getUserSettings(userID: number): Promise<UserSettings> {
    const resp = await axios.get<UserSettings>(
      `/api/userSettings/${userID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async update(user: User): Promise<User> {
    const resp = await axios.put<User>(
      `/api/user/${user.id}`,
      user,
      this.withAuthorization(),
    );
    return resp.data;
  }

  /**
   * this method use for get user all user  notification setting data.

   * @returns array of user notification settings
   */
  async getUserNotificationSettings(): Promise<UserNotificationSetting[]> {
    const resp = await axios.get<UserNotificationSetting[]>(
      `/api/notification-settings/settings`,
      this.withAuthorization()
    );
    return resp.data;
  }

  /**
   * 
   * @param event value is  notification name like researches,offers etc.
   * @param type value is notification type like inApp,email,push.
   * @param status value is notification type active or not active.
   * @returns latest object of current notification type.
   */
  async updateUserNotificationSettings(
    event: string,
    type: string,
    status: boolean
  ): Promise<[UserNotificationSetting]> {
    let data: any = {
      event: event,
    };
    data[type] = status;
    const resp = await axios.post<[UserNotificationSetting]>(
      `/api/notification-settings/update-settings`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  async updateSettings(userID: number, data: {}): Promise<UserSettings> {
    const resp = await axios.put<UserSettings>(
      `/api/userSettings/${userID}`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  async verifyUser(token: string): Promise<User> {
    const resp = await axios.get<User>(`/api/user/verify?token=${token}`);
    return resp.data;
  }

  async join(data: {}): Promise<User> {
    const resp = await axios.post<User>(`/api/user/join`, data);
    return resp.data;
  }

  async verifyMigration(token: string): Promise<User> {
    const resp = await axios.get<User>(
      `/api/user/verify-migration?token=${token}`
    );
    return resp.data;
  }

  async migrate(data: {}): Promise<User> {
    const resp = await axios.post<User>(`/api/user/migrate`, data);
    return resp.data;
  }

  async verifyInvitationTokenToV2(token: string): Promise<{ ifsid: number; email: string; subscription: string; redirectTo: string, isAnnual?: boolean }> {
    const resp = await axios.get<{ ifsid: number; email: string; redirectTo: string; subscription: string, isAnnual?: boolean }>(
      `/api/user/verify-invite-v2?token=${token}`
    );
    return resp.data;
  }

  /**
   * Resend invite
   * @param email
   * @returns
   */
    async resendInvite(email: string): Promise<boolean> {
      const resp = await axios.post<boolean>(`/api/user/resend-invite`, {
        email,
      });
      return resp.data;
    }
}

export default new UserApi();
