import React from "react";
import { propertyApi } from "src/api";
import { DebtStack } from "src/interfaces/debtStack";
import Property from "src/interfaces/property";
import { timeAgo } from "src/helpers/parseEpochs";
import useDebtStacks from "src/hooks/private/useDebtStacks";
import useViewport from "src/hooks/useViewport";
import Alert from "../alert/Alert";
import DownloadButton from "../buttons/DownloadButton";
import useModal from "src/hooks/useModal";
import SubscriptionsHelp from "../help/SubscriptionsHelp";
import UpgradeModal from "./UpgradeModal";
import useSubscriptionContext from "src/hooks/private/useSubscription";
import Button from "../buttons/Button";
import useAlert from "src/hooks/useAlert";
import useDrawer from "src/hooks/private/useDrawer";
import useTeamContext from "src/hooks/private/useTeamContext";

interface DebtStackModalProps {
  property: Property;
}

const DebtStackModal = ({ property }: DebtStackModalProps) => {
  const { debtStacks } = useDebtStacks(property.id, true);

  const { isMobile } = useViewport();

  const { setShowModal, openModalWith } = useModal();

  const { fetchUserTeamData } = useTeamContext();

  const { setAlert } = useAlert();

  const { subscriptionFeatures } = useSubscriptionContext();

  const { showPropertyDrawer, refreshProperty } = useDrawer();

  const handleDownload = (debtStackID: number, title: string) => {
    const link = propertyApi.previewDebtStackLink(debtStackID, title, property?.fullAddress);
    window.open(link, "_blank");
  };

  function isDateWithin30Days(dateString: string): boolean {
    const date = new Date(dateString);
    const currentDate = new Date();
    const diffInMs = currentDate.getTime() - date.getTime();
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    return diffInDays < 30;
  }

  const mostRecentDebtStackDate = debtStacks.sort(
    (a: any, b: any) =>
      new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  )[0];

  const alert = {
    display: true,
    message: isMobile
      ? "A recent Debt Stack is less than 30 days old"
      : "A Debt Stack has been ordered in the last 30 days",
    type: "warning",
  };

    const extra =
    subscriptionFeatures?.["DebtStack"].accessType === "fiat"
      ? subscriptionFeatures?.["DebtStack"].fiatCost.toFixed(2)
      : 0;

      const handleSubmit = async () => {
        const { id, houseNumber, street, suffix, city, state, zipcode } = property;
        const property_address = `${houseNumber} ${street} ${suffix}, ${city}, ${state} ${zipcode}`;
    
        setShowModal(false);
        setAlert({
          display: true,
          type: "info",
          message:
            isMobile || showPropertyDrawer
              ? `Searching for Debt Stack`
              : `Searching for Debt Stack, please stand by`,
          duration: 6 * 1000,
        });
        try {
          await propertyApi.orderTitle(property_address, id);
          setAlert({
            display: true,
            type: "success",
            message:
              isMobile || showPropertyDrawer
                ? `Debt Stack found`
                : `Debt Stack found for ${property_address}`,
          });
        } catch (error) {
          setAlert({
            display: true,
            type: "error",
            message:
              isMobile || showPropertyDrawer
                ? `Unable to find Debt Stack`
                : `Unable to find Debt Stack for ${property.fullAddress}`,
          });
        } finally {
          fetchUserTeamData();
          refreshProperty();
        }
      };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleSubscriptionUpgrade = () => {
    openModalWith({
      title: "Upgrade Subscription",
      body: <UpgradeModal mustFullyUpgrade={true} featureClicked={"DebtStack"} />,
      hideButtons: true,
      helpTitle: "Subscriptions",
      helpBody: <SubscriptionsHelp />,
    });
  };

  return (
    <>
      {debtStacks.length ? (
        <>
          <div className="flex columns-1 flex-col items-center justify-center">
            <div className="mb-4 w-full md:w-7/12">
              {isDateWithin30Days(mostRecentDebtStackDate.createdAt) && (
                <Alert alert={alert} />
              )}
            </div>
            <table
              className={"table-compact block table w-full shadow-xl md:w-7/12"}
            >
              <thead>
                <tr className="sticky top-0 border-b-2 border-secondary text-secondary">
                  <th className="select-none  bg-card-light text-center text-xs uppercase text-secondary dark:bg-card-dark">
                    Last Ordered
                  </th>
                  <th className="select-none bg-card-light text-center text-xs uppercase text-secondary dark:bg-card-dark">
                    Debt Stack
                  </th>
                </tr>
              </thead>
              <tbody>
                {debtStacks
                  ?.sort(
                    (a: any, b: any) =>
                      new Date(b.createdAt).getTime() -
                      new Date(a.createdAt).getTime()
                  )
                  .map((debtStack: DebtStack) => (
                    <tr key={debtStack.id}>
                      <td className="bg-card-light text-center font-semibold text-text-dark dark:bg-card-dark dark:text-text-light">
                        {timeAgo(debtStack.createdAt)}
                      </td>
                      <td className="bg-card-light text-center font-semibold text-text-dark dark:bg-card-dark dark:text-text-light">
                        <DownloadButton
                          onClick={() => handleDownload(debtStack.id || 0, debtStack?.title)}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (<></>)}
      {extra !== 0 && (
        <div className="my-4 flex items-center justify-center">
          <div>Request the Skip Trace for ${extra} or &nbsp;</div>
          <p
            onClick={handleSubscriptionUpgrade}
            className="cursor-pointer underline hover:opacity-70"
          >
            upgrade your subscription
          </p>
        </div>
      )}
      <div className="flex items-center justify-center">
        {subscriptionFeatures?.["DebtStack"].accessType === "fiat" ? (
          <>
            <Button
              className="btn-ghost btn mr-1 w-32 text-lg font-bold text-text-dark hover:bg-blue-200 dark:text-text-light hover:dark:bg-gray-900"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className="btn ml-1 w-32 bg-secondary text-lg font-bold text-text-light hover:bg-accent hover:drop-shadow-lg dark:text-text-dark"
              onClick={handleSubmit}
            >
              Submit {extra !== 0 ? `$${extra}` : ""}
            </Button>
          </>
        ) : (
          <>
            <Button
              className="btn-ghost btn mr-1 w-32 text-lg font-bold text-text-dark hover:bg-blue-200 dark:text-text-light hover:dark:bg-gray-900"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className="btn ml-1 w-32 bg-secondary text-lg font-bold text-text-light hover:bg-accent hover:drop-shadow-lg dark:text-text-dark"
              onClick={handleSubmit}
            >
              Submit {extra !== 0 ? `$${extra}` : ""}
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default DebtStackModal;
