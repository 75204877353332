import React, { PropsWithChildren, useCallback, useState } from "react";
import { importApi } from "src/api";
import ImportSession from "src/interfaces/importSession";
import { FilterParams } from "src/global/types";
import useErrorHandler from "src/hooks/private/useErrorHandler";

interface ImportContextInterface {
  importSessions: ImportSession[];
  fetchImportSessions: (filter?: FilterParams) => void;
}

const importSessionContextDefaults: ImportContextInterface = {
  importSessions: [],
  fetchImportSessions: (filter?: FilterParams) => {},
};

const ImportContext = React.createContext<ImportContextInterface>(
  importSessionContextDefaults
);

interface ImportProviderProps {}

export const ImportProvider = ({
  children,
}: PropsWithChildren<ImportProviderProps>) => {
  const [importSessions, setImportSessions] = useState<ImportSession[]>([]);

  const { handleError } = useErrorHandler();

  const fetchImportSessions = useCallback(
    async (filter?: FilterParams) => {
      try {
        const importSessions = await importApi.getImportSessions(filter);
        setImportSessions(importSessions);
      } catch (err) {
        handleError(err);
      }
    },
    [handleError]
  );

  /* useEffect(() => {
    fetchImportSessions()
  }, [fetchImportSessions]) */

  return (
    <ImportContext.Provider
      value={{
        importSessions,
        fetchImportSessions,
      }}
    >
      {children}
    </ImportContext.Provider>
  );
};

export default ImportContext;
