import { actionCosts } from "src/configs/configs";
import useHelpDrawer from "src/hooks/private/useHelpDrawer";
import useViewport from "src/hooks/useViewport";
import FuseCoinsHelp from "./FuseCoinsHelp";
import debtstackGIFDesktop from "src/assets/gifs/Desktop_Debtstack.gif";
import debtstackGIFMobile from "src/assets/gifs/Mobile_Debtstack.gif";
import useAuth from "src/hooks/useAuth";

const DebtStacksHelp = () => {
  const { helpLink } = useHelpDrawer();

 const  { isMobile } = useViewport();

 const { currentUser } = useAuth();

  return (
    <>
      <p>
        Debt Stacks give you data on a property's owner or owners, physical characteristics, recent sales and loans, assessments and tax information, mortgages, construction, nearby neighbors, next sellers, out of state owners and an estimated amortization schedule.
      </p>
      <br></br>
      <p>
        Ordering a Debt Stack costs
        <span className="font-bold"> {actionCosts["title"]} </span>
        <span
          className="font-bold hover:underline text-primary cursor-pointer"
          onClick={() => helpLink(currentUser?.coinName || "FuseCoins™", <FuseCoinsHelp />)}
        >
          
          {currentUser?.coinName}
        </span>
        {" "}and is an appropriate action to take on a property when you are looking
        to get a deeper knowledge of its current financial situation.
      </p>
      <br></br>
        <img
          src={isMobile ? debtstackGIFMobile : debtstackGIFDesktop}
          alt={"How To Research"}
        />
    </>
  );
};

export default DebtStacksHelp;
