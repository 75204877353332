import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Property from "src/interfaces/property";
import { callsEmailsApi } from "src/api";
import useUserContext from "src/hooks/private/useUserContext";
import useTeamContext from "src/hooks/private/useTeamContext";
import useErrorHandler from "src/hooks/private/useErrorHandler";

interface SkipTraceContactButtonProps {
  valueKey: "phone1" | "phone2" | "email1" | "email2" | "domain";
  value: string | number;
  propertyID: number;
}

const SkipTraceContactButton = ({
  valueKey,
  value,
  propertyID,
}: SkipTraceContactButtonProps) => {
  const { user } = useUserContext();

  const { team } = useTeamContext();

  const { handleError } = useErrorHandler();

  const createHREF = () => {
    if (valueKey === "phone1" || valueKey === "phone2") {
      return `tel:${value}`;
    } else if (valueKey === "email1" || valueKey === "email2") {
      return `mailto:${value}`;
    } else {
      return `${value}`;
    }
  };

  const handleClick = async () => {
    if (valueKey === "domain") {
      return;
    }
    if (user?.id && team?.id) {
      try {
        await callsEmailsApi.createCallsEmails({
          userID: user?.id,
          teamID: team.id,
          propertyID: propertyID,
          phoneEmail: `${value}`,
        });
      } catch (err) {
        handleError(err);
      }
    }
  };

  return (
    <a
      className={
        valueKey === "phone1"
          ? "btn-sm btn gap-1 border-none bg-theme1 text-text-light hover:bg-theme1 dark:text-text-dark"
          : valueKey === "phone2"
          ? "btn-sm btn gap-1 border-none bg-theme2 text-text-light hover:bg-theme2 dark:text-text-dark"
          : valueKey === "email1"
          ? "btn-sm btn gap-1 border-none bg-theme3 text-text-light hover:bg-theme3 dark:text-text-dark"
          : "btn-sm btn gap-1 border-none bg-theme4 text-text-light hover:bg-theme4 dark:text-text-dark"
      }
      target="_blank"
      rel="noreferrer"
      href={createHREF()}
      onClick={handleClick}
    >
      <FontAwesomeIcon
        icon={
          valueKey === "phone1" || valueKey === "phone2" ? faPhone : faEnvelope
        }
        className="text-lg"
      />
      <p>
        {valueKey === "phone1" || valueKey === "phone2"
          ? value.toString().replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
          : value.toString()}
      </p>
    </a>
  );
};

interface SkipTraceResultsProps {
  property: Property;
}

const SkipTraceResults = ({ property }: SkipTraceResultsProps) => {
  const { id, phone1, phone2, email1, email2, domain, owner1, owner2 } =
    property;

  console.log(phone2, email2);

  return (
    <>
      {phone1 || phone2 || email1 || email2 ? (
        <div className="flex w-full columns-1 flex-col items-center justify-center gap-1 bg-back-light dark:bg-base-100">
          <table className="table-compact table">
            <tbody>
              <tr>
                <td className="bg-back-light dark:bg-base-100">
                  <div className="font-bold">{owner1}</div>
                </td>
                <td className="flex items-center gap-2 bg-back-light dark:bg-base-100">
                  {phone1 && (
                    <SkipTraceContactButton
                      valueKey={"phone1"}
                      value={phone1}
                      propertyID={id}
                    />
                  )}
                  {email1 && (
                    <SkipTraceContactButton
                      valueKey={"email1"}
                      value={email1}
                      propertyID={id}
                    />
                  )}
                  {domain && (
                    <SkipTraceContactButton
                      valueKey={"email1"}
                      value={domain}
                      propertyID={id}
                    />
                  )}
                </td>
              </tr>
              {(phone2?.toString().length || email2?.length) &&
              phone1 !== phone2 ? (
                <tr>
                  <td className="bg-back-light dark:bg-base-100">
                    <div className="font-bold">{owner2}</div>
                  </td>
                  <td className="flex items-center gap-2 bg-back-light dark:bg-base-100">
                    {phone2?.toString().length && (
                      <SkipTraceContactButton
                        valueKey={"phone1"}
                        value={phone2}
                        propertyID={id}
                      />
                    )}
                    {email2?.length && (
                      <SkipTraceContactButton
                        valueKey={"email1"}
                        value={email2}
                        propertyID={id}
                      />
                    )}
                  </td>
                </tr>
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="font-medium">
          Unable to find any Skip Trace information for this property.
        </p>
      )}
    </>
  );
};

export default SkipTraceResults;
