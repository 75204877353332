import { useEffect, useRef } from "react";
import SubHolder from "./SubHolder";
import useSubscriptionContext from "src/hooks/private/useSubscription";
import { AppSubscriptionsAndFeatures } from "src/interfaces/subscriptions";

interface SubscriptionChoiceProps {
  isUpgrade?: boolean;
  selectedSubscription: number;
  isAnnual: boolean;
  handleSelectSub: (subId: number) => void;
  handleSelectPeriod: (annual: boolean) => void;
}

const SubscriptionChoice = ({
  isUpgrade,
  selectedSubscription,
  isAnnual,
  handleSelectSub,
  handleSelectPeriod,
}: SubscriptionChoiceProps) => {
  const { appSubscriptionsAndFeatures, subscription } =
    useSubscriptionContext();

  const containerRef = useRef(null);

  useEffect(() => {
    const element: any = containerRef.current;
    if (element) {
      const scrollLeft = (element.scrollWidth - element.clientWidth) / 2;
      element.scrollLeft = scrollLeft;
    }
  }, []);

  return (
    <>
      <div
        className="grid auto-cols-max grid-flow-col items-center justify-start gap-4 overflow-x-auto px-8 py-4 md:gap-12"
        ref={containerRef}
      >
        {Object.values(appSubscriptionsAndFeatures || {}).map(
          (sub: AppSubscriptionsAndFeatures, index: number) => (
            <div key={index} className="snap-start">
              <SubHolder
                sub={sub}
                isSelected={sub.subscription.id === selectedSubscription}
                isAnnual={isAnnual}
                handleSelectSub={
                  subscription?.id === sub.subscription.id
                    ? () => {}
                    : handleSelectSub
                }
                handleSelectPeriod={
                  subscription?.id === sub.subscription.id
                    ? () => {}
                    : handleSelectPeriod
                }
                currentSub={subscription?.id === sub.subscription.id}
                isUpgrade={isUpgrade}
              />
            </div>
          )
        )}
      </div>
    </>
  );
};

export default SubscriptionChoice;
