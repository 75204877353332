import React, { useEffect, useState } from "react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faBath,
  faBed,
  faBookmark,
  faRulerCombined,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Property from "src/interfaces/property";
import Tooltip from "src/components/tooltips/Tooltip";
import { propertyApi } from "src/api";
import useAlert from "src/hooks/useAlert";
import useDrawer from "src/hooks/private/useDrawer";
import { shortenString } from "src/helpers/parseStrings";
import useViewport from "src/hooks/useViewport";
import classNames from "classnames";
import UpdatePropertyStatus from "./UpdatePropertyStatus";

interface GalleryCardProps {
  property: Property;
}

const GalleryCard = React.memo(({ property }: GalleryCardProps) => {
  const { isMobile } = useViewport();

  const { openPropertyDrawerWith } = useDrawer();

  const { setAlert } = useAlert();

  const [_isWatchList, setIsWatchlist] = useState<boolean | undefined>(
    property?.isWatchList
  );

  const handleIsWatchlist = async () => {
    try {
      const value = !_isWatchList;
      const result = await propertyApi.toggleWatchList(id, value);
      setIsWatchlist(result?.isWatchList);
    } catch (err: any) {
      setAlert({
        display: true,
        message: err?.message || err || "Unable to toggle Favorite",
        type: "error",
      });
    }
  };

  useEffect(() => {
    setIsWatchlist(property?.isWatchList);
  }, [property?.isWatchList]);

  const {
    id,
    bed,
    bath,
    sqft,
    yearAppraised,
    appraisedValue,
    ownerOccupied,
    owner1,
    fullAddress,
  } = property;

  const handlePropertyClick = () => {
    openPropertyDrawerWith({
      property,
    });
  };

  const streetImg = propertyApi.propertyImg(id, "street");
  const imgClass = !!streetImg ? "object-cover" : "object-contain";

  return (
    <>
      <div className="card card-compact h-full w-full rounded-xl bg-card-light shadow-xl dark:bg-card-dark">
        <figure onClick={handlePropertyClick}>
          {/* set property dynamic image & alter text in image tag */}
          <img
            className={classNames("h-[250px] w-full cursor-pointer", imgClass)}
            src={streetImg ?? "/assets/no_img.webp"}
            alt={fullAddress ?? "property"}
          />
        </figure>
        <div className="card-body flex w-full flex-grow columns-1 flex-col gap-0 @container">
          <div className="flex items-center justify-between">
            <h2
              className={
                fullAddress.length > 38
                  ? "card-title text-secondary @[8rem]:text-[0.25rem] @[9rem]:text-[0.3rem] @[10rem]:text-[0.35rem] @[11rem]:text-[0.37rem] @[12rem]:text-[0.45rem]  @[13rem]:text-[0.55rem] @[14rem]:text-[0.55rem]  @[15rem]:text-[.6rem]  @[16rem]:text-[.625rem] @[17rem]:text-[.65rem] @[18rem]:text-[.7rem] @[19rem]:text-[.75rem] @[20rem]:text-[.8rem] @[21rem]:text-[.85rem]"
                  : "card-title text-secondary @[8rem]:text-[0.25rem] @[9rem]:text-[0.3rem] @[10rem]:text-[0.35rem] @[11rem]:text-[0.37rem] @[12rem]:text-[0.45rem]  @[13rem]:text-[0.55rem] @[14rem]:text-[0.6rem]  @[15rem]:text-[.625rem]  @[16rem]:text-[.65rem] @[17rem]:text-[.70rem] @[18rem]:text-[.75rem] @[19rem]:text-[.85rem] @[20rem]:text-[.9rem] @[21rem]:text-[.95rem]"
              }
            >
              {fullAddress}
            </h2>
            <div className="cursor-pointer">
              <FontAwesomeIcon
                className={
                  _isWatchList
                    ? "cursor-pointer border-none text-xxl text-secondary"
                    : "cursor-pointer border-none text-xxl text-gray-300 dark:text-gray-900"
                }
                onClick={handleIsWatchlist}
                icon={faBookmark as IconDefinition}
                border
              />
              <Tooltip
                id="watchlistButtonSmall"
                place="right"
                content={"Toggle Favorite"}
              />
            </div>
          </div>
          <div className="w-full">
            <h2 className="">
              <FontAwesomeIcon
                className="mr-1 text-text-dark dark:text-text-light"
                icon={faBed}
              />
              <span className="text-text-dark dark:text-text-light">
                {bed ? bed : "?"} Bed
              </span>
              <span className="mx-2 text-text-dark dark:text-text-light">
                |
              </span>{" "}
              <FontAwesomeIcon
                className="mr-1 text-text-dark dark:text-text-light"
                icon={faBath}
              />
              <span className="text-text-dark dark:text-text-light">
                {bath ? bath : "?"} Bath
              </span>
              <span className="mx-2 text-text-dark dark:text-text-light">
                |
              </span>{" "}
              <FontAwesomeIcon
                className="mr-1 text-text-dark dark:text-text-light"
                icon={faRulerCombined}
              />
              <span className="text-text-dark dark:text-text-light">
                {sqft
                  ? sqft.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "?"}{" "}
                ft<sup>2</sup>
              </span>
            </h2>
            <div>
              <p>
                <span className="font-bold text-text-dark dark:text-text-light">
                  Tax Assessed Value
                </span>
                <span className="font-bold text-text-dark dark:text-text-light">
                  {yearAppraised ? `(${yearAppraised}):` : ":"}
                </span>
                <span className="font-medium text-secondary"> $</span>
                <span className="font-extrabold text-secondary">
                  {appraisedValue
                    ? appraisedValue
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : 0}
                </span>
              </p>
            </div>
            <div className="flex w-full items-end justify-between">
              <div>
                <div className="flex columns-1 flex-col font-bold text-text-dark dark:text-text-light">
                  <span className="font-bold text-text-dark dark:text-text-light">
                    {ownerOccupied ? "Occupied " : "Absentee "}Owner:{" "}
                  </span>
                  {owner1 && (
                    <span className="text-secondary">
                      {shortenString(owner1, 23)}
                    </span>
                  )}
                </div>
              </div>
              <div className="">
                <UpdatePropertyStatus
                  propertyID={id}
                  status={property.status}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default GalleryCard;
