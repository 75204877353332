import React from "react";
import { Sequence } from "src/interfaces/sequence";
import { parseMonthDayYear } from "src/helpers/parseEpochs";
import useSequenceLetterContext from "src/hooks/private/useSequenceLetterContext";
import useViewport from "src/hooks/useViewport";
import DeleteButton from "../buttons/DeleteButton";
import UserAvatar from "../buttons/UserAvatar";
import LettersBlock from "../property/LettersBlock";
import { sequenceApi } from "src/api";
import useAlert from "src/hooks/useAlert";
import useModal from "src/hooks/useModal";
import CancelSequenceHelp from "../help/CancelSequenceHelp";
import Property from "src/interfaces/property";
import { faStop } from "@fortawesome/free-solid-svg-icons";
import CircularActionButton from "../buttons/CircularActionButton";

interface SequenceResultsModalProps {
  sequenceID: number;
  property: Property;
}

const SequenceResultsModal = ({
  sequenceID,
  property,
}: SequenceResultsModalProps) => {
  const { sequences, fetchSequences } = useSequenceLetterContext();

  const { setAlert } = useAlert();

  const { openModalWith, setShowModal } = useModal();

  const sequence = sequences.find(
    (sequence: Sequence) => sequence?.id === sequenceID
  );

  const onSequenceCancelSubmit = async (sequenceID: number) => {
    let sequence = await sequenceApi.updateSequence(sequenceID, {
      isActive: false,
    });
    if (sequence.isActive === false) {
      setShowModal(false);
      setAlert({
        type: "success",
        message: "Sequence cancelled successfully",
        display: true,
      });
      fetchSequences();
    } else {
      setShowModal(false);
      setAlert({
        display: true,
        message: "Unable to cancel sequence",
        type: "error",
      });
    }
  };

  const handleSequenceCancel = () => {
    openModalWith({
      title: "Cancel Sequence",
      property: property,
      body: (
        <p className="w-full text-center text-text-dark dark:text-text-light">
          Are you sure you want to cancel this sequence?
        </p>
      ),
      onSubmit: () => onSequenceCancelSubmit(sequenceID),
      helpTitle: "Cancelling a Sequence",
      helpBody: <CancelSequenceHelp />,
    });
  };

  return (
    <>
      {sequence && (
        <div className="w-full">
          <table className={"table-compact block table w-full shadow-xl"}>
            <tbody>
              <tr className="">
                <th className="select-none border-r-2 border-secondary bg-card-light text-center text-xs uppercase text-secondary dark:bg-card-dark">
                  Created
                </th>
                <td className="select-none border-b-secondary bg-card-light text-center text-xs font-semibold dark:bg-card-dark">
                  {parseMonthDayYear(sequence.createdAt)}
                </td>
              </tr>
              <tr>
                <th className="select-none border-r-2 border-secondary bg-card-light text-center text-xs uppercase text-secondary dark:bg-card-dark">
                  Teammate
                </th>

                <td className="flex items-center justify-center border-b-secondary bg-card-light text-center text-base font-semibold text-text-dark dark:bg-card-dark dark:text-text-light">
                  <UserAvatar isSmall={false} userID={sequence.userID} />
                </td>
              </tr>
              <tr>
                <th className="select-none border-r-2 border-secondary bg-card-light text-center text-xs uppercase text-secondary dark:bg-card-dark">
                  Addressed To
                </th>

                <td className="flex items-center justify-center border-b-secondary bg-card-light text-center text-base font-semibold text-text-dark dark:bg-card-dark dark:text-text-light">
                  {sequence.addressTo || property.owner1}
                </td>
              </tr>
              <tr>
                <th className="select-none border-r-2 border-secondary bg-card-light text-center text-xs uppercase text-secondary dark:bg-card-dark">
                  Status
                </th>
                <td className="flex items-center justify-center border-b-secondary bg-card-light text-center text-base font-semibold text-text-dark dark:bg-card-dark dark:text-text-light">
                  <LettersBlock sequence={sequence} />
                </td>
              </tr>
              {sequence.isActive && (
                <tr>
                  <th className="select-none border-r-2 border-secondary bg-card-light text-center text-xs uppercase text-secondary dark:bg-card-dark">
                    Actions
                  </th>
                  <td className="flex items-center justify-center border-b-secondary bg-card-light text-center text-base font-semibold text-text-dark dark:bg-card-dark dark:text-text-light">
                    <div className="tooltip" data-tip={"Cancel Sequence"}>
                      <CircularActionButton
                        icon={faStop}
                        onClick={handleSequenceCancel}
                      />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default SequenceResultsModal;
