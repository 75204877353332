import { RSVP } from 'src/interfaces/rsvp';
import { FilterParams } from 'src/global/types';
import axios from 'src/utils/axios';
import ApiTokenSupport from './token.support';

class RSVPApi extends ApiTokenSupport {

  async getRsvps(filter?: FilterParams): Promise<RSVP[]> {
    const resp = await axios.post<RSVP[]>(`/api/rsvp/filter`, { ...filter } ,this.withAuthorization());
    return resp.data;
  }
  
}

export default new RSVPApi();
