import { PropsWithChildren } from "react";
import { BrowserRouter } from "react-router-dom";

/***** Context Provider *****/
import { ThemeProvider } from "src/contexts/ThemeContext";
import { AuthProvider } from "src/contexts/AuthContext";
import { AlertProvider } from "src/contexts/AlertContext";
import { SpinnerProvider } from "src/contexts/SpinnerContext";
import { NavProvider } from "src/contexts/NavContext";
import { FayeProvider } from "src/faye";
import { RealtimeSyncProvider } from "src/contexts/RealtimeSyncContext";
import { ConfigProvider } from "src/contexts/ConfigContext";
import { AppProvider } from "src/contexts/AppContext";

interface AppProviderProps { }

const ApplicationProvider = ({ children }: PropsWithChildren<AppProviderProps>) => (
  <ThemeProvider>
    <SpinnerProvider>
      <BrowserRouter>
        <NavProvider>
          <AlertProvider>
            <RealtimeSyncProvider>
              <FayeProvider>
                <AppProvider>
                  <ConfigProvider>
                    <AuthProvider>{children}</AuthProvider>
                  </ConfigProvider>
                </AppProvider>
              </FayeProvider>
            </RealtimeSyncProvider>
          </AlertProvider>
        </NavProvider>
      </BrowserRouter>
    </SpinnerProvider>
  </ThemeProvider>
);

export default ApplicationProvider;
