import { TemplatePostcard } from 'src/interfaces/templatePostcard';
import { FilterParams } from 'src/global/types';
import axios from 'src/utils/axios';
import ApiTokenSupport from './token.support';

class TemplatePostcardsApi extends ApiTokenSupport {

  /**
   * Get templates
   * @returns 
   */
  async getTemplatePostcards(filter?: FilterParams): Promise<TemplatePostcard[]> {
    const resp = await axios.post<TemplatePostcard[]>(`/api/template-postcard/filter`, { ...filter } ,this.withAuthorization());
    return resp.data;
  }

  async getUserTemplateIds(postcardNumbers: number[], type?: string): Promise<number[]> {
    const resp = await axios.post<number[]>(`/api/template-postcard/user-templates`, { postcardNumbers, type } ,this.withAuthorization());
    return resp.data;
  }

  /**
   * Get preview of html template as img
   * @param id 
   * @param view 
   * @returns 
   */
  previewUrl(id: number, view: "front" | "back", userID: number, noCache?: boolean, propertyID?: number): string {
    return `${process.env.REACT_APP_BACKEND_URL}/api/template-postcard/preview/${id}/${view}/${userID}?cache=${!noCache}&propertyid=${propertyID || 0}`;
  }

  async getTemplate(id: number): Promise<TemplatePostcard> {
    const resp = await axios.get<TemplatePostcard>(`/api/template-postcard/${id}`,this.withAuthorization());
    return resp.data;
  }

  async update(templateId: number, payload: {}): Promise<TemplatePostcard> {
    const resp = await axios.put<TemplatePostcard>(`/api/template-postcard/${templateId}`, payload, this.withAuthorization());
    return resp.data;
  }
  
}

export default new TemplatePostcardsApi();
