import {
  PropsWithChildren,
  createContext,
  ReactNode,
  useState,
  HTMLAttributes,
} from "react";
import PropertyType, { ModalProperty } from "src/interfaces/property";
import ModalCard from "src/components/modals/ModalCard";

export interface ModalOptionsProps {
  title: string;
  property?: PropertyType | ModalProperty;
  body: ReactNode;
  onSubmit?: (data?: any) => Promise<void>;
  submitLabel?: string;
  cancelLabel?: string;
  hideButtons?: boolean;
  hideCancelButton?: boolean;
  helpTitle?: string;
  helpBody?: ReactNode;
  disableOutsideClick?: boolean;
  onCancel?: () => void;
  modalStyle?: HTMLAttributes<HTMLDivElement>["style"];
}

interface ModalContextValue {
  showModal: boolean;
  setShowModal: (showCard: boolean) => void;
  openModalWith: (o: ModalOptionsProps) => void;
  getModalOptions: () => ModalOptionsProps | null;
}

const defaultState = {
  showModal: false,
  setShowModal: () => {},
  openModalWith: () => {},
  getModalOptions: () => null,
};

export const ModalContext = createContext<ModalContextValue>({
  ...defaultState,
});

interface ModalContextProps {}

export const ModalProvider = ({
  children,
}: PropsWithChildren<ModalContextProps>) => {
  const [showModal, setShowModal] = useState<boolean>(defaultState.showModal);

  const [modalProps, setModalProps] = useState<ModalOptionsProps>({
    title: "",
    body: () => null,
  });

  const openModalWith = (o: ModalOptionsProps) => {
    setModalProps(o);
    setShowModal(true);
  };

  const getModalOptions = () => modalProps;

  return (
    <ModalContext.Provider
      value={{ showModal, setShowModal, openModalWith, getModalOptions }}
    >
      {children}
      <ModalCard {...modalProps} />
    </ModalContext.Provider>
  );
};
