function addressParser(data: string) {
  const addressArray = data.split(" ");
  if (addressArray[1] === "North") {
    addressArray[1] = "N";
  } else if (addressArray[1] === "South") {
    addressArray[1] = "S";
  } else if (addressArray[1] === "East") {
    addressArray[1] = "E";
  } else if (addressArray[1] === "West") {
    addressArray[1] = "W";
  } else if (addressArray[1] === "Northwest") {
    addressArray[1] = "NW";
  } else if (addressArray[1] === "Northeast") {
    addressArray[1] = "NE";
  } else if (addressArray[1] === "Southeast") {
    addressArray[1] = "SE";
  } else if (addressArray[1] === "Southwest") {
    addressArray[1] = "SW";
  }
    addressArray.pop();

  let parsedAddress = addressArray.join(" ").slice(0, -1);

  // if(parsedAddress.length > 36){
  //   return parsedAddress.slice(0, -4);
  // }
  return parsedAddress
}

export default addressParser;
