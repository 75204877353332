import Property from "src/interfaces/property";
// import { timeAgo } from "src/helpers/parseEpochs";
// import { capitalize } from "src/helpers/parseStrings";
import UserAvatar from "../buttons/UserAvatar";
import useViewport from "src/hooks/useViewport";
import classNames from "classnames";
import ActivityBadge from "./ActivityBadge";
import useTimeAgo from "src/hooks/useTimeAgo";
import usePropertyUpdates from "src/hooks/private/usePropertyUpdates";
import { useEffect } from "react";

interface PropertyUpdateTableProps {
  property: Property;
  className?: string;
  hideIfEmpty?: boolean;
}

const PropertyUpdateTable = ({
  property,
  hideIfEmpty,
  className,
}: PropertyUpdateTableProps) => {
  const { id } = property;

  const { isMobile } = useViewport();

  const { timeAgo } = useTimeAgo();

  const { propertyUpdates, fetchAPropertyUpdates } = usePropertyUpdates();

  useEffect(() => {
    if (id) {
      fetchAPropertyUpdates(id);
    }
  }, [property, id, fetchAPropertyUpdates]);

  const _className = classNames("", className);

  return (
    <>
      {hideIfEmpty && propertyUpdates.length === 0 ? (
        <></>
      ) : (
        <div className={_className}>
          <table
            className={
              isMobile || propertyUpdates.length > 5
                ? "table-compact block table w-full rounded-lg shadow-lg"
                : "block table w-full rounded-lg shadow-lg"
            }
          >
            <thead className="">
              <tr className="sticky top-0 border-b-2 border-secondary text-secondary">
                <th className="sticky top-0 bg-card-light text-xs dark:bg-card-dark sm:text-sm">
                  When
                </th>
                <th className="sticky top-0 bg-card-light text-xs dark:bg-card-dark sm:text-sm">
                  Team Member
                </th>
                <th className="sticky top-0 bg-card-light text-xs dark:bg-card-dark sm:text-sm">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {propertyUpdates.length ? (
                propertyUpdates?.map((update) => (
                  <tr key={`tr.up.${update.id}`}>
                    <td className="bg-card-light text-left font-semibold text-text-dark dark:bg-card-dark dark:text-text-light">
                      <small className="italic">
                        {timeAgo(update.createdAt)}
                      </small>
                    </td>
                    <td className="bg-card-light text-left font-semibold text-text-dark dark:bg-card-dark dark:text-text-light">
                      <UserAvatar userID={update.userID} />
                    </td>
                    <td className="bg-card-light text-left font-semibold text-text-dark dark:bg-card-dark dark:text-text-light">
                      <ActivityBadge property={property} update={update} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    className="bg-card-light text-center text-xs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light xs:text-sm sm:text-base"
                    colSpan={3}
                  >
                    No activity on this property yet
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default PropertyUpdateTable;
