import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { capitalize } from "src/helpers/parseStrings";
import classNames from "classnames";

interface SubscriptionBadgeProps {
  subscription: string;
  onClick?: () => void;
  className?: string;
}

const SubscriptionBadge = ({
  subscription,
  onClick,
  className,
}: SubscriptionBadgeProps) => {
  const _classNames = classNames(
    "flex items-center justify-center rounded cursor-pointer font-semibold",
    className
  );

  return (
    <>
      <label className={_classNames} onClick={onClick}>
        <FontAwesomeIcon icon={faArrowUp} className={"mr-1"} />
        <h2 className={""}>{capitalize(subscription)}</h2>
      </label>
    </>
  );
};

export default SubscriptionBadge;
