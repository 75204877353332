

interface LoadMoreProps {
    onClick: () => void;
    loading?: boolean;
}
const LoadMore = ({ onClick, loading }: LoadMoreProps) => {
    return (
        <div className="w-full justify-center flex flex-wrap py-5">
            {loading ? <span className="loading">...</span> : <button
                className="bg-card-light dark:bg-card-dark text-secondary rounded-lg drop-shadow-lg px-10 py-2 select-none"
                disabled={loading}
                onClick={onClick}
            >
                Load more
            </button>}
        </div>
    )
}

export default LoadMore;