import React from "react";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import parseAddress, { trimCountry } from "src/helpers/parseAddress";
import useViewport from "src/hooks/useViewport";

interface ResarchAddressButtonsProps {
  address: string;
  placeID?: string;
  isSmall?: boolean;
}

const ResearchAddressButton = ({
  address,
  placeID,
  isSmall = false,
}: ResarchAddressButtonsProps) => {
  const { isMobile } = useViewport();

  const handleCopyClick = (str: string) => {
    return navigator.clipboard.writeText(str);
  };


  const trimLongAddress = (str: string) => {
      return str.split("").slice(0, 38)
  }

  return (
    <div className="btn-group btn-group-horizontle">
      {placeID ? (
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${placeID}`}
          className={
            isSmall
              ? "btn btn-xs btn-secondary normal-case text-text-light dark:text-text-light text-xxxs sm:text-xs"
              : "btn btn-sm btn-secondary normal-case text-text-light dark:text-text-light text-xs xs:text-sm "
          }
        >
          {isMobile && parseAddress(trimCountry(address)).length > 40
            ? trimLongAddress(parseAddress(trimCountry(address)))
            : address.length > 40
            ? parseAddress(trimCountry(address))
            : trimCountry(address)}
        </a>
      ) : (
        <button
          className={
            isSmall
              ? "btn btn-xs btn-secondary normal-case text-text-light dark:text-text-light text-xxxs"
              : "btn btn-sm btn-secondary normal-case text-text-light dark:text-text-light text-xs"
          }
        >
          {isMobile && address.length > 40
            ? parseAddress(trimCountry(address))
            : trimCountry(address)}
        </button>
      )}
      <button
        className={
          isSmall
            ? "btn btn-xs text-sm hover:bg-secondary text-text-light dark:text-text-light"
            : "btn btn-sm hover:bg-secondary text-text-light dark:text-text-light text-xs"
        }
        onClick={() => handleCopyClick(address)}
      >
        <FontAwesomeIcon icon={faCopy} className="" />
      </button>
    </div>
  );
};

export default ResearchAddressButton;
