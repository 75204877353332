import { PropsWithChildren, createContext, useState, useEffect } from "react";

interface MouseContextValue {
  x: number;
  y: number;
}

const defaultState = {
  x: 0,
  y: 0,
};

export const MouseContext = createContext<MouseContextValue>({
  ...defaultState,
});

interface MouseContextProps {}

export const MouseProvider = ({
  children,
}: PropsWithChildren<MouseContextProps>) => {
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  useEffect(() => {
    const updateMouse = () => {
      window.addEventListener("mousemove", (e) => {
      setX(e.clientX);
      setY(e.clientY);
      });
    };

    window.addEventListener("mousemove", updateMouse);
    
    return () => {
      window.removeEventListener("mousemove", updateMouse);
    };
  }, []);

  return (
    <MouseContext.Provider value={{ x, y }}>{children}</MouseContext.Provider>
  );
};
