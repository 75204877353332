import { forwardRef, useEffect } from "react";
// import useViewport from "src/hooks/useViewport";
import loadImage from "./loadImage";

interface SuspenseImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  noCache?: boolean;
  defaultImg?: string;
}

const SuspenseImage = forwardRef<HTMLImageElement, SuspenseImageProps>(
  ({ noCache, defaultImg, ...props }: SuspenseImageProps, ref) => {
    // const { isMobile } = useViewport();
    loadImage(props.src as string, noCache, defaultImg).read();
    return (
      <div className="w-full bg-white py-24 flex items-center justify-center shadow-xl">
        <div className="w-[350px]">
        <img className={""} {...props} ref={ref} alt={props.alt} />
        </div>
      </div>
    );
  }
);

export default SuspenseImage;
