import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faXmark } from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "src/components/LoadingSpinner";
import { shortenString } from "src/helpers/parseStrings";
import useSearchBar from "src/hooks/useSearchBar";
import useViewport from "src/hooks/useViewport";

const SearchBarMobile = () => {
  const {
    ref,
    value,
    ready,
    status,
    isLoading,
    searchInput,
    isSearching,
    searchString,
    addressBeingResearched,
    clearSearch,
    handleSubmit,
    handleInput,
    handleClick,
    renderSuggestions,
    handleSearchProperties,
  } = useSearchBar();

  let keyboardDismissRef = useRef<any>(null);

  const { isMobile } = useViewport();

  return (
    <>
      <div ref={ref} className="bg-back-light dark:bg-back-dark">
        <div
          onClick={handleClick}
          className="fixed flex items-center justify-center w-screen h-[30px] bg-back-light dark:bg-back-dark left-0 right-0 top-[54px] pt-[32px] "
        >
          <div className="w-full mt-[9px] pb-[7px] px-1.5 bg-back-light dark:bg-back-dark">
            <form onSubmit={handleSubmit}>
              {isLoading ? (
                <>
                  <div className="absolute w-full flex items-center jusitify-center left-[25px] right-0 top-[23px]">
                    <div className="flex items-center justify-center gap-0.5  ">
                      <LoadingSpinner className={"text-secondary"} />
                      <div className="text-secondary text-sm font-bold animate-pulse">
                        {`Researching ${shortenString(
                          addressBeingResearched,
                          29
                        )}...`}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="absolute top-[23px] pl-4">
                  <FontAwesomeIcon
                    className="text-secondary text-xl md:text-3xl"
                    icon={faMagnifyingGlass}
                  />
                </div>
              )}
              <input
                id="searchproperties"
                type="text"
                name="search"
                ref={searchInput}
                onKeyDown={(e: any) => {
                  e.key === "Enter" && e.preventDefault();
                }}
                placeholder={
                  isLoading
                    ? ""
                    : isSearching
                    ? "Search Saved Properties"
                    : "Search New Properties"
                }
                className={
                  isSearching
                    ? "border-[2.5px] border-secondary w-full placeholder:italic placeholder:text-lg placeholder:text-neutral-400 placeholder:dark:text-gray-60 bg-card-light dark:bg-card-dark font-medium h-12 pl-12 shadow-md rounded-lg text-text-light dark:text-text-light text-sm md:text-3xl"
                    : "focus:border-[2.5px] focus:border-secondary w-full placeholder:italic  placeholder:text-lg placeholder:text-neutral-400 placeholder:dark:text-gray-600 bg-card-light dark:bg-card-dark font-medium h-12 pl-12 shadow-md rounded-lg text-text-dark dark:text-text-light text-sm md:text-3xl"
                }
                // value={isSearching ? searchString : value}
                onChange={isSearching ? handleSearchProperties : handleInput}
                // onSubmit={handleSubmit}
                disabled={!ready || isLoading}
              />
              {searchString.length > 0 && (
                <FontAwesomeIcon
                  icon={faXmark}
                  className="absolute top-[23px] right-[20px] text-secondary text-xl md:text-3xl cursor-pointer"
                  onClick={() => {
                    clearSearch("search");
                  }}
                />
              )}
              {value.length > 0 && (
                <FontAwesomeIcon
                  icon={faXmark}
                  className="absolute top-[23px] right-[20px] text-secondary text-xl md:text-3xl cursor-pointer"
                  onClick={() => {
                    clearSearch("value");
                  }}
                />
              )}
            </form>
            <div className="fixed mt-1 right-1.5 left-1.5  bg-card-light dark:bg-back-dark rounded-lg px-1 shadow-xl dark:shadow-md dark:shadow-slate-500 ">
              {status === "OK" && renderSuggestions(isMobile)}
            </div>
          </div>
        </div>
        <div className="hidden">
          <input ref={keyboardDismissRef}></input>
        </div>
      </div>
    </>
  );
};

export default SearchBarMobile;
