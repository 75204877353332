import { faBed, faBath, faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import { propertyApi } from "src/api";
import Property from "src/interfaces/property";
import useDrawer from "src/hooks/private/useDrawer";
import useMousePosition from "src/hooks/useMousePosition";
import useViewport from "src/hooks/useViewport";

interface PropertyButtonProps
  extends Pick<
    Property,
    | "id"
    | "houseNumber"
    | "preDirectional"
    | "street"
    | "suffix"
    | "owner1"
    | "appraisedValue"
    | "bed"
    | "bath"
    | "sqft"
    | "fullAddress"
    | "city"
    | "state"
  > {}

const PropertyButton = ({ ...property }: PropertyButtonProps) => {
  const { width, height } = useViewport();

  const { y } = useMousePosition();

  const layoutChange = width < 1024;

  const switchDropdown = y > height / 1.8;

  const {
    id,
    houseNumber,
    preDirectional,
    street,
    suffix,
    owner1,
    appraisedValue,
    bed,
    bath,
    sqft,
    fullAddress,
    city,
    state,
  } = property;

  const calculateLettersToRemove = () => {
    let num = 20 - Math.floor((430 - width) / 10);
    return num;
  };
  const coerceButtonSize = useMemo(() => {
    const fullAddress = `${houseNumber || ""} ${preDirectional || ""} ${
      street || ""
    } ${suffix || ""}`;
    if (width < 430) {
      if (fullAddress.length > 20 - calculateLettersToRemove()) {
        return fullAddress.slice(0, calculateLettersToRemove());
      } else {
        return fullAddress;
      }
    } else {
      return fullAddress;
    }
  }, [width]);

  const { isMobile } = useViewport();

  const addCityState = useMemo(() => {
    const fullCityState = `${city}, ${state}`;
    if (width < 430) {
      if (fullCityState.length > 20 - calculateLettersToRemove()) {
        return fullCityState.slice(0, calculateLettersToRemove());
      } else {
        return fullCityState;
      }
    } else {
      return fullCityState;
    }
  }, [width]);

  const { openPropertyDrawerWith } = useDrawer();

  const handleClick = () => {
    openPropertyDrawerWith({
      property: { id } as Property,
    });
  };

  const streetImg = propertyApi.propertyImg(id, "street");

  return (
    <>
      <div
        className={
          switchDropdown
            ? "dropdown-hover dropdown-end dropdown dropdown-right"
            : "dropdown-hover dropdown dropdown-right"
        }
      >
        <label
          style={{
            textTransform: "none",
          }}
          tabIndex={0}
          className="btn-ghost btn mr-0.5 px-0 text-sm hover:bg-hover-card-light hover:dark:bg-hover-card-dark sm:px-4 md:text-base"
          onClick={handleClick}
        >
          <div>
            <div className="text-xs xs:text-sm lg:text-base">
              {coerceButtonSize}
            </div>
            <div className="text-xs xs:text-sm lg:text-base">
              {addCityState}
            </div>
          </div>
        </label>
        {!isMobile && (
          <div
            tabIndex={0}
            className={
              layoutChange
                ? "hidden"
                : "delay-600 dropdown-content w-96 select-none rounded-lg bg-back-light p-3 text-sm shadow drop-shadow-xl dark:bg-back-dark"
            }
          >
            <div className="text-center">
              <figure className="mb-2">
                {/* set property dynamic image & alter text in image tag */}
                <img
                  className="h-[250px] w-full rounded-md"
                  src={streetImg ?? "/assets/no_img.webp"}
                  alt={fullAddress ?? "property"}
                  onClick={handleClick}
                />
              </figure>
              <div className="text-left">
                <div>
                  <FontAwesomeIcon
                    className="mr-1 text-text-dark dark:text-text-light"
                    icon={faBed}
                  />
                  <span className="text-text-dark dark:text-text-light">
                    {bed ? bed : "?"} Bed
                  </span>
                  <span className="mx-2 text-text-dark dark:text-text-light">
                    |
                  </span>{" "}
                  <FontAwesomeIcon
                    className="mr-1 text-text-dark dark:text-text-light"
                    icon={faBath}
                  />
                  <span className="text-text-dark dark:text-text-light">
                    {bath ? bath : "?"} Bath
                  </span>
                  <span className="mx-2 text-text-dark dark:text-text-light">
                    |
                  </span>{" "}
                  <FontAwesomeIcon
                    className="mr-1 text-text-dark dark:text-text-light"
                    icon={faHouse}
                  />
                  <span className="text-text-dark dark:text-text-light">
                    {sqft
                      ? sqft.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : "?"}{" "}
                    ft<sup>2</sup>
                  </span>
                </div>

                <p>
                  <span className="font-bold text-text-dark dark:text-text-light">
                    Estimated Value:
                  </span>
                  <span className="font-medium text-accent"> $</span>
                  <span className="font-extrabold text-accent">
                    {appraisedValue
                      ? appraisedValue
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : 0}
                  </span>
                </p>
                <span className="font-bold text-text-dark dark:text-text-light">
                  Owner:
                </span>
                <span className="font-extrabold text-accent"> {owner1}</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PropertyButton;
