import useHelpDrawer from "src/hooks/private/useHelpDrawer";
import SubscriptionsHelp from "./SubscriptionsHelp";
import useAuth from "src/hooks/useAuth";


const FuseCoinsHelp = () => {
  const { helpLink } = useHelpDrawer();

  const { currentUser } = useAuth();

  return (
    <>
      <div>
        <p>
          Monthly, when your subscription is successfully renewed for Pro and
          Platinum{" "}
          <span
            className="cursor-pointer font-bold text-primary hover:underline"
            onClick={() => helpLink("Subscriptions", <SubscriptionsHelp />)}
          >
            Subscriptions
          </span>
          , {currentUser?.coinName} are deposited in your account. They never expire and any
          unused {currentUser?.coinName} rollover for use the next month. Don’t worry that
          your marketing engine will stall though, extra {currentUser?.coinName} are always
          available in lots of 100 or more! Hover or click on the {currentUser?.coinName}
          balance and select Add {currentUser?.coinName}.
        </p>
        <br></br>
        <div className="flex w-full items-center justify-center"></div>
        <div></div>
      </div>
    </>
  );
};

export default FuseCoinsHelp;
