import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { propertyApi } from "src/api";
import { DebtStack } from "src/interfaces/debtStack";
import useUserContext from "src/hooks/private/useUserContext";
import useErrorHandler from "src/hooks/private/useErrorHandler";

interface DebtStackContextInterface {
  debtStacks: DebtStack[];
  fetchDebtStacks: () => void;
}

const debtStackContextDefaults: DebtStackContextInterface = {
  debtStacks: [],
  fetchDebtStacks: () => {},
};

const DebtStackContext = React.createContext<DebtStackContextInterface>(
  debtStackContextDefaults
);

interface DebtStackProviderProps {}

export const DebtStackProvider = ({
  children,
}: PropsWithChildren<DebtStackProviderProps>) => {
  const [debtStacks, setDebtStacks] = useState<DebtStack[]>([]);

  const { user } = useUserContext();

  const { handleError } = useErrorHandler();

  const fetchDebtStacks = useCallback(async () => {
    if (user) {
      try {
        const debtStacks = await propertyApi.getDebtStacks(user?.teamID);
        setDebtStacks(debtStacks);
      } catch (err) {
        handleError(err);
      }
    }
  }, [user, handleError]);

  /* useEffect(() => {
    fetchDebtStacks();
  }, [fetchDebtStacks]); */

  return (
    <DebtStackContext.Provider
      value={{
        debtStacks,
        fetchDebtStacks,
      }}
    >
      {children}
    </DebtStackContext.Provider>
  );
};

export default DebtStackContext;
