import {
  PropsWithChildren,
  createContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { propertyApi } from "src/api";

import PropertyDrawer from "src/components/drawer/PropertyDrawer";
import defaultProperty from "src/global/defaults/property";
import Property from "src/interfaces/property";
import useErrorHandler from "src/hooks/private/useErrorHandler";
import useAlert from "src/hooks/useAlert";
import useRealtimeSync from "src/hooks/useRealtimeSync";

export interface PropertyDrawerOptionsProps {
  property: Property;
}

interface DrawerContextValue {
  showPropertyDrawer: boolean;
  setShowPropertyDrawer: (showingDrawer: boolean) => void;
  openPropertyDrawerWith: (o: PropertyDrawerOptionsProps) => void;
  setPropertyDrawer: React.Dispatch<
    React.SetStateAction<PropertyDrawerOptionsProps>
  >;
  refreshProperty: () => void;
}

const defaultState = {
  showPropertyDrawer: false,
  setShowPropertyDrawer: () => {},
  openPropertyDrawerWith: () => {},
  setPropertyDrawer: () => {},
  refreshProperty: () => {},
};

export const DrawerContext = createContext<DrawerContextValue>({
  ...defaultState,
});

interface DrawerContextProps {}

export const DrawerProvider = ({
  children,
}: PropsWithChildren<DrawerContextProps>) => {
  const { handleError } = useErrorHandler();
  const { propertyUpdater } = useRealtimeSync();

  const { clearAlert } = useAlert();

  const [showPropertyDrawer, setShowPropertyDrawer] = useState<boolean>(
    defaultState.showPropertyDrawer
  );

  const [propertyDrawerProps, setPropertyDrawer] =
    useState<PropertyDrawerOptionsProps>({
      property: defaultProperty,
    });

  const openPropertyDrawerWith = async (o: PropertyDrawerOptionsProps) => {
    await refreshProperty(o.property.id);
    // setPropertyDrawer(o);
    setShowPropertyDrawer(true);
  };

  const refreshProperty = useCallback(
    async (propertyId?: number) => {
      const propertyID = propertyId;

      if (!propertyID) return;

      try {
        const property = await propertyApi.getProperty(propertyID);
        setPropertyDrawer({ property });
      } catch (err: any) {
        handleError(err);
      }
      return "fetched";
    },
    [handleError, propertyDrawerProps]
  );

  useEffect(() => {
    const { property } = propertyUpdater;
    if (property && property?.id === propertyDrawerProps?.property?.id) {
      refreshProperty(property.id);
    }
  }, [propertyUpdater]);

  const handleClickOusideDrawer = () => {
    setShowPropertyDrawer(false);
    clearAlert();
  };

  const backGroundColor = "rgba(0,0,0,0.69)";

  return (
    <DrawerContext.Provider
      value={{
        showPropertyDrawer,
        setShowPropertyDrawer,
        openPropertyDrawerWith,
        setPropertyDrawer,
        refreshProperty,
      }}
    >
      <div className="drawer drawer-end">
        <input
          id="my-drawer-right"
          type="checkbox"
          className="drawer-toggle"
          checked={showPropertyDrawer}
          onChange={() => {}}
        />
        <div className="drawer-content">
          <div className="drawer">
            <div className="drawer-content">{children}</div>
          </div>
        </div>
        <div className="drawer-side">
          <label
            htmlFor="my-drawer-right"
            className="drawer-overlay"
            onClick={handleClickOusideDrawer}
            style={{
              backgroundColor: backGroundColor,
            }}
          ></label>
          <div className="menu w-10/12 overflow-y-auto bg-back-light p-4 dark:bg-back-dark sm:w-9/12 md:w-8/12 lg:w-7/12 xl:w-5/12 2xl:w-4/12">
            <PropertyDrawer {...propertyDrawerProps} />
          </div>
        </div>
      </div>
    </DrawerContext.Provider>
  );
};
