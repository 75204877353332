import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { notificationApi } from "src/api";
import { listenNewNotification } from "src/faye/listener";
import useRealtimeSync from "src/hooks/useRealtimeSync";
import useNotification from "src/hooks/private/useNotification";
import useErrorHandler from "src/hooks/private/useErrorHandler";
import useAuth from "src/hooks/useAuth";
import InlineNotification from "src/components/notifications/InlineNotification";

interface ListenerProps {
  teamID: number;
}

const Listener = React.memo(({ teamID }: ListenerProps) => {
  listenNewNotification(teamID);
  return <></>;
})

const AlertButton = () => {
  const { notifications, getUserNotifications } = useNotification();
  const [unreadNotification, setUnreadNotification] = useState<number>(0);
  const { handleError } = useErrorHandler();
  const { currentUser } = useAuth();
  const { notificationUpdater } = useRealtimeSync();

  const getNotifCount = useCallback(async () => {
    try {
      const count = await notificationApi.getUserNotificationCount();
      setUnreadNotification(count)
    } catch (error: any) {
      handleError(error);
    }
  }, [handleError]);

  const resetCount = async () => {
    try {
      await notificationApi.resetUserNotificationCount();
      setUnreadNotification(0);
    } catch (err) { }
  }

  const getNotifications = useCallback(async () => {
    let params = {
      limit: 5,
      page: 1,
      sort: "-createdAt",
      query: {
        showUnreadOnly: false,
      },
    };

    await getUserNotifications(params);

  }, [getUserNotifications]);

  useEffect(() => {
    getNotifCount();
    getNotifications();
  }, [notificationUpdater, getNotifications, getNotifCount]);

  return (
    <>
      {currentUser?.teamID && <Listener teamID={currentUser.teamID} />}
      <div className="dropdown dropdown-hover flex flex-col columns-1 items-center dropdown-bottom">
        <button
          tabIndex={0}
          className="btn btn-ghost hover:bg-hover-back-light dark:hover:bg-hover-back-dark relative"
          onClick={resetCount}
        >
          <FontAwesomeIcon icon={faBell} className="text-primary text-xl" />
          {unreadNotification !== 0 && (
            <div className="badge badge-accent absolute top-0 left-3 font-extrabold text-sm">
              {unreadNotification < 99 ? unreadNotification : "99+"}
            </div>
          )}
        </button>

        <ul
          tabIndex={0}
          className="dropdown-content p-2 drop-shadow-xl bg-card-light dark:bg-card-dark md:dark:bg-card-dark rounded-box border-2 border-primary mt-0.5 overflow-auto max-h-[400px] h-hull 2xl:w-[550px] xl:w-[550px] lg:w-[550px]  md:w-[400px] sm:w-[350px] w-[300px] sm:max-h-max xs:w-[300px] xs:max-h-[400px]"
        >
          <div id="dropdownUsers" className="z-10">
            {notifications && notifications.length !== 0 ? (
              notifications
                ?.slice(0, 5)
                ?.map((notif, index: number) => <InlineNotification key={index} notification={notif} />)
            ) : (
              <div className="flex flex-wrap mt-4 text-center justify-center">
                <label className="text-text-dark dark:text-text-light">
                  There is no new notification.
                </label>
              </div>
            )}
            <div className="flex flex-wrap my-2 justify-center text-text-dark dark:text-text-light font-semibold hover:underline">
              <Link to="/notifications">View all</Link>
            </div>
          </div>
        </ul>
      </div>
    </>
  );
};

export default AlertButton;
