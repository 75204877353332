import React from "react";
import StaticActionBadge from "src/components/badges/StaticActionBadge";


const WhatsNew1 = () => {
  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <div className="mb-4">
          <StaticActionBadge title="Campaigns" />
        </div>
        <div className="px-4">
          <ul className="text-left">
            <li>
              - A Campaign is a set of reusable settings for a Sequence.
            </li>
            <li>
              - Name, order and save your Campaigns to quickly use them for
              Sequences.
            </li>
            <li>
              - Letters can now be sent in addition to Postcards in a Sequence.
            </li>
            <li>
              - Unlike Postcards, letters cannot be edited at this time.
            </li>
            <li>
              - Head over to the Sequences tab to access Templates and Campaigns.
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default WhatsNew1;
