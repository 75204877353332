import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useUserContext from "src/hooks/private/useUserContext";
import useModal from "src/hooks/useModal";
import useViewport from "src/hooks/useViewport";
import AnimatedNumber from "react-awesome-animated-number";
import "react-awesome-animated-number/dist/index.css";
import FuseCoinsHelp from "src/components/help/FuseCoinsHelp";
import PurchaseCoinsModal from "src/components/modals/PurchaseCoinsModal";
import SubscriptionsHelp from "src/components/help/SubscriptionsHelp";
import UpgradeModal from "src/components/modals/UpgradeModal";
import useTeamContext from "src/hooks/private/useTeamContext";
import FuseCoin from "src/components/icons/FuseCoin";
import useAuth from "src/hooks/useAuth";

interface CoinBalanceProps {
  isDisabled?: boolean;
}

const CoinBalance = ({ isDisabled = false }: CoinBalanceProps) => {
  const { team } = useTeamContext();
  const { user } = useUserContext();
  const { width } = useViewport();
  const { openModalWith } = useModal();

  const { currentUser } = useAuth();

  let dropDownProps = { tabIndex: 0 };

  const [coinBalance, setCoinBalance] = useState<number>(
    team ? team?.coinsPurchased + team?.coinsSubscription + 50 : 0
  );

  const handleAddCoins = () => {
    openModalWith({
      title: `Add ${currentUser?.coinName}`,
      hideButtons: true,
      body: <PurchaseCoinsModal />,
      helpTitle: "Purchasing Coins",
      helpBody: <FuseCoinsHelp />,
    });
  };

  const handleUpgrade = () => {
    openModalWith({
      title: "Upgrade Subscription",
      body: <UpgradeModal hideExplanation={true} />,
      hideButtons: true,
      helpTitle: "Subscriptions",
      helpBody: <SubscriptionsHelp />,
    });
  };

  useEffect(() => {
    if (team) {
      setCoinBalance(team?.coinsPurchased + team?.coinsSubscription);
    }
  }, [team]);

  return (
    <>
      <div
        className={
          width < 1024
            ? "dropdown dropdown-hover my-0 py-0 cursor-pointer"
            : "dropdown dropdown-hover dropdown-end my-0 py-0 cursor-pointer"
        }
      >
        <label
          {...dropDownProps}
          className="px-1 h-8 border-2 border-primary flex items-center justify-center bg-card-light dark:bg-card-dark rounded-lg shadow-lg drop-shadow-lg space-x-1 cursor-pointer select-none mt-0.5"
        >
          <FuseCoin />
          <span className="text-text-dark dark:text-text-light text-sm font-extrabold select-none">
            <AnimatedNumber
              className="mr-0.5 lg:mr-1"
              value={coinBalance}
              size={14}
              duration={200}
            />
          </span>
        </label>
        <ul
          {...dropDownProps}
          className="dropdown-content menu p-2 drop-shadow-xl bg-card-light dark:bg-card-dark rounded-box w-52 text-primary border-2 border-primary mt-0.5"
        >
          <li className="mb-1">
            <button
              onClick={handleAddCoins}
              className="btn btn-ghost hover:bg-hover-card-light dark:hover:bg-hover-card-dark font-bold"
            >
              Add {currentUser?.coinName}
            </button>
          </li>
          {/* {team?.subscription !== "platinum" && user?.isLeader && (
            <li className="mb-1">
              <button
                className="btn btn-ghost hover:bg-hover-card-light dark:hover:bg-hover-card-dark  font-bold"
                onClick={handleUpgrade}
              >
                Upgrade
              </button>
            </li>
          )} */}
          {!isDisabled && (
            <Link to="/coinhistory">
              <li className="mb-1">
                <button className="btn btn-ghost hover:bg-hover-card-light dark:hover:bg-hover-card-dark font-bold">
                  Transactions
                </button>
              </li>
            </Link>
          )}
        </ul>
      </div>
    </>
  );
};

export default CoinBalance;
