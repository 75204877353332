import { useCallback, useEffect, useRef, useState } from "react";
import { propertyApi, watchlistApi } from "src/api";
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";
import useAlert from "./useAlert";
import useDrawer from "./private/useDrawer";
import useModal from "src/hooks/useModal";
import States, { StatesArray } from "src/data/States";
import useOnclickOutside from "react-cool-onclickoutside";
import addressParser from "src/api/GooglePlaces/addressParser";
import Property from "src/interfaces/property";
import usePropertiesContext from "./private/usePropertiesContext";
import { actionCosts } from "src/configs/configs";
import ResearchModal from "src/components/modals/ResearchModal";
import ResearchingHelp from "src/components/help/ResearchingHelp";
import useTheme from "./useTheme";
import useTeamContext from "./private/useTeamContext";
import { throttle } from "src/helpers/js-utils";
import parseAddress from "src/helpers/parseAddress";
import { trimCountry } from "src/helpers/parseAddress";
import { ModalOptionsProps } from "src/contexts/ModalContext";
import useUserContext from "./private/useUserContext";
import WatchlistHelp from "src/components/help/WatchlistHelp";
import useWatchlist from "./private/useWatchlist";
import { useNavigate } from "react-router-dom";
import ResearchAddressButton from "src/components/buttons/ResearchAddressButton";

const useSearchBar = () => {
  const { mode } = useTheme();
  const { setAlert, clearAlert } = useAlert();
  const { checkCoinBalance, fetchUserTeamData } = useTeamContext();
  const { openModalWith, setShowModal } = useModal();
  const { openPropertyDrawerWith } = useDrawer();
  const { isSearching, fetchProperties, unshiftProperty } =
    usePropertiesContext();
  const { fetchWatchlist } = useWatchlist();
  const { user } = useUserContext();
  const navigate = useNavigate();
  const { team } = useTeamContext();

  const [isLoading, setIsLoading] = useState(false);
  const [addressBeingResearched, setAddressBeingResearched] =
    useState<string>("");
  const [searchString, setSearchString] = useState<string>("");

  const searchInput = useRef<HTMLInputElement>(null);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 1000,
    cache: 48 * 60 * 60,
    requestOptions: {
      types: ["address"],
      componentRestrictions: {
        country: "us",
      },
    },
  });

  const focusInput = () => {
    searchInput?.current?.focus();
  };

  const handleClick = () => {
    focusInput();
    clearAlert();
  };

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);
  };

  const getFullAddress = async (address: string) => {
    const parameter = {
      address: address,
    };

    const [results] = await getGeocode(parameter);
    return results;
  };

  const findStateAbbreviated = (state: string) => {
    const stateObj = States.find((stateObj) => stateObj.name === state);
    return stateObj?.label;
  };

  const getStateFromDescription = (str: string) => {
    const address = str.split(", ");
    address.pop();
    let state = address.pop();
    if(state && state?.length > 2) {
      state = state.slice(0,2)
    }

    if (state && state.length > 2) {
      state = findStateAbbreviated(state.toLowerCase());
    }
    if (state && StatesArray.includes(state)) {
      return state;
    } else {
      return "Unknown";
    }
  };

  const checkExistingProperty = async (address: string, place_id?: string) => {
    if (!place_id) {
      const gRes = await getFullAddress(address);
      place_id = gRes?.place_id;
    }

    if (place_id) {
      const existingProperty = await propertyApi.propertyAlreadySearched(
        place_id
      );
      if (existingProperty) {
        return { ...existingProperty, place_id };
      }
    }

    return null;
  };

  const handleResearchSubmit = async (
    description: string,
    placeId: string,
    propertyAlreadySearched?: Property | null,
    fromWatchList: boolean = false
  ) => {
    clearSearch("value");
    try {
      if (propertyAlreadySearched) {
        setShowModal(false);
        return openPropertyDrawerWith({
          property: propertyAlreadySearched,
        });
      }
      setIsLoading(true);
      setAddressBeingResearched(parseAddress(description));
      !fromWatchList && setShowModal(false);
      const result = await propertyApi.researchProperty({
        address: parseAddress(description),
        placeId,
      });
      unshiftProperty(result);
      fetchUserTeamData();
      openPropertyDrawerWith({
        property: result,
      });
    } catch (err: any) {
      setAlert({
        display: true,
        message: err?.message || err || "Unable to research this property",
        type: "error",
      });
    } finally {
      setIsLoading(false);
      setShowModal(false);
      setAddressBeingResearched("");
    }
  };

  const handleAddToWatchlist = async (address: string, placeID: string) => {
    setShowModal(false);
    try {
      const watchlist = await watchlistApi.createWatchlist({
        userID: user?.id,
        teamID: team?.id,
        fullAddress: address,
        placeID: placeID,
      });
      if (watchlist) {
        setAlert({
          display: true,
          message: "Address added to Watchlist",
          type: "success",
        });
      }
      fetchWatchlist();
      navigate("/watchlist");
    } catch (err) {
      setAlert({
        display: true,
        message: "An error occurred adding this address to Watchlist",
        type: "error",
      });
    }
  };

  const handleSelect = async (
    address: string,
    fromWatchlist: boolean = false,
    place_id?: string
  ) => {
    clearAlert();
    try {
      // Use google place id to check if property has already been researched
      const result = await checkExistingProperty(address, place_id);

      // Set coins to spend to 0 in case of property already researched
      const coinToSpend = result ? 0 : actionCosts["research"];

      const isBalanceOk = checkCoinBalance(coinToSpend);

      let modalProps: ModalOptionsProps = user?.isBirddog
        ? {
            title: "Add To Watchlist",
            helpTitle: "Watchlist",
            helpBody: <WatchlistHelp />,
            submitLabel: "Add",
            body: (
              <ResearchAddressButton address={address} placeID={place_id} />
            ),
            onSubmit: () => handleAddToWatchlist(address, place_id || ""),
          }
        : user?.canResearch
        ? {
            title: "Research Property",
            hideButtons: !isBalanceOk,
            helpTitle: "Researching",
            helpBody: <ResearchingHelp />,
            submitLabel: "Submit",
            body: (
              <ResearchModal
                address={address}
                placeID={place_id || ""}
                showWatchlist={!fromWatchlist && user?.canWatchlist}
              />
            ),
            onSubmit: () =>
              isBalanceOk
                ? handleResearchSubmit(
                    address,
                    (result?.place_id || place_id) as string,
                    result,
                    fromWatchlist
                  )
                : Promise.resolve(),
          }
        : {
            title: "Add To Watchlist",
            helpTitle: "Watchlist",
            helpBody: <WatchlistHelp />,
            submitLabel: "Add",
            body: (
              <ResearchAddressButton address={address} placeID={place_id} />
            ),
            onSubmit: () => handleAddToWatchlist(address, place_id || ""),
          };

      openModalWith({
        ...modalProps,
      });
    } catch (error: any) {
      setValue(addressParser(address), false);
      clearSuggestions();
      if (error?.code === 400) {
      } else {
        setAlert({
          display: true,
          message: error?.message ?? "Something went wrong, Please try later.",
          type: "error",
        });
      }
    }
  };

  // exclusively for searching new property
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isSearching) {
      handleSelect(value, false);
    }
  };

  // As the searchInput has been changed to uncontrolled component, we have to reset the input value manually
  // uncontrolled component give the ability to throttle input fire action
  const clearSearch = (key: "value" | "search") => {
    if (key === "value") {
      setValue("");
    } else {
      setSearchString("");
    }
    if (searchInput?.current?.value !== undefined) {
      searchInput.current.value = "";
    }
  };

  const handleSearchSavedProperties = () => {
    if (!isSearching) {
      return;
    }
    fetchProperties({
      search: searchString.toLowerCase(),
      searchFields: "fullAddress",
    });
  };

  // Throttle setting state to avoid multiple api search properties call
  // Using useCallback here to avoid to recreate every stating change the throttle
  const handleSearchProperties = useCallback(
    throttle((e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      let searchParameters = e.target.value || "";
      setSearchString(searchParameters);
    }, 2000),
    []
  );

  // Only fire search on searchString change
  useEffect(() => {
    handleSearchSavedProperties();
  }, [searchString]);

  const renderSuggestions = (isMobile?: boolean): JSX.Element => {
    const suggestions = data.map(
      ({ place_id, description }: any, index: number) => (
        <li
          className={
            index === 0
              ? "flex min-w-full list-none items-center justify-start rounded-lg py-0.5 pt-2 text-lg text-text-light dark:text-text-light"
              : index === data.length - 1
              ? "flex min-w-full list-none items-center justify-start rounded-lg py-0.5 pb-2 text-lg text-text-light dark:text-text-light"
              : "flex min-w-full list-none items-center justify-start rounded-lg py-0.5 text-lg text-text-light dark:text-text-light"
          }
          key={place_id}
          value={description}
          onClick={() => handleSelect(description, false, place_id)}
        >
          <button
            style={{
              textTransform: "none",
            }}
            className={
              isMobile && description.length > 40
                ? parseAddress(trimCountry(description)).length > 40
                  ? "btn-ghost btn flex w-full items-center justify-start gap-3 text-xxs text-text-dark hover:bg-blue-100 dark:text-text-light dark:hover:bg-gray-900"
                  : "btn-ghost btn flex w-full items-center justify-start gap-3 text-xs text-text-dark hover:bg-blue-100 dark:text-text-light dark:hover:bg-gray-900"
                : "btn-ghost btn flex w-full items-center justify-start gap-3 text-text-dark hover:bg-blue-100 dark:text-text-light dark:hover:bg-gray-900"
            }
          >
            <img
              className={isMobile ? "w-[27px]" : "w-[33px]"}
              src={require(`src/assets/icons/${getStateFromDescription(
                description
              )}-${mode === "light" ? "dark" : "light"}.png`)}
              alt="state"
            />
            {description.length > 40
              ? parseAddress(trimCountry(description))
              : trimCountry(description)}
          </button>
        </li>
      )
    );
    return <div className="pb-1.5">{suggestions}</div>;
  };

  const renderStaticSuggestions = (
    isMobile: boolean,
    callBack: (address: string, placeID: string, id: string) => void,
    id: string
  ): JSX.Element => {
    const suggestions = data.map(
      ({ place_id, description }: any, index: number) => (
        <li
          className={
            index === 0
              ? "flex min-w-full list-none items-center justify-start rounded-lg py-0.5 pt-2 text-lg text-text-light dark:text-text-light"
              : index === data.length - 1
              ? "flex min-w-full list-none items-center justify-start rounded-lg py-0.5 pb-2 text-lg text-text-light dark:text-text-light"
              : "flex min-w-full list-none items-center justify-start rounded-lg py-0.5 text-lg text-text-light dark:text-text-light"
          }
          key={place_id}
          value={description}
          onClick={() => callBack(description, place_id, id)}
        >
          <button
            style={{
              textTransform: "none",
            }}
            className={
              isMobile && description.length > 40
                ? parseAddress(trimCountry(description)).length > 40
                  ? "btn-ghost btn flex w-full items-center justify-start gap-3 text-xxs text-text-dark hover:bg-blue-100 dark:text-text-light dark:hover:bg-gray-900"
                  : "btn-ghost btn flex w-full items-center justify-start gap-3 text-xs text-text-dark hover:bg-blue-100 dark:text-text-light dark:hover:bg-gray-900"
                : "btn-ghost btn flex w-full items-center justify-start gap-3 text-text-dark hover:bg-blue-100 dark:text-text-light dark:hover:bg-gray-900"
            }
          >
            <img
              className={isMobile ? "w-[27px]" : "w-[33px]"}
              src={require(`src/assets/icons/${getStateFromDescription(
                description
              )}-${mode === "light" ? "dark" : "light"}.png`)}
              alt="state"
            />
            {description.length > 40
              ? parseAddress(trimCountry(description))
              : trimCountry(description)}
          </button>
        </li>
      )
    );
    return <>{suggestions}</>;
  };

  useEffect(() => {
    if (isSearching) {
      focusInput();
    }
  }, [isSearching]);

  return {
    ref,
    ready,
    value,
    status,
    isLoading,
    searchInput,
    isSearching,
    searchString,
    addressBeingResearched,
    setValue,
    handleInput,
    handleClick,
    handleSubmit,
    handleSelect,
    clearSearch,
    setSearchString,
    renderSuggestions,
    renderStaticSuggestions,
    handleSearchProperties,
    checkExistingProperty,
  };
};

export default useSearchBar;
