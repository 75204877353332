import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import {
  faMagnifyingGlass,
  faFileCsv,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, SetStateAction } from "react";
import useSearchBar from "src/hooks/useSearchBar";
import useViewport from "src/hooks/useViewport";

interface SearchBarModalProps {
  id: string;
  selectedValue: string;
  onSelect: (address: string, placeID: string, id: string) => void;
  hasMadeSelection: boolean;
  setHasMadeSelection: Dispatch<SetStateAction<boolean>>;
  source: "file" | "research";
  error?: boolean;
}

const SearchBarModal = ({
  id,
  selectedValue,
  onSelect,
  hasMadeSelection,
  setHasMadeSelection,
  source,
  error,
}: SearchBarModalProps) => {
  const {
    ref,
    ready,
    status,
    isLoading,
    searchInput,
    handleSubmit,
    handleInput,
    renderStaticSuggestions,
  } = useSearchBar();

  const { isMobile } = useViewport();

  return (
    <>
      {source === "file" ? (
        <div className="group relative">
          <div className="absolute top-[6px] left-[5px]">
            <FontAwesomeIcon
              className="md:text-3xl text-xl text-secondary"
              icon={faFileCsv}
            />
          </div>
          <input
            type="text"
            value={selectedValue}
            onKeyDown={(e: any) => {
              e.key === "Enter" && e.preventDefault();
            }}
            placeholder="Search "
            className={
              "input input-sm w-96 bg-card-light pl-8 hover:bg-card-light focus:bg-card-light dark:bg-back-dark hover:dark:bg-back-dark focus:dark:bg-back-dark"
            }
            onChange={handleInput}
            disabled={!ready || isLoading}
          />
        </div>
      ) : (
        <div ref={ref} className="rounded-lg bg-back-light dark:bg-back-dark">
          <form onSubmit={handleSubmit} className="">
            {hasMadeSelection ? (
              <div className="group relative">
                <div className="absolute top-[6px] left-[8px]">
                  <FontAwesomeIcon
                    className="md:text-3xl text-xl text-secondary"
                    icon={faMagnifyingGlass}
                  />
                </div>
                <input
                  type="text"
                  value={selectedValue}
                  onKeyDown={(e: any) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  placeholder="Search "
                  className={
                    "input input-sm w-96 bg-card-light pl-8 hover:bg-card-light focus:bg-card-light dark:bg-back-dark hover:dark:bg-back-dark focus:dark:bg-back-dark"
                  }
                  onChange={handleInput}
                  disabled={!ready || isLoading}
                />
                <div className="absolute top-[5px] right-[10px] hidden w-6 bg-card-light group-hover:block dark:bg-back-dark">
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    className="md:text-3xl cursor-pointer text-xl text-secondary"
                    onClick={() => {
                      setHasMadeSelection(false);
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="">
                <div className="relative">
                  <div className="absolute top-[6px] left-[8px]">
                    <FontAwesomeIcon
                      className="md:text-3xl text-xl text-secondary"
                      icon={faMagnifyingGlass}
                    />
                  </div>
                </div>
                <input
                  type="text"
                  ref={searchInput}
                  onKeyDown={(e: any) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  placeholder="Search "
                  className={
                    error
                      ? "input input-sm w-96 border border-error bg-card-light pl-8 hover:bg-card-light focus:bg-card-light dark:bg-back-dark hover:dark:bg-back-dark focus:dark:bg-back-dark"
                      : "input input-sm w-96 bg-card-light pl-8 hover:bg-card-light focus:bg-card-light dark:bg-back-dark hover:dark:bg-back-dark focus:dark:bg-back-dark"
                  }
                  onChange={handleInput}
                  disabled={!ready || isLoading}
                />
                <div className="absolute z-50 mt-1 rounded-lg bg-card-light px-1 shadow-xl dark:bg-back-dark">
                  {status === "OK" &&
                    renderStaticSuggestions(isMobile, onSelect, id)}
                </div>
              </div>
            )}
          </form>
        </div>
      )}
    </>
  );
};

export default SearchBarModal;
