import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useModal from "src/hooks/useModal";
import UpgradeModal from "src/components/modals/UpgradeModal";
import SubscriptionsHelp from "src/components/help/SubscriptionsHelp";
import useSubscriptionContext from "src/hooks/private/useSubscription";
import { useState, useEffect } from "react";
import useTeamContext from "src/hooks/private/useTeamContext";

const Upgrade = () => {
  const { openModalWith } = useModal();

  const { team } = useTeamContext();

  const handleClick = () => {
    openModalWith({
      title: "Upgrade Subscription",
      body: <UpgradeModal hideExplanation={true} />,
      hideButtons: true,
      helpTitle: "Subscriptions",
      helpBody: <SubscriptionsHelp />,
    });
  };

  const { appSubscriptions, subscription } = useSubscriptionContext();

  const [isUpgradeEligible, setIsUpgradeEligible] = useState(false);

  useEffect(() => {
    const determineUpgradeEligibility = () => {
      if (!appSubscriptions || !subscription?.sortOrder) return false;

      let highestSortOrder = Math.max(
        ...appSubscriptions.map((sub) => sub.sortOrder)
      );

      return subscription.sortOrder < highestSortOrder;
    };

    setIsUpgradeEligible(determineUpgradeEligibility());
  }, [appSubscriptions, subscription]);

  return (
    <>
      {team && !team.isDemo && isUpgradeEligible && (
        <button
          className="btn-primary btn-ghost btn text-xl text-primary hover:bg-hover-back-light dark:hover:bg-hover-back-dark"
          onClick={handleClick}
        >
          <FontAwesomeIcon
            icon={faArrowUp}
            className="mr-2 text-xl text-primary"
          />
          Upgrade
        </button>
      )}
    </>
  );
};

export default Upgrade;
