import useHelpDrawer from "src/hooks/private/useHelpDrawer";
import useViewport from "src/hooks/useViewport";
import CampaignsHelp from "./CampaignsHelp";

const SequencesHelp = () => {
  const { helpLink } = useHelpDrawer();

  const { isMobile } = useViewport();

  return (
    <>
      <div>
        <p>
          A Sequence is a series of mailings sent to a properties owner. When
          creating a sequence you can choose between 3 and 8
          postcards or letters. Before you send your Sequence you must either select a <span
            className="font-bold hover:underline text-primary cursor-pointer"
            onClick={() => helpLink("Campaigns", <CampaignsHelp />)}
          >
            Campaign
          </span> or create custom settings for that particular Sequence.
        </p>
        <br></br>
      </div>
    </>
  );
};

export default SequencesHelp;
