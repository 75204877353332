import React, { useState } from "react";
import useModal from "src/hooks/useModal";
import Button from "../../buttons/Button";
import Papa from "papaparse";
import StaticActionBadge from "../../badges/StaticActionBadge";
import "src/components/fields/parseNumberInput.css";
import { faHashtag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useTheme from "src/hooks/useTheme";
import FuseCoin from "../../icons/FuseCoin";
import AnimatedNumber from "react-awesome-animated-number";
import { actionCosts } from "src/configs/configs";
import { motion, AnimatePresence } from "framer-motion";
import useViewport from "src/hooks/useViewport";
import { v4 as uuidv4 } from "uuid";
import NewBulkRow from "./NewBulkRow";
import TrashButton from "src/components/buttons/TrashButton";
import { importApi, propertyApi } from "src/api";
import { useNavigate } from "react-router-dom";
import useImportContext from "src/hooks/private/useImportContext";
import useTeamContext from "src/hooks/private/useTeamContext";
import useWatchlist from "src/hooks/private/useWatchlist";

export interface CsvAddress {
  id: string;
  address: string;
  isWatchlist: boolean;
  isResearch: boolean;
  isSkiptrace: boolean;
  isDebtstack: boolean;
  isSequence: boolean;
  numLetters: number;
  source: "file" | "research";
  placeID: string | null;
  error: boolean;
}

const BulkImportModal = () => {
  const { setShowModal } = useModal();

  const { tailwindTheme } = useTheme();

  const { fetchWatchlist } = useWatchlist();

  const navigate = useNavigate();

  const [csvFile, setCsvFile] = useState<File>();

  const [data, setData] = useState<CsvAddress[]>([]);

  const [error, setError] = useState<null | string>(null);

  const [hasUploaded, setHasUploaded] = useState<boolean>(false);

  const [isImporting, setIsImporting] = useState<boolean>(false);

  const [isReadingCSV, setIsReadingCSV] = useState<boolean>(false);

  const { teamSettings } = useTeamContext();

  const { fetchImportSessions } = useImportContext();

  const createNewID = uuidv4;

  const handleChangeFile = ({
    target: { files },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setError(null);

    if (!files) {
      return;
    }

    if (files[0].type !== "text/csv") {
      setError("File must be .csv");
      return;
    }

    setCsvFile(files[0]);
  };

  const isValidAddressFormat = (address: string): boolean => {
    return address.length > 5;
  };

  const readCSV = () => {
    setIsReadingCSV(true);
    if (!csvFile) return setError("Enter a valid file");

    const reader = new FileReader();

    reader.onload = async ({ target }: any) => {
      const csv = Papa.parse<string[]>(target.result, { header: false });
      const { data } = csv;

      // Trim empty cells
      let flattenedData: string[] = data
        .reduce((a, b) => {
          return a.concat(b);
        }, [])
        .filter((address) => address && address.trim() !== "");

      // Remove duplicates
      flattenedData = flattenedData.filter(
        (address, index, self) => self.indexOf(address) === index
      );

      // Check if address is less than 3 characters which means the CSV is not formatted properly
      for (const address of flattenedData) {
        if (address.length <= 3) {
          setError(
            "Addresses must be in a single column. Please format your CSV correctly."
          );
          return;
        }
      }

      // Limit total addresses to import to 300
      if (flattenedData.length > 300) {
        setError("You may only import up to 300 addresses at a time.");
        return;
      }

      const dataArr: CsvAddress[] = [];

      for (const address of flattenedData) {
        if (!address || !address.length) continue;

        const isValid = isValidAddressFormat(address);
        if (!isValid) continue; // Skip invalid addresses

        try {
          const placeID = await propertyApi.getGooglePlaceID(address);

          const csvAddress: CsvAddress = {
            id: createNewID(),
            address,
            isWatchlist: false,
            isResearch: false,
            isSkiptrace: false,
            isDebtstack: false,
            isSequence: false,
            numLetters: teamSettings?.postCardNumber || 4,
            source: "file",
            placeID,
            error: false,
          };

          dataArr.push(csvAddress);
        } catch (error) {
          console.error(`Failed to get place ID for address ${address}`, error);
        }
      }

      setData(dataArr);
      setHasUploaded(true);
    };
    setIsReadingCSV(false);
    reader.readAsText(csvFile);
  };

  const handleAddManually = () => {
    setData([
      {
        id: createNewID(),
        address: "",
        isWatchlist: false,
        isResearch: false,
        isSkiptrace: false,
        isDebtstack: false,
        isSequence: false,
        numLetters: teamSettings?.postCardNumber || 4,
        source: "research",
        placeID: null,
        error: false,
      },
    ]);
    setHasUploaded(true);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleUpload = () => {
    readCSV();
  };

  const validateInput = (): Promise<CsvAddress[]> => {
    return new Promise((resolve) => {
      const validatedData = data.map((row: CsvAddress) => {
        if (!row.address.length) {
          return { ...row, error: true };
        } else {
          return { ...row, error: false };
        }
      });
      setData(validatedData);
      resolve(validatedData);
    });
  };

  type ObjectType = {
    error: boolean;
    [key: string]: any;
  };

  function checkErrorStatus(objects: ObjectType[]): boolean {
    for (let obj of objects) {
      if (obj.error) {
        return false;
      }
    }
    return true;
  }

  const handleImport = async () => {
    const validatedData = await validateInput();
    if (checkErrorStatus(validatedData)) {
      setIsImporting(true);
      try {
        const session = await importApi.createImportSession(data.length);
        for (let i = 0; i < validatedData.length; i++) {
          await new Promise((resolve) => setTimeout(resolve, i * 1000));
          await importApi.importAddress({
            sessionID: session.id,
            ...validatedData[i],
          });
        }
        fetchImportSessions();
        navigate("/import-history");
      } catch (err) {
        console.log(err);
      } finally {
        setIsImporting(false);
        setShowModal(false);
        fetchWatchlist();
      }
    }
  };

  const calculateFuseCoinCost = () => {
    let totalCoins: number = 0;
    data.forEach((obj: CsvAddress) => {
      if (obj.isResearch) totalCoins = totalCoins + actionCosts["research"];
      if (obj.isSkiptrace) totalCoins = totalCoins + actionCosts["skipTrace"];
      if (obj.isDebtstack) totalCoins = totalCoins + actionCosts["title"];
      if (obj.isSequence)
        totalCoins = totalCoins + actionCosts["sequence"] * obj.numLetters;
    });
    return totalCoins;
  };

  const handleInsertNewRow = () => {
    const newArray: CsvAddress[] = [
      ...data,
      {
        id: createNewID(),
        address: "",
        isWatchlist: false,
        isResearch: false,
        isSkiptrace: false,
        isDebtstack: false,
        isSequence: false,
        numLetters: teamSettings?.postCardNumber || 4,
        source: "research",
        placeID: null,
        error: false,
      },
    ];
    setData(newArray);
  };

  const { height } = useViewport();

  const calculateMaxHeight = () => {
    return height - 350;
  };

  return (
    <AnimatePresence>
      <motion.div key={"BulkImportModal"} layout>
        {hasUploaded ? (
          <>
            <motion.div
              layout
              className="overflow-x-none flex  columns-1 flex-col items-center justify-center overflow-y-auto"
            >
              <div className="mb-2 flex items-center justify-center gap-2">
                <div className="btn-group">
                  <div className="hover-border-secondary btn-sm btn border-r-0 border-secondary bg-back-light hover:border-secondary hover:bg-back-light dark:bg-base-100 hover:dark:bg-base-100">
                    <FuseCoin />
                  </div>
                  <div className="btn-sm btn border-l-0 border-secondary bg-back-light hover:border-secondary hover:bg-back-light dark:bg-base-100 hover:dark:bg-base-100">
                    <AnimatedNumber
                      value={calculateFuseCoinCost()}
                      className="font-bold text-text-dark dark:text-text-light"
                    />
                  </div>
                </div>
                <div>
                  <button
                    className="btn-outline btn-secondary btn-sm btn hover:text-white"
                    onClick={handleInsertNewRow}
                  >
                    + New Row
                  </button>
                </div>
              </div>

              <motion.ul layout className="px-4">
                <motion.li
                  layout
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="flex flex-row"
                >
                  <div className="flex w-10 items-center justify-center">
                    <StaticActionBadge title={"Watchlist"} iconOnly={true} />
                  </div>
                  <div className="flex w-10 items-center justify-center">
                    <StaticActionBadge title={"Research"} iconOnly={true} />
                  </div>
                  <div className="flex w-10 items-center justify-center">
                    <StaticActionBadge title={"Skip Trace"} iconOnly={true} />
                  </div>
                  <div className="flex w-10 items-center justify-center">
                    <StaticActionBadge title={"Debt Stack"} iconOnly={true} />
                  </div>
                  {/* <div className="flex w-10 items-center justify-center">
                    <StaticActionBadge title={"Sequence"} iconOnly={true} />
                  </div>
                  <div
                    className="tooltip mr-1 flex w-10 items-center justify-center"
                    data-tip={`Number of Letters`}
                  >
                    <div
                      className="text-md flex h-7 w-7 items-center justify-center rounded-[5px] font-semibold text-text-light dark:text-text-dark"
                      style={{
                        backgroundColor: tailwindTheme?.theme3,
                      }}
                    >
                      <FontAwesomeIcon icon={faHashtag} />
                    </div>
                  </div> */}
                  <div className="flex items-center justify-center">
                    <p className="w-96 text-left font-bold text-secondary">
                      Address
                    </p>
                  </div>
                  <div className="invisible ml-2 flex items-center justify-center">
                    <TrashButton onClick={() => {}} isSmall={true} />
                  </div>
                </motion.li>
              </motion.ul>
              <ul
                style={{
                  maxHeight: `${calculateMaxHeight()}px`,
                }}
                className="overflow-scroll px-4"
              >
                {(data || []).map(
                  (
                    {
                      id,
                      address,
                      isWatchlist,
                      isResearch,
                      isSkiptrace,
                      isDebtstack,
                      isSequence,
                      numLetters,
                      source,
                      error,
                    },
                    index
                  ) => (
                    <NewBulkRow
                      id={id}
                      key={id}
                      address={address}
                      isWatchlist={isWatchlist}
                      isResearch={isResearch}
                      isSkiptrace={isSkiptrace}
                      isDebtstack={isDebtstack}
                      isSequence={isSequence}
                      numLetters={numLetters}
                      data={data}
                      setData={setData}
                      index={index}
                      source={source}
                      error={error}
                    />
                  )
                )}
              </ul>

              <div className="mt-2 flex items-center justify-center">
                <Button
                  className="btn-ghost btn mr-1 w-32 text-lg font-bold text-text-dark hover:bg-blue-200 dark:text-text-light hover:dark:bg-gray-900"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  className="btn ml-1 w-32 bg-secondary text-lg font-bold text-text-light hover:bg-accent hover:drop-shadow-lg dark:text-text-dark"
                  onClick={handleImport}
                  loading={isImporting}
                  disabled={isImporting}
                >
                  Import
                </Button>
              </div>
            </motion.div>
          </>
        ) : (
          <form>
            <div className="mb-2">
              <input
                type="file"
                className="file-input-bordered file-input-secondary file-input w-full max-w-xs bg-card-light dark:bg-card-dark dark:text-white sm:max-w-sm [&::file-selector-button]:text-text-light"
                onChange={handleChangeFile}
              />
              {error && <p className="text-error">{error}</p>}
            </div>
            <div>
              or
              <span
                className="cursor-pointer text-secondary hover:underline"
                onClick={handleAddManually}
              >
                {" "}
                Add Manually
              </span>
            </div>
            <div className="mt-4 flex items-center justify-center">
              <Button
                className="btn-ghost btn mr-1 w-32 text-lg font-bold text-text-dark hover:bg-blue-200 dark:text-text-light hover:dark:bg-gray-900"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                className="btn ml-1 w-32 bg-secondary text-lg font-bold text-text-light hover:bg-accent hover:drop-shadow-lg dark:text-text-dark"
                onClick={handleUpload}
                loading={isReadingCSV}
              >
                Upload
              </Button>
            </div>
          </form>
        )}
      </motion.div>
    </AnimatePresence>
  );
};

export default BulkImportModal;
