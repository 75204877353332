import React from "react";
import {
  faCarSide,
  faDollarSign,
  faEnvelope,
  faEnvelopesBulk,
  faEye,
  faFileInvoiceDollar,
  faLayerGroup,
  faMagnifyingGlass,
  faPhone,
  faPhoneVolume,
  faQuestion,
  faRoute,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useTheme from "src/hooks/useTheme";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";

interface StaticActionBadgeProps {
  title: string;
  onClick?: () => void;
  iconOnly?: boolean;
}

const StaticActionBadge = ({
  title,
  onClick,
  iconOnly = false,
}: StaticActionBadgeProps) => {
  const { tailwindTheme } = useTheme();

  const colors = [
    tailwindTheme?.theme1,
    tailwindTheme?.theme2,
    tailwindTheme?.theme3,
    tailwindTheme?.theme4,
    tailwindTheme?.theme5,
    tailwindTheme?.gradient1,
  ];

  const assignColor = () => {
    switch (title) {
      case "Research":
        return colors[0];
      case "Researches":
        return colors[0];
      case "Phone Call":
        return colors[1];
      case "Debt Stack":
        return colors[4];
      case "DebtStack":
        return colors[4];
      case "Sequence":
        return colors[1];
      case "Sequences":
        return colors[1];
      case "Mail":
        return colors[1];
      case "Postcards":
        return colors[1];
      case "Offer":
        return colors[2];
      case "Offers":
        return colors[2];
      case "Three Tiered Offer":
        return colors[2];
      case "Skip Trace":
        return colors[3];
      case "SkipTraces":
        return colors[3];
      case "Watchlist":
        return colors[0];
      case "Drives":
        return colors[0];
      case "Invites":
        return colors[4];
      case "Invitation":
        return colors[4];
      case "Purchases":
        return colors[1];
      case "Coins":
        return colors[1];
      case "Call/Email":
        return colors[3];
      case "Calls/Emails":
        return colors[3];
      case "Campaigns":
          return colors[2];
      case "Campaign":
          return colors[2];
      default:
        return colors[0];
    }
  };

  const assignIcon = () => {
    switch (title) {
      case "Research":
        return faMagnifyingGlass;
      case "Researches":
        return faMagnifyingGlass;
      case "Call/Email":
        return faPhoneVolume;
      case "Calls/Emails":
        return faPhoneVolume;
      case "Debt Stack":
        return faFileInvoiceDollar;
      case "DebtStack":
        return faFileInvoiceDollar;
      case "Sequence":
        return faEnvelope;
      case "Sequences":
        return faEnvelope;
      case "Offer":
        return faDollarSign;
      case "Offers":
        return faDollarSign;
      case "Three Tiered Offer":
        return faLayerGroup;
      case "Skip Trace":
        return faPhone;
      case "SkipTraces":
        return faPhone;
      case "Watchlist":
        return faEye;
      case "Drives":
        return faCarSide;
      case "Invites":
        return faUserGroup;
      case "Invitation":
        return faUserGroup;
      case "Purchases":
        return faCreditCard;
      case "Coins":
        return faCreditCard;
      case "Postcards":
        return faEnvelopesBulk;
      case "Mail":
        return faEnvelopesBulk;
      case "Campaigns":
          return faRoute;
      case "Campaign":
          return faRoute;
      default:
        return faQuestion;
    }
  };

  const sanitizeTitle = () => {
    switch (title) {
      case "DebtStack":
        return "Debt Stacks";
      case "SkipTraces":
        return "Skip Traces";
      case "Invitation":
        return "Invite";
      case "Coins":
        return "Purchase";
      case "Three Tiered Offer":
        return "Offer";
      case "Postcards":
        return "Mail"
    }
    return title;
  };

  return (
    <>
      {!iconOnly && (
        <div
          className={
            onClick
              ? "flex w-fit cursor-pointer select-none items-center justify-center gap-1 rounded-[3px] py-[1px] px-[5px] text-sm font-semibold text-text-light dark:text-text-dark"
              : "flex w-fit select-none items-center justify-center gap-1 rounded-[3px] py-[1px] px-[5px] text-sm font-semibold text-text-light dark:text-text-dark"
          }
          style={{
            backgroundColor: assignColor(),
          }}
          onClick={onClick}
        >
          <FontAwesomeIcon icon={assignIcon()} />
          <p className="">{sanitizeTitle()}</p>
        </div>
      )}
      {iconOnly && (
        <div
          className="text-md tooltip flex h-7 w-7 cursor-pointer items-center justify-center rounded-[5px] font-semibold text-text-light dark:text-text-dark"
          data-tip={title}
          style={{
            backgroundColor: assignColor(),
          }}
          onClick={onClick}
        >
          <FontAwesomeIcon icon={assignIcon()} />
        </div>
      )}
    </>
  );
};

export default StaticActionBadge;
