import React from "react";

interface DeleteButtonProps {
  onClick?: (id?: number | undefined) => void;
  isSmall?: boolean;
}

const DeleteButton = ({ onClick, isSmall }: DeleteButtonProps) => {
  return (
    <>
      <label
        className={
          isSmall
            ? "btn-xs btn-circle btn cursor-pointer bg-card-dark text-text-light hover:bg-black dark:bg-back-dark  dark:text-gray-500 hover:dark:bg-black"
            : "btn-sm btn-circle btn cursor-pointer bg-card-dark text-text-light hover:bg-black dark:bg-back-dark  dark:text-gray-500 hover:dark:bg-black"
        }
        onClick={() => onClick && onClick()}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </label>
    </>
  );
};

export default DeleteButton;
