import { motion } from "framer-motion";
import React, {
  Dispatch,
  SetStateAction,
  useState,
} from "react";
import TrashButton from "src/components/buttons/TrashButton";
import SearchBarModal from "src/components/search/SearchBarModal";
import { CsvAddress } from "./BulkImportModal";

interface CheckBoxProps {
  isChecked: boolean;
  onChange: () => void;
  disabled?: boolean;
}

const CheckBox = ({ isChecked, onChange, disabled }: CheckBoxProps) => {
  return (
    <label className="label w-10 cursor-pointer">
      <input
        type="checkbox"
        checked={isChecked}
        className="checkbox-secondary checkbox [--chkfg:100_100%_100%] lg:checkbox-lg"
        onChange={onChange}
        disabled={disabled}
      />
    </label>
  );
};

interface NewBulkRowProps {
  id: string;
  address: string;
  isWatchlist: boolean;
  isResearch: boolean;
  isSkiptrace: boolean;
  isDebtstack: boolean;
  isSequence: boolean;
  numLetters: number;
  data: CsvAddress[];
  setData: Dispatch<SetStateAction<CsvAddress[]>>;
  index: number;
  source: "file" | "research";
  error: boolean;
}

const NewBulkRow = ({
  id,
  address,
  isWatchlist,
  isResearch,
  isSkiptrace,
  isDebtstack,
  isSequence,
  numLetters,
  data,
  setData,
  index,
  source,
  error,
}: NewBulkRowProps) => {
  const [hasMadeSelection, setHasMadeSelection] = useState<boolean>(false);

  const handleChangeCheckbox = (
    id: string,
    key: string,
    value: boolean | number
  ) => {
    setData((prevData: CsvAddress[]) => {
      const updatedData = prevData.map((row) => {
        if (row.id === id) {
          return {
            ...row,
            [key]: value,
          };
        }
        return row;
      });
      return updatedData;
    });
  };

  const handleChangeLetters = (e: any) => {
    const { name, value } = e.target;
    const { data } = e.nativeEvent;

    //Only allow numeric inputs between 0 and 7
    if (value.length > 1) return;
    if (data === "8" || data == "9") return;

    setData((prevData: CsvAddress[]) => {
      const updatedData = prevData.map((row) => {
        if (row.id === name) {
          return {
            ...row,
            numLetters: value,
          };
        }
        return row;
      });
      return updatedData;
    });
  };

  const handleSuggestionsSelect = (address: string, placeID: string, id: string) => {
    setData((prevData: CsvAddress[]) => {
      const updatedData = prevData.map((row) => {
        if (row.id === id) {
          return {
            ...row,
            address: address,
            placeID: placeID,
          };
        }
        return row;
      });
      return updatedData;
    });
    setHasMadeSelection(true);
  };

  const handleDeleteRow = (_index: number) => {
    const newArray = data.filter(
      (obj: CsvAddress, index: number) => index !== _index
    );
    setData(newArray);
  };

  return (
    <motion.li
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      key={id}
      className="flex flex-row"
    >
      <CheckBox
        isChecked={isWatchlist}
        onChange={() => handleChangeCheckbox(id, "isWatchlist", !isWatchlist)}
      />
      <CheckBox
        isChecked={isResearch}
        onChange={() => handleChangeCheckbox(id, "isResearch", !isResearch)}
      />
      <CheckBox
        isChecked={isSkiptrace}
        onChange={() => handleChangeCheckbox(id, "isSkiptrace", !isSkiptrace)}
        disabled={!isResearch}
      />
      <CheckBox
        isChecked={isDebtstack}
        onChange={() => handleChangeCheckbox(id, "isDebtstack", !isDebtstack)}
        disabled={!isResearch}
      />
      {/* <CheckBox
        isChecked={isSequence}
        onChange={() => handleChangeCheckbox(id, "isSequence", !isSequence)}
        disabled={!isResearch}
      />
      {isSequence ? (
        <div className="mr-1 flex w-10 items-center justify-center">
          <input
            type="number"
            name={id}
            placeholder="#"
            value={numLetters}
            disabled={!isSequence}
            onChange={handleChangeLetters}
            className="input input-sm w-8 bg-back-light py-1 pl-2 hover:bg-card-light focus:bg-card-light disabled:bg-back-light dark:bg-back-dark hover:dark:bg-back-dark focus:dark:bg-back-dark"
          />
        </div>
      ) : (
        <div className="mr-1">
          <CheckBox
            isChecked={isSequence}
            onChange={() => handleChangeCheckbox(id, "isSequence", !isSequence)}
            disabled={true}
          />
        </div>
      )} */}

      <div className="flex items-center justify-center">
        <SearchBarModal
          id={id}
          selectedValue={address}
          onSelect={handleSuggestionsSelect}
          hasMadeSelection={hasMadeSelection}
          setHasMadeSelection={setHasMadeSelection}
          source={source}
          error={error}
        />
      </div>
      <div className="ml-2 flex items-center justify-center">
        <TrashButton onClick={() => handleDeleteRow(index)} isSmall={true} />
      </div>
    </motion.li>
  );
};

export default NewBulkRow;
