import React from "react";

interface CampaignTargetProps {
  target: "absentee" | "occupied";
  onChange: any;
}

const CampaignTarget = ({ target, onChange }: CampaignTargetProps) => {
  return (
    <>
      <div className="relative h-full flex-grow rounded-md bg-card-light py-2 px-4 shadow-xl dark:bg-card-dark lg:w-1/2">
        <div className="mb-2">
          <span className="text-sm font-light  text-text-dark dark:text-text-light xs:text-base sm:text-xl">
            Select a target audience.
          </span>
        </div>
        <div className="mb-3 flex w-full items-center justify-center gap-4 md:justify-around lg:justify-center lg:gap-12">
          <div
            onClick={() => onChange("absentee", "target")}
            className={
              target === "absentee"
                ? "badge-secondary badge cursor-pointer text-white"
                : "badge-outline badge cursor-pointer text-title-text-light dark:text-title-text-dark"
            }
          >
            Absentee
          </div>
          <div
            onClick={() => onChange("occupied", "target")}
            className={
              target === "occupied"
                ? "badge-secondary  badge cursor-pointer text-white"
                : "badge-outline badge cursor-pointer text-title-text-light dark:text-title-text-dark"
            }
          >
            Occupied
          </div>
        </div>
      </div>
    </>
  );
};

export default CampaignTarget;
