/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  faCircleExclamation,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Property from "src/interfaces/property";
import Button from "../buttons/Button";
import useModal from "src/hooks/useModal";
import { offerApi } from "src/api";
import useAlert from "src/hooks/useAlert";
import UpgradeModal from "./UpgradeModal";
import SubscriptionsHelp from "../help/SubscriptionsHelp";
import LabeledNumberDisplay from "../display/LabeledNumberDisplay";
import { offerDefaults } from "src/configs/configs";
import FloatingInput from "../fields/FloatingInputNumber";
import useTeamContext from "src/hooks/private/useTeamContext";
import useOfferContext from "src/hooks/private/useOfferContext";
import useDrawer from "src/hooks/private/useDrawer";
import SelectOwner from "../fields/SelectOwner";
import useViewport from "src/hooks/useViewport";
import OfferSummary from "../offer/OfferSummary";
import usePropertyUpdateContext from "src/hooks/private/usePropertyUpdateContext";
import OffersHelp from "../help/OffersHelp";
import OfferResultsModal from "./OfferResultsModal";
import useSubscriptionContext from "src/hooks/private/useSubscription";

const WarningAlert = () => {
  return (
    <div className="rounded-md border border-warning bg-[#fbbe2382]">
      <div
        className="flex h-[48px] items-center rounded-md p-2 lg:h-[32px]"
        role="alert"
      >
        <FontAwesomeIcon
          icon={faCircleExclamation}
          className={"mr-2 text-lg text-text-dark"}
        />
        <span className="sr-only">Warning</span>
        <div className="text-left text-sm font-medium text-text-dark">
          <div>
            Loan amount required to fund this offer exceeds the FHA loan limit
          </div>
        </div>
      </div>
    </div>
  );
};

interface OfferAmountProps {
  property: Property;
}

interface formDataTypes {
  allCashOffer: number;
  sellerCarryOffer: number;
  sellerFinancingOffer: number;
  isThreeTiered: boolean;
  addressTo?: string;
}

const OfferModal = ({ property }: OfferAmountProps) => {
  const { teamSettings, fetchUserTeamData } = useTeamContext();

  const { fetchPropertyUpdates } = usePropertyUpdateContext();

  const { setShowModal } = useModal();

  const { fetchOffers } = useOfferContext();

  const { openModalWith } = useModal();

  const { refreshProperty } = useDrawer();

  const { subscriptionFeatures } = useSubscriptionContext();

  const { id, appraisedValue, sqft } = property;

  const { setAlert } = useAlert();

  const { isMobile } = useViewport();

  const defaultARV = Math.round(appraisedValue);

  const [ARV, setARV] = useState<number>(defaultARV);

  const defaultValues: formDataTypes = {
    allCashOffer: Math.round(
      (ARV / 100) * (teamSettings?.allCashARV || offerDefaults.allCashARV)
    ),
    sellerCarryOffer: Math.round(
      (ARV / 100) *
        (teamSettings?.sellerCarryARV || offerDefaults.sellerCarryARV)
    ),
    sellerFinancingOffer: Math.round(
      (ARV / 100) *
        (teamSettings?.sellerFinancingARV || offerDefaults.sellerFinancingARV)
    ),
    isThreeTiered: false,
    addressTo: property.owner1,
  };

  const [formData, setFormData] = useState<formDataTypes>(defaultValues);

  const [offerType, setOfferType] = useState("single");

  const [extra, setExtra] = useState<number>(
    subscriptionFeatures?.["Offer"].accessType === "fiat"
      ? subscriptionFeatures?.["Offer"].fiatCost
      : 0
  );

  const handleOfferType = (e: any) => {
    e.preventDefault();
    setOfferType(e.target.value);

    if (e.target.value === "single") {
      setFormData({
        ...formData,
        isThreeTiered: false,
      });
      if (subscriptionFeatures?.["Offer"]?.accessType === "fiat") {
        setExtra(subscriptionFeatures["Offer"]?.fiatCost ?? 0);
      } else {
        setExtra(0);
      }
    } else {
      setFormData({
        ...formData,
        isThreeTiered: true,
      });
      if (subscriptionFeatures?.["ThreeTieredOffer"]?.accessType === "fiat") {
        setExtra(subscriptionFeatures["ThreeTieredOffer"]?.fiatCost ?? 0);
      } else {
        setExtra(0);
      }
    }
  };

  const [hasOfferIncreased, setHasOfferIncreased] = useState(false);

  const [FHAcaps, setFHAcaps] = useState<{
    oneUnit: number;
    twoUnit: number;
    threeUnit: number;
    fourUnit: number;
  } | null>(null);

  useEffect(() => {
    const fetchFhaLoanLimits = async () => {
      try {
        const response = await offerApi.getFHALoansByStateCounty(
          property.state,
          property.fipsCounty
        );
        const { oneUnit, twoUnit, threeUnit, fourUnit } = response[0];
        setFHAcaps({
          oneUnit: oneUnit,
          twoUnit: twoUnit,
          threeUnit: threeUnit,
          fourUnit: fourUnit,
        });
      } catch (error) {
        console.error("Error fetching FHA loan limits:", error);
      }
    };
    fetchFhaLoanLimits();
  }, []);

  const [sellerCarryAboveFHACap, setSellerCarryAboveFHACap] =
    useState<boolean>(false);
  const [sellerFinancingAboveFHACap, setSellerFinancingAboveFHACap] =
    useState<boolean>(false);

  const handleARVChange = async (e: any) => {
    const { value } = e.target;
    let numValue = value.replace(/,/g, "");

    if (isNaN(numValue)) {
      return;
    }
    if (!hasOfferIncreased && numValue > defaultARV) {
      setHasOfferIncreased(true);
      if (sqft < 3000) {
        // await getFHACapAmount();
      }
    }
    if (numValue.toString().length > 9) {
      return;
    }
    numValue = parseInt(numValue);
    setARV(numValue || 0);

    setFormData({
      ...formData,
      allCashOffer:
        Math.round(
          (numValue / 100) *
            (teamSettings?.allCashARV || offerDefaults.allCashARV)
        ) || 1,
      sellerCarryOffer:
        Math.round(
          (numValue / 100) *
            (teamSettings?.sellerCarryARV || offerDefaults.sellerCarryARV)
        ) || 1,
      sellerFinancingOffer:
        Math.round(
          (numValue / 100) *
            (teamSettings?.sellerFinancingARV ||
              offerDefaults.sellerFinancingARV)
        ) || 1,
    });

    if (FHAcaps) {
      setSellerCarryAboveFHACap(
        Math.round(
          (numValue / 100) *
            (teamSettings?.sellerCarryARV || offerDefaults.sellerCarryARV)
        ) -
          Math.round(
            (numValue / 100) *
              (teamSettings?.sellerCarryDownPayment ||
                offerDefaults.sellerCarryDownPayment)
          ) >
          FHAcaps?.oneUnit
      );

      setSellerFinancingAboveFHACap(
        Math.round(
          (numValue / 100) *
            (teamSettings?.sellerFinancingARV ||
              offerDefaults.sellerFinancingARV)
        ) > FHAcaps?.oneUnit
      );
    }
  };

  const handleOwnerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFormData((formData) => ({ ...formData, addressTo: e.target.value }));
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleSubmit = async () => {
    setShowModal(false);
    try {
      const newOffer = await offerApi.createOffer(
        id,
        formData.allCashOffer,
        formData.sellerCarryOffer,
        formData.sellerFinancingOffer,
        formData.isThreeTiered,
        formData.addressTo
      );
      setAlert({
        type: "success",
        message: "Offer successfully created",
        display: true,
      });
      fetchUserTeamData();
      fetchOffers();
      refreshProperty();
      openModalWith({
        title: "Offer",
        property,
        body: <OfferResultsModal offerID={newOffer.id} property={property} />,
        hideButtons: true,
        helpTitle: "Offers",
        helpBody: <OffersHelp />,
      });
    } catch (e: any) {
      setAlert({
        type: "error",
        message: e?.message ?? "Offer creation failed",
        display: true,
      });
    } finally {
      fetchUserTeamData();
      fetchOffers();
      fetchPropertyUpdates();
      refreshProperty();
    }
  };

  const handleSubscriptionUpgrade = () => {
    openModalWith({
      title: "Upgrade Subscription",
      body: (
        <UpgradeModal
          mustFullyUpgrade={true}
          featureClicked={offerType === "single" ? "Offer" : "ThreeTieredOffer"}
        />
      ),
      hideButtons: true,
      helpTitle: "Subscriptions",
      helpBody: <SubscriptionsHelp />,
    });
  };

  const [summaryCardDisplayed, setSummaryCardDisplayed] = useState<0 | 1 | 2>(
    1
  );

  const [displaySummaryCard, setDisplaySummaryCard] = useState<boolean>(true);

  const handleOfferClick = (index: 0 | 1 | 2) => {
    setDisplaySummaryCard(true);
    setSummaryCardDisplayed(index);
  };

  return (
    <div className="">
      <form>
        <input
          type="hidden"
          name="isThreeTiered"
          value={`${formData.isThreeTiered}`}
        />
        <div className="mb-8 flex w-full columns-1 flex-col items-center justify-center">
          <div className="z-50 flex h-[50px] w-full items-center justify-center gap-3 bg-back-light dark:bg-base-100">
            <button
              value="single"
              onClick={handleOfferType}
              className={
                offerType === "single"
                  ? "btn-secondary btn-sm btn text-text-light hover:bg-secondary"
                  : "btn-ghost btn-sm btn text-base-100 dark:text-text-light"
              }
            >
              All Cash
            </button>
            <button
              value="three"
              onClick={handleOfferType}
              className={
                offerType === "three"
                  ? "btn-secondary btn-sm btn text-text-light hover:bg-secondary"
                  : "btn-ghost btn-sm btn text-base-100 dark:text-text-light"
              }
            >
              Three Tiered
            </button>
          </div>

          {formData.isThreeTiered ? (
            <>
              <div
                className={
                  sellerCarryAboveFHACap || sellerFinancingAboveFHACap
                    ? "duration-400 relative right-0 left-0 h-[43px] w-full bg-back-light transition-all dark:bg-base-100 md:h-[30px]"
                    : "duration-400 relative right-0 left-0 h-0 w-full bg-back-light transition-all dark:bg-base-100"
                }
              >
                <div
                  className={
                    sellerCarryAboveFHACap || sellerFinancingAboveFHACap
                      ? "duration-400 absolute right-0 left-0 top-0 w-full transition-all"
                      : "duration-400 absolute right-0 left-0 -top-[50px] w-full transition-all"
                  }
                >
                  <WarningAlert />
                </div>
              </div>
              <div className="w-36 md:w-full">
                <div className="my-4 w-full gap-2 md:flex md:items-center md:justify-center">
                  <div className="w-full md:w-36">
                    <FloatingInput
                      label={"ARV"}
                      name={"ARV"}
                      type={"text"}
                      onChange={handleARVChange}
                      value={ARV.toString()}
                      hasComma={true}
                      prefix={
                        <FontAwesomeIcon
                          icon={faDollarSign}
                          className="font-light text-text-dark dark:text-text-light"
                        />
                      }
                    />
                  </div>
                  {!isMobile && (
                    <div className="">
                      <SelectOwner
                        property={property}
                        onChange={handleOwnerChange}
                      />
                    </div>
                  )}
                </div>
                <div className="mb-3 flex w-full cursor-pointer flex-col gap-3 md:flex-row md:justify-center">
                  <div
                    className="flex w-full columns-1 flex-col md:w-36"
                    onClick={() => handleOfferClick(0)}
                  >
                    <LabeledNumberDisplay
                      label={"All Cash"}
                      value={formData.allCashOffer}
                      bgLight={"bg-back-light"}
                      bgDark={"dark:bg-base-100"}
                      className={"h-12 border-secondary "}
                      hasComma={true}
                      prefix={
                        <FontAwesomeIcon
                          icon={faDollarSign}
                          className="font-light text-text-dark dark:text-text-light"
                        />
                      }
                    />
                  </div>
                  <div
                    className="flex w-full cursor-pointer columns-1 flex-col md:w-36"
                    onClick={() => handleOfferClick(1)}
                  >
                    <LabeledNumberDisplay
                      label={"Seller Carry"}
                      value={formData.sellerCarryOffer}
                      bgLight={"bg-back-light"}
                      bgDark={"dark:bg-base-100"}
                      className={
                        sellerCarryAboveFHACap
                          ? "h-12 border-warning transition-all duration-500"
                          : "h-12 border-secondary transition-all duration-500"
                      }
                      hasComma={true}
                      prefix={
                        <FontAwesomeIcon
                          icon={faDollarSign}
                          className="font-light text-text-dark dark:text-text-light"
                        />
                      }
                    />
                  </div>
                  <div
                    className="flex w-full cursor-pointer columns-1 flex-col md:w-36"
                    onClick={() => handleOfferClick(2)}
                  >
                    <LabeledNumberDisplay
                      label={"Seller Financing"}
                      value={formData.sellerFinancingOffer}
                      bgLight={"bg-back-light"}
                      bgDark={"dark:bg-base-100"}
                      className={
                        sellerFinancingAboveFHACap
                          ? "h-12 border-warning transition-all duration-500"
                          : "h-12 border-secondary transition-all duration-500"
                      }
                      hasComma={true}
                      prefix={
                        <FontAwesomeIcon
                          icon={faDollarSign}
                          className="font-light text-text-dark dark:text-text-light"
                        />
                      }
                    />
                  </div>
                  {isMobile && (
                    <div className="">
                      <SelectOwner
                        property={property}
                        onChange={handleOwnerChange}
                      />
                    </div>
                  )}
                </div>
              </div>
              <OfferSummary
                summaryCardDisplayed={summaryCardDisplayed}
                setSummaryCardDisplayed={setSummaryCardDisplayed}
                allCashARV={formData.allCashOffer}
                sellerCarryARV={formData.sellerCarryOffer}
                sellerFinancingARV={formData.sellerFinancingOffer}
                sellerCarryDownPayment={
                  teamSettings?.sellerCarryDownPayment || 0
                }
                sellerCarryRate={teamSettings?.sellerCarryRate || 0}
                sellerCarryTerm={teamSettings?.sellerCarryTerm || 0}
                sellerFinancingRate={teamSettings?.sellerFinancingRate || 0}
                sellerFinancingBalloon={
                  teamSettings?.sellerFinancingBalloon || 0
                }
                displaySummaryCard={displaySummaryCard}
                setDisplaySummaryCard={setDisplaySummaryCard}
              />
            </>
          ) : (
            <div className="flex w-full columns-1 flex-col items-center justify-center">
              <div className="mb-4 w-36">
                <div className="my-4">
                  <FloatingInput
                    label={"ARV"}
                    name={"ARV"}
                    type={"text"}
                    onChange={handleARVChange}
                    value={ARV.toString()}
                    prefix={
                      <FontAwesomeIcon
                        icon={faDollarSign}
                        className="font-light text-text-dark dark:text-text-light"
                      />
                    }
                  />
                </div>
                <LabeledNumberDisplay
                  label={"All Cash"}
                  value={formData.allCashOffer}
                  bgLight={"bg-back-light"}
                  bgDark={"dark:bg-base-100"}
                  className={"h-12 border-secondary "}
                  hasComma={true}
                  prefix={
                    <FontAwesomeIcon
                      icon={faDollarSign}
                      className="font-light text-text-dark dark:text-text-light"
                    />
                  }
                />
              </div>
              <div className="flex items-center justify-center text-center">
                <SelectOwner property={property} onChange={handleOwnerChange} />
              </div>
            </div>
          )}
        </div>
      </form>

      {extra !== 0 && (
        <div className="mb-4 flex items-center justify-center">
          <div>Submit the offer for ${extra.toFixed(2)} or &nbsp;</div>
          <p
            onClick={handleSubscriptionUpgrade}
            className="cursor-pointer underline hover:opacity-70"
          >
            upgrade your subscription
          </p>
        </div>
      )}

      <div className="flex items-center justify-center">
        <Button
          className="btn-ghost btn mr-1 w-32 text-lg font-bold text-text-dark hover:bg-blue-200 dark:text-text-light hover:dark:bg-gray-900"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          className="btn ml-1 w-32 bg-secondary text-lg font-bold text-text-light hover:bg-accent hover:drop-shadow-lg dark:text-text-dark"
          onClick={handleSubmit}
        >
          Submit {extra !== 0 ? `$${extra.toFixed(2)}` : ""}
        </Button>
      </div>
    </div>
  );
};

export default OfferModal;
