import { ComponentType, Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useRoutes } from "react-router-dom";

/***** Route Helpers *****/
import PublicLayout from "./layouts/PublicLayout";
import PrivateRoute from "./auth/PrivateRoute";

import PublicRoute from "./auth/PublicRoute";
import TokenGuard from "./contexts/TokenGuardContext";
import JoinInvitationGuard from "./guards/JoinInvitationGuard";
import DemoInvitationGuard from "./guards/DemoInvitationGuard";
import LogAsUserInvitationGuard from "./guards/LogAsUserInvitationGuard";
import UpdateCardGuard from "./guards/UpdateCardGuard";
import useApp from "./hooks/useAppContext";

import NewSequence from "./pages/private/NewSequence";
import EditCampaign from "./components/sequences/campaigns/EditCampaign";
import Sequences from "src/pages/private/Sequences";
import NewCampaign from "./components/sequences/campaigns/NewCampaign";
import getHostName from "./helpers/getAppFromHost";
export const Loader = (Component: ComponentType) => (props: any) =>
(
  <Suspense
    fallback={
      <div className="flex w-full justify-center">
        <span className="loading">...</span>
      </div>
    }
  >
    <Component {...props} />
  </Suspense>
);

// Use lazy loading to improve rendering performance and the bundle size by importing component dynamically

/***** Public Pages *****/
const Page404 = Loader(lazy(() => import("src/pages/public/NotFound")));
const License = Loader(lazy(() => import("src/pages/public/License")));
const Landing = Loader(lazy(() => import("src/pages/public/Landing")));
const Unauthorized = Loader(
  lazy(() => import("src/pages/public/Unauthorized"))
);
const ExpiredTokenPage = Loader(
  lazy(() => import("src/pages/public/ExpiredToken"))
);

const Login = Loader(lazy(() => import("src/pages/public/Login")));
const Register = Loader(
  lazy(() => import("src/pages/public/registration_team/Register"))
);
const RegisterUser = Loader(
  lazy(() => import("src/pages/public/registration_user/RegisterUser"))
);
const RegisterMigration = Loader(
  lazy(
    () => import("src/pages/public/registration_migration/RegisterMigration")
  )
);
const Subscription = Loader(
  lazy(() => import("src/pages/public/registration_team/Subscription"))
);
const ForgotPassword = Loader(
  lazy(() => import("src/pages/public/recover_password/ForgotPassword"))
);
const ResetPassword = Loader(
  lazy(() => import("src/pages/public/recover_password/ResetPassword"))
);

/***** Private Pages *****/
const Dashboard = Loader(lazy(() => import('src/pages/private/Dashboard')));
const Properties = Loader(lazy(() => import('src/pages/private/Properties')));
// const Sequences = Loader(lazy(() => import('src/pages/private/Sequences')));
const Team = Loader(lazy(() => import('src/pages/private/Team')));
const Settings = Loader(lazy(() => import('src/pages/private/Settings')));
const SendFuseDash = Loader(lazy(() => import('src/pages/private/SendFuseDash')));
const Features = Loader(lazy(() => import('src/pages/private/Features')));
const Support = Loader(lazy(() => import('src/pages/private/Support')));
const Feedback = Loader(lazy(() => import('src/pages/private/Feedback')));
const Roadmap = Loader(lazy(() => import('src/pages/private/Roadmap')));
const DriveAI = Loader(lazy(() => import('src/pages/private/DriveAI')));
const CoinHistory = Loader(lazy(() => import('src/pages/private/Transactions')));
const Notifications = Loader(lazy(() => import('src/pages/private/Notifications')));
const EditTemplate = Loader(lazy(() => import('src/components/templates/EditTemplate')));
const Watchlist = Loader(lazy(() => import ('src/pages/private/Watchlist')));
const ImportHistory = Loader(lazy(() => import ('src/pages/private/ImportHistory')))

const Routes = () => {
  const { app } = useApp();
  const appName = app?.name || getHostName();

  const routing = useRoutes([
    {
      path: "",
      children: [
        {
          path: "*",
          element: <Navigate to="/404" replace />,
        },
        {
          path: "/",
          element: <Navigate to="/login" replace />,
        },
        {
          path: "/",
          element: <PublicLayout />,
          children: [
            {
              path: "home",
              element: <Landing />,
            },
            {
              path: "license",
              element: (
                <>
                  <Helmet>
                    <title>License |{" "}{appName}</title>
                  </Helmet>
                  <License />
                </>
              ),
            },
            {
              path: "404",
              element: (
                <>
                  <Helmet>
                    <title>404 Not Found |{" "}{appName}</title>
                  </Helmet>
                  <Page404 />
                </>
              ),
            },

            {
              path: "subscription",
              element: (
                <>
                  <Helmet>
                    <title>{appName || "Subscription"}</title>
                  </Helmet>
                  <Subscription />
                </>
              ),
            },
            {
              path: "401",
              element: (
                <>
                  <Helmet>
                    <title>401 Unauthorized |{" "}{appName}</title>
                  </Helmet>
                  <Unauthorized />
                </>
              ),
            },
            {
              path: "invalid-token",
              element: (
                <>
                  <Helmet>
                    <title>Invalid Token |{" "}{appName}</title>
                  </Helmet>
                  <ExpiredTokenPage />
                </>
              ),
            },
          ],
        },
        {
          path: "/",
          element: <PublicRoute />,
          children: [
            {
              path: "",
              element: <TokenGuard />,
              children: [
                {
                  path: "register",
                  element: (
                    <>
                      <Helmet>
                        <title>Register |{" "}{appName}</title>
                      </Helmet>
                      <Register />
                    </>
                  ),
                },
                {
                  path: "login",
                  element: (
                    <>
                      <Helmet>
                        <title>Login |{" "}{appName}</title>
                      </Helmet>
                      <Login />
                    </>
                  ),
                },
                {
                  path: "join/v2/:token",
                  element: (
                    <>
                      <Helmet>
                        <title>Join |{" "}{appName}</title>
                      </Helmet>
                      <JoinInvitationGuard />
                    </>
                  ),
                },
                {
                  path: "/demo/:token",
                  element: (
                    <>
                      <Helmet>
                        <title>Demo Mode |{" "}{appName}</title>
                      </Helmet>
                      <DemoInvitationGuard />
                    </>
                  ),
                },
                {
                  path: "/log-as-user/:token",
                  element: (
                    <>
                      <Helmet>
                        <title>Login as User |{" "}{appName}</title>
                      </Helmet>
                      <LogAsUserInvitationGuard />
                    </>
                  ),
                },
                {
                  path: "/update-card/:token",
                  element: (
                    <>
                      <Helmet>
                        <title>Update card |{" "}{appName}</title>
                      </Helmet>
                      <UpdateCardGuard />
                    </>
                  ),
                },
              ],
            },
            {
              path: "register/:token",
              element: (
                <>
                  <Helmet>
                    <title>Register |{" "}{appName}</title>
                  </Helmet>
                  <RegisterUser />
                </>
              ),
            },
            {
              path: "migrate/:token",
              element: (
                <>
                  <Helmet>
                    <title>Migrate to V2 |{" "}{appName}</title>
                  </Helmet>
                  <RegisterMigration />
                </>
              ),
            },
            {
              path: "forgot-password",
              element: (
                <>
                  <Helmet>
                    <title>Forgot Password |{" "}{appName}</title>
                  </Helmet>
                  <ForgotPassword />
                </>
              ),
            },
            {
              path: "reset-password/:token",
              element: (
                <>
                  <Helmet>
                    <title>Reset Password |{" "}{appName}</title>
                  </Helmet>
                  <ResetPassword />
                </>
              ),
            },
          ],
        },
        {
          path: "/",
          element: <PrivateRoute />,
          children: [
            {
              path: "dashboard",
              element: (
                <>
                  <Helmet>
                    <title>
                      Dashboard |{" "}{appName}
                    </title>
                  </Helmet>
                  <Dashboard />
                </>
              ),
            },
            {
              path: "properties",
              element: (
                <>
                  <Helmet>
                    <title>
                      Properties |{" "}{appName}
                    </title>
                  </Helmet>
                  <Properties />
                </>
              ),
            },
            {
              path: "watchlist",
              element: (
                <>
                  <Helmet>
                    <title>
                      Watchlist |{" "}{appName}
                    </title>
                  </Helmet>
                  <Watchlist />
                </>
              ),
            },
            {
              path: "import-history",
              element: (
                <>
                  <Helmet>
                    <title>
                      Import History |{" "}{appName}
                    </title>
                  </Helmet>
                  <ImportHistory />
                </>
              ),
            },
            {
              path: "sequences",
              element: (
                <>
                  <Helmet>
                    <title>
                      Sequences |{" "}{appName}
                    </title>
                  </Helmet>
                  <Sequences />
                </>
              ),
            },
            {
              path: 'sequences/postcards',
              element: (
                <>
                  <Helmet>
                  <title>
                      Sequences |{" "}{appName}
                    </title>
                  </Helmet>
                  <Sequences activeTab={"postcards"} />
                </>
              )
            },
            {
              path: 'sequences/letters',
              element: (
                <>
                  <Helmet>
                  <title>
                      Sequences |{" "}{appName}
                    </title>
                  </Helmet>
                  <Sequences activeTab={"letters"} />
                </>
              )
            },
            {
              path: 'sequences/campaigns',
              element: (
                <>
                  <Helmet>
                  <title>
                      Sequences |{" "}{appName}
                    </title>
                  </Helmet>
                  <Sequences activeTab={"campaigns"} />
                </>
              )
            },
            {
              path: 'sequences/campaigns/new',
              element: (
                <>
                  <Helmet>
                  <title>
                      New Campaign |{" "}{appName}
                    </title>
                  </Helmet>
                  <NewCampaign />
                </>
              )
            },
            {
              path: 'sequences/new/:propertyID',
              element: (
                <>
                  <Helmet>
                  <title>
                      New Sequences |{" "}{appName}
                    </title>
                  </Helmet>
                  <NewSequence />
                </>
              )
            },
            {
              path: 'sequences/campaigns/edit/:campaignID',
              element: (
                <>
                  <Helmet>
                  <title>
                      New Sequences |{" "}{appName}
                    </title>
                  </Helmet>
                  <EditCampaign />
                </>
              )
            },
            {
              path: "templates/:templateID/edit",
              element: (
                <>
                  <Helmet>
                    <title>
                      Templates |{" "}{appName}
                    </title>
                  </Helmet>
                  <EditTemplate />
                </>
              ),
            },
            {
              path: "team",
              element: (
                <>
                  <Helmet>
                    <title>
                      Team |{" "}{appName}
                    </title>
                  </Helmet>
                  <Team />
                </>
              ),
            },
            {
              path: "SendFuseDash",
              element: (
                <>
                  <Helmet>
                    <title>
                      {appName} Dash |{" "}{appName}
                    </title>
                  </Helmet>
                  <SendFuseDash />
                </>
              ),
            },
            {
              path: "DriveAI",
              element: (
                <>
                  <Helmet>
                    <title>
                      New Drive |{" "}{appName}
                    </title>
                  </Helmet>
                  <DriveAI />
                </>
              ),
            },
            {
              path: "coinhistory",
              element: (
                <>
                  <Helmet>
                    <title>
                      Coin History |{" "}{appName}
                    </title>
                  </Helmet>
                  <CoinHistory />
                </>
              ),
            },
            {
              path: "settings",
              element: (
                <>
                  <Helmet>
                    <title>
                      Settings |{" "}{appName}
                    </title>
                  </Helmet>
                  <Settings />
                </>
              ),
            },
            {
              path: "features",
              element: (
                <>
                  <Helmet>
                    <title>
                      Pro Features |{" "}{appName}
                    </title>
                  </Helmet>
                  <Features />
                </>
              ),
            },
            {
              path: "support",
              element: (
                <>
                  <Helmet>
                    <title>
                      Support |{" "}{appName}
                    </title>
                  </Helmet>
                  <Support />
                </>
              ),
            },
            {
              path: "feedback",
              element: (
                <>
                  <Helmet>
                    <title>
                      Feedback |{" "}{appName}
                    </title>
                  </Helmet>
                  <Feedback />
                </>
              ),
            },
            {
              path: "roadmap",
              element: (
                <>
                  <Helmet>
                    <title>
                      Roadmap |{" "}{appName}
                    </title>
                  </Helmet>
                  <Roadmap />
                </>
              ),
            },
            {
              path: "notifications",
              element: (
                <>
                  <Helmet>
                    <title>
                      Notifications |{" "}{appName}
                    </title>
                  </Helmet>
                  <Notifications />
                </>
              ),
            },
          ],
        },
      ],
    },
  ]);

  return (
    <>
      <Helmet>
        <title>{app?.name || window.location.host}</title>
      </Helmet>
      {routing}
    </>
  );
};

export default Routes;
