import useViewport from "../../hooks/useViewport";
import useSequenceLetterContext from "src/hooks/private/useSequenceLetterContext";
import {} from "swiper";
import SwiperTable from "src/components/swiper/SwiperTable";
import SwiperInvisibleBlock from "src/components/swiper/SwiperInvisibleBlock";
import { Sequence1 } from "src/interfaces/sequence";
import LoadMore from "src/components/LoadMore";
import {} from "swiper";
import SequencesRow from "./SequencesRow";
interface SequencesTabProps {
  props?: any;
}

const SequencesTab = ({ props }: SequencesTabProps) => {
  const {
    activeSequences,
    fetchActiveSequences,
    inactiveSequences,
    fetchInactiveSequences,
    activeCurrentPage,
    setActiveCurrentPage,
    activeTotalPage,
    inactiveCurrentPage,
    inactiveTotalPage,
    setInactiveCurrentPage,
  } = useSequenceLetterContext();

  const { isMobile } = useViewport();

  const reloadSequences = () => {
    fetchActiveSequences({
      page: activeCurrentPage,
      query: {
        isActive: true,
      },
    });

    fetchInactiveSequences({
      page: inactiveCurrentPage,
      query: {
        isActive: false,
      },
    });
  };

  return (
    <>
      <SwiperTable
        slides={[
          {
            id: "$.seq.active",
            title: "Active",
            content: (
              <>
                <table
                  className={
                    isMobile
                      ? "table-compact table w-full overflow-auto shadow-xl"
                      : "table w-full overflow-auto shadow-xl"
                  }
                >
                  <thead>
                    <tr className="border-b-2 border-secondary text-secondary">
                      <th
                        style={{
                          position: "static",
                        }}
                        className="select-none bg-card-light text-center text-xs dark:bg-card-dark md:text-base"
                      >
                        Property
                      </th>
                      <th className="select-none bg-card-light text-center text-xs dark:bg-card-dark md:text-base">
                        Status
                      </th>
                      {!isMobile && (
                        <>
                          <th className="select-none bg-card-light text-center text-xs dark:bg-card-dark md:text-base">
                            Start Date
                          </th>
                          <th className="select-none bg-card-light text-center text-xs dark:bg-card-dark md:text-base">
                            End Date
                          </th>
                          <th className="select-none bg-card-light text-center text-xs dark:bg-card-dark md:text-base">
                            Created By
                          </th>
                        </>
                      )}
                      <th className="select-none bg-card-light text-center text-xs dark:bg-card-dark md:text-base">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="overflow-auto bg-card-light">
                    {activeSequences.length ? (
                      activeSequences.map(
                        (sequence: Sequence1, index: number) => (
                          <SequencesRow
                            key={index}
                            sequenceRow={sequence}
                            reloadSequences={reloadSequences}
                          />
                        )
                      )
                    ) : (
                      <tr>
                        <td
                          className="bg-card-light text-center text-xs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light xs:text-sm sm:text-base"
                          colSpan={isMobile ? 3 : 6}
                        >
                          No Active Sequences yet
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="flex w-full flex-wrap">
                  <ul className="w-full">
                    {activeSequences.length > 0 && (
                      <>
                        {activeCurrentPage < activeTotalPage && (
                          <LoadMore
                            onClick={() =>
                              setActiveCurrentPage(activeCurrentPage + 1)
                            }
                          />
                        )}
                      </>
                    )}
                  </ul>
                </div>
                <SwiperInvisibleBlock num={activeSequences.length} />
              </>
            ),
          },
          {
            id: "$.seq.inactive",
            title: "Inactive",
            content: (
              <>
                <table
                  className={
                    isMobile
                      ? "table-compact table w-full overflow-auto shadow-xl"
                      : "table w-full overflow-auto shadow-xl"
                  }
                >
                  <thead>
                    <tr className="border-b-2 border-secondary text-secondary">
                      <th
                        style={{
                          position: "static",
                        }}
                        className="select-none bg-card-light text-center text-xs dark:bg-card-dark md:text-base"
                      >
                        Property
                      </th>
                      <th className="select-none bg-card-light text-center text-xs dark:bg-card-dark md:text-base">
                        {isMobile ? "Status" : "Letter Status"}
                      </th>
                      {!isMobile && (
                        <>
                          <th className="select-none bg-card-light text-center text-xs dark:bg-card-dark md:text-base">
                            Start Date
                          </th>
                          <th className="select-none bg-card-light text-center text-xs dark:bg-card-dark md:text-base">
                            End Date
                          </th>
                          <th className="select-none bg-card-light text-center text-xs dark:bg-card-dark md:text-base">
                            Created By
                          </th>
                          <th className="select-none bg-card-light text-center text-xs dark:bg-card-dark md:text-base"></th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody className="overflow-auto bg-card-light">
                    {inactiveSequences.length ? (
                      inactiveSequences.map((row: Sequence1, index: number) => (
                        <SequencesRow
                          key={index}
                          sequenceRow={row}
                          reloadSequences={reloadSequences}
                        />
                      ))
                    ) : (
                      <tr>
                        <td
                          className="bg-card-light text-center text-xs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light xs:text-sm sm:text-base"
                          colSpan={isMobile ? 3 : 5}
                        >
                          No Inactive Sequences yet
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="flex w-full flex-wrap">
                  <ul className="w-full">
                    {inactiveSequences.length > 0 && (
                      <>
                        {inactiveCurrentPage < inactiveTotalPage && (
                          <LoadMore
                            onClick={() =>
                              setInactiveCurrentPage(inactiveCurrentPage + 1)
                            }
                          />
                        )}
                      </>
                    )}
                  </ul>
                </div>
                <SwiperInvisibleBlock num={inactiveSequences.length} />
              </>
            ),
          },
        ]}
      />
    </>
  );
};

export default SequencesTab;
