import React, { useCallback } from "react";
import { subscriptionApi } from "src/api";
import { useState } from "react";
import useErrorHandler from "src/hooks/private/useErrorHandler";
import {
  Subscription,
  Feature,
  SubscriptionFeatures,
  AppSubscriptionsAndFeatures,
} from "src/interfaces/subscriptions";
import useAuth from "src/hooks/useAuth";

interface SubscriptionContextInterface {
  subscription: Subscription | null | undefined;
  appSubscriptions: Subscription[] | null | undefined;
  features: Feature[] | null | undefined;
  subscriptionFeatures: SubscriptionFeatures | null | undefined;
  appSubscriptionsAndFeatures: AppSubscriptionsAndFeatures[] | null | undefined;
  fetchSubscription: () => void;
  fetchAppSubscriptions: () => void;
  fetchFeatures: () => void;
  fetchSubscriptionFeatures: () => void;
  fetchAppSubscriptionsAndFeatures: () => void;
}

type CombinedSubscription = {
  subscription: Subscription;
  subscriptionFeatures: SubscriptionFeatures;
};

const subscriptionContextDefaults: SubscriptionContextInterface = {
  subscription: null,
  appSubscriptions: null,
  features: null,
  subscriptionFeatures: null,
  appSubscriptionsAndFeatures: null,
  fetchSubscription: () => {},
  fetchAppSubscriptions: () => {},
  fetchFeatures: () => {},
  fetchSubscriptionFeatures: () => {},
  fetchAppSubscriptionsAndFeatures: () => {},
};

const SubscriptionContext = React.createContext<SubscriptionContextInterface>(
  subscriptionContextDefaults
);

export const SubscriptionProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const { currentUser } = useAuth();
  const [appSubscriptions, setAppSubscriptions] = useState<Subscription[]>([]);
  const [subscription, setSubscription] = useState<Subscription>();
  const [features, setFeatures] = useState<Feature[]>([]);
  const [subscriptionFeatures, setSubscriptionFeatures] =
    useState<SubscriptionFeatures>();
  const [appSubscriptionsAndFeatures, setAppSubscriptionsAndFeatures] =
    useState<AppSubscriptionsAndFeatures[]>();

  const { handleError } = useErrorHandler(); // assuming you have a similar error handler as in your example

  const fetchSubscription = useCallback(async () => {
    try {
      if (currentUser?.teamID) {
        const teamSubscription = await subscriptionApi.getSubscription(
          currentUser?.teamID
        );
        setSubscription(teamSubscription);
      }
    } catch (err) {
      handleError(err);
    }
  }, [handleError, currentUser?.teamID]);

  const fetchAppSubscriptions = useCallback(async () => {
    try {
      if (currentUser?.appID) {
        const appSubscriptionsData = await subscriptionApi.getAllSubscriptions(
          currentUser?.appID
        );
        setAppSubscriptions(appSubscriptionsData);
      }
    } catch (err) {
      handleError(err);
    }
  }, [handleError, currentUser?.appID]);

  const fetchFeatures = useCallback(async () => {
    try {
      const featuresData = await subscriptionApi.getFeatures();
      setFeatures(featuresData);
    } catch (err) {
      handleError(err);
    }
  }, [handleError]);

  const fetchSubscriptionFeatures = useCallback(async () => {
    try {
      if (currentUser?.teamID) {
        const subscriptionFeaturesData =
          await subscriptionApi.getSubscriptionFeatures(currentUser?.teamID);

        // Converting this to an object for easier use;
        const featuresObject: SubscriptionFeatures =
          subscriptionFeaturesData.reduce((obj: any, feature) => {
            obj[feature.name] = feature;
            return obj;
          }, {});

        setSubscriptionFeatures(featuresObject);
      }
    } catch (err) {
      handleError(err);
    }
  }, [handleError, currentUser?.teamID]);

 
  const fetchAppSubscriptionsAndFeatures = useCallback(async () => {
    try {
      const { teamID, appID } = currentUser || {};
      if (teamID && appID) {
        const appSubscriptionsData = await subscriptionApi.getAllSubscriptions(appID);

        // Fetch all features at once
        const allFeaturesData = await subscriptionApi.getAppSubscriptionFeaturesByAppID(appID);

        const appSubscriptionsWithFeatures: CombinedSubscription[] = [];

        for (const subscription of appSubscriptionsData) {
          const appSubscriptionFeaturesData = allFeaturesData
            .filter((feature) => feature.subscriptionID === subscription.id)
            .sort((a, b) => a.featureID - b.featureID);
        
          const featuresObject: SubscriptionFeatures = appSubscriptionFeaturesData.reduce((obj: any, feature) => {
            obj[feature.id] = feature;
            return obj;
          }, {});
        
          appSubscriptionsWithFeatures.push({
            subscription,
            subscriptionFeatures: featuresObject,
          });
        }

        appSubscriptionsWithFeatures.sort(
          (a, b) => a.subscription.sortOrder - b.subscription.sortOrder
        );

        setAppSubscriptionsAndFeatures(appSubscriptionsWithFeatures);
      }
    } catch (err) {
      handleError(err);
    }
  }, [handleError, currentUser]);

  React.useEffect(() => {
    fetchSubscription();
    fetchAppSubscriptions();
    fetchFeatures();
    fetchSubscriptionFeatures();
    fetchAppSubscriptionsAndFeatures();
  }, [
    fetchSubscription,
    fetchFeatures,
    fetchSubscriptionFeatures,
    fetchAppSubscriptions,
    fetchAppSubscriptionsAndFeatures,
  ]);

  return (
    <SubscriptionContext.Provider
      value={{
        subscription,
        appSubscriptions,
        features,
        subscriptionFeatures,
        appSubscriptionsAndFeatures,
        fetchSubscription,
        fetchAppSubscriptions,
        fetchFeatures,
        fetchSubscriptionFeatures,
        fetchAppSubscriptionsAndFeatures,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionContext;
