import { useState, useEffect } from "react";
import AnimatedNumber from "react-awesome-animated-number";
import { subscriptionApi } from "src/api";
import { costToUpgrade } from "src/configs/configs";
import useTeamContext from "src/hooks/private/useTeamContext";
import useAlert from "src/hooks/useAlert";
import useModal from "src/hooks/useModal";
import Button from "../buttons/Button";
import SubscriptionChoice from "../stripe/SubscriptionChoice";
import useSubscriptionContext from "src/hooks/private/useSubscription";

interface UpgradeModalProps {
  mustFullyUpgrade?: boolean;
  hideExplanation?: boolean;
  featureClicked?: string;
  defaultSub?: number;
}

const UpgradeModal = ({
  mustFullyUpgrade = false,
  hideExplanation = false,
  featureClicked,
  defaultSub,
}: UpgradeModalProps) => {
  const { fetchUserTeamData } = useTeamContext();

  const { setAlert } = useAlert();

  const { setShowModal } = useModal();

  const { appSubscriptionsAndFeatures, subscription } =
    useSubscriptionContext();

  const [onSubmitting, setOnSubmitting] = useState<boolean>(false);

  const [selectedSubscription, setSelectedSubscription] = useState<number>(
    defaultSub || 2
  );

  const [costToUpgrade, setCostToUpgrade] = useState<number>(0);

  const [isAnnual, setIsAnnual] = useState<boolean>(true);

  const handleSelectSub = (subId: number) => {
    setSelectedSubscription(subId);
    // Calculate cost to upgrade
    let cost = 0;
    let startCounting = false;
    if (appSubscriptionsAndFeatures) {
      Object.values(appSubscriptionsAndFeatures).forEach((sub) => {
        if (sub.subscription.id === subscription?.id) {
          startCounting = true;
          return; // Skip the current subscription
        }

        if (startCounting) {
          cost += sub.subscription.upgradeCost;
        }

        if (sub.subscription.id === subId) {
          startCounting = false;
        }
      });
    }

    setCostToUpgrade(cost);
  };

  useEffect(() => {
    // Calculate initial cost to upgrade
    let cost = 0;
    let startCounting = false;
    if (appSubscriptionsAndFeatures) {
      Object.values(appSubscriptionsAndFeatures).forEach((sub) => {
        if (sub.subscription.id === subscription?.id) {
          startCounting = true;
          return; // Skip the current subscription
        }

        if (startCounting) {
          cost += sub.subscription.upgradeCost;
        }

        if (sub.subscription.id === selectedSubscription) {
          startCounting = false;
        }
      });
    }

    setCostToUpgrade(cost);
  }, [appSubscriptionsAndFeatures, subscription, selectedSubscription]);

  const handleSelectPeriod = (annual: boolean) => {
    setIsAnnual(annual);
  };

  const getSlugFromSub = (subID: number): string => {
    return "";
  }

  const handleSubmit = async () => {
    setOnSubmitting(true);
    try {
      let res = await subscriptionApi.changeSubscription({
        plan: getSlugFromSub(selectedSubscription),
        subscriptionID: selectedSubscription,
        isAnnual: isAnnual,
        costToChange: costToUpgrade,
      });
      console.log(res);
      setAlert({
        type: "success",
        message: "Subscription updated successfully!",
        display: true,
      });
      fetchUserTeamData();
    } catch (error: any) {
      setAlert({
        type: "error",
        message: error?.message || "Error upgrading team subscription",
        display: true,
      });
    } finally {
      setOnSubmitting(false);
      setShowModal(false);
      window.location.reload();
    }
  };

  const handleCancel = async () => {
    setShowModal(false);
  };

  return (
    <>
      <div>
        <div>
          {!hideExplanation && (
            <p className="mb-2 text-center text-text-dark dark:text-text-light">
              To use this feature please upgrade.
            </p>
          )}
          <div className="mb-4">
            <SubscriptionChoice
              isUpgrade={true}
              handleSelectSub={handleSelectSub}
              handleSelectPeriod={handleSelectPeriod}
              selectedSubscription={selectedSubscription}
              isAnnual={isAnnual}
            />
          </div>
        </div>
        <div className="flex items-center justify-center">
          <Button
            className="btn-ghost btn mr-1 w-32 text-lg font-bold text-text-dark hover:bg-blue-200 dark:text-text-light hover:dark:bg-gray-900"
            onClick={handleCancel}
            disabled={onSubmitting}
          >
            Cancel
          </Button>
          <Button
            className="btn ml-1 w-32 bg-secondary text-lg font-bold text-text-light hover:bg-accent hover:drop-shadow-lg dark:text-text-dark"
            onClick={handleSubmit}
            disabled={onSubmitting}
            loading={onSubmitting}
          >
            Upgrade $
            <AnimatedNumber
              className=""
              value={isAnnual ? 0 : costToUpgrade ? costToUpgrade : 0}
              size={17}
              duration={200}
            />
          </Button>
        </div>
      </div>
    </>
  );
};

export default UpgradeModal;
