import Property from "src/interfaces/property";
import { PropertyUpdate } from "src/interfaces/propertyUpdate";
import { DebtStack } from "src/interfaces/debtStack";
import { FilterParams } from "src/global/types";
import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";
import { Loan } from "src/interfaces/loan";
// import { getPDFfileName } from "src/helpers/pdf";
import { getDebtStackPDFfileName } from "src/helpers/pdf";
import { customHash } from "src/utils/hash";

class PropertyApi extends ApiTokenSupport {
  /**
   * Get property by ID
   * @param propertyID
   * @returns
   */
  async getProperty(propertyID: number): Promise<Property> {
    const resp = await axios.get<Property>(
      `/api/property/${propertyID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  /**
   * Delete property by ID
   * @param propertyID
   * @returns
   */
  async deleteProperty(propertyID: number): Promise<Property> {
    const resp = await axios.delete<Property>(
      `/api/property/${propertyID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  /**
   * Get properties
   * @returns
   */
  async getProperties(filter?: FilterParams): Promise<Property[]> {
    const resp = await axios.post<Property[]>(
      `/api/property/filter`,
      { ...filter },
      this.withAuthorization()
    );
    return resp.data;
  }

  async count(filter?: FilterParams): Promise<number> {
    const resp = await axios.post<number>(
      "api/property/count",
      { ...filter },
      this.withAuthorization()
    );
    return resp.data;
  }

  /**
   * Get loans
   * @returns
   */
  async getLoans(filter?: FilterParams): Promise<Loan[]> {
    const resp = await axios.post<Loan[]>(
      `/api/loan/filter`,
      { ...filter },
      this.withAuthorization()
    );
    return resp.data;
  }

  /**
   * Get loan by ID
   * @param propertyID
   * @returns
   */
  async getLoan(propertyID: number): Promise<Loan> {
    const resp = await axios.get<Loan>(
      `/api/loan/${propertyID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  /**
   * Get propertyUpdates
   * @returns
   */
  async getPropertyUpdates(filter?: FilterParams): Promise<PropertyUpdate[]> {
    const resp = await axios.post<PropertyUpdate[]>(
      `/api/property-update/filter`,
      { ...filter },
      this.withAuthorization()
    );
    return resp.data;
  }

  /**
   * Retrieve a property updates
   * @param propertyID
   * @returns
   */
  async getAPropertyUpdates(propertyID: number): Promise<PropertyUpdate[]> {
    const resp = await axios.get<PropertyUpdate[]>(
      `api/property-update/property/${propertyID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  /**
   * Get titles
   * @returns
   */
  async getDebtStacks(teamID: number): Promise<DebtStack[]> {
    const resp = await axios.get<DebtStack[]>(
      `/api/title/all/${teamID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  /**
   * Retrieve a debtstack by propertyID
   * @param propertyID
   * @returns
   */
  async getDebtStack(
    propertyID: number,
    debtStackID: number
  ): Promise<DebtStack> {
    const resp = await axios.get<DebtStack>(
      `/api/title/property/${propertyID}/${debtStackID}`,
      this.withAuthorization()
    );
    return resp.data;
  }
  /**
   * Retrieve an array of debtstacks by propertyID
   * @param propertyID
   * @returns
   */
  async getDebtStacksByProperty(propertyID: number): Promise<DebtStack[]> {
    const resp = await axios.get<DebtStack[]>(
      `/api/title/property/${propertyID}/all`,
      this.withAuthorization()
    );
    return resp.data;
  }

  previewDebtStackLink(
    debtStackID: number,
    title?: string,
    propertyFullAddress?: string
  ) {
    const hashed = customHash(debtStackID);
    return `${
      process.env.REACT_APP_BACKEND_URL
    }/api/title/report/${hashed}/${getDebtStackPDFfileName(
      title,
      propertyFullAddress
    )}_PropertyProfile`;
  }

  /**
   * Update property status
   * @param propertyID
   * @param status
   * @returns
   */
  async updatePropertyStatus(
    propertyID: number,
    status: string
  ): Promise<Property> {
    const resp = await axios.put<Property>(
      `/api/property/${propertyID}`,
      { status },
      this.withAuthorization()
    );
    return resp.data;
  }

  async updatePropertyIsActive(
    propertyID: number,
    isActive: boolean
  ): Promise<Property> {
    const resp = await axios.put<Property>(
      `/api/property/${propertyID}`,
      { isActive },
      this.withAuthorization()
    );
    return resp.data;
  }

  /**
   * Toggle favorite
   * @param propertyID
   * @param isWatchList
   * @returns
   */
  async toggleWatchList(
    propertyID: number,
    isWatchList: boolean
  ): Promise<Property> {
    const resp = await axios.put<Property>(
      `/api/property/${propertyID}`,
      { isWatchList },
      this.withAuthorization()
    );
    return resp.data;
  }

  /**
   * Research property
   *
   * @param data
   * @returns
   */
  async researchProperty(data: any): Promise<Property> {
    const resp = await axios.post<Property>(
      `/api/property/research-property`,
      { ...data },
      this.withAuthorization()
    );
    return resp.data;
  }

  /**
   * Order title
   * @param propertyID
   * @returns
   */
  async orderTitle(address: string, propertyID: number): Promise<boolean> {
    const resp = await axios.post<boolean>(
      `/api/title/title-report`,
      { address, propertyID },
      this.withAuthorization()
    );
    return resp.data;
  }

  async updateIsContact(propertyID: number, data: any): Promise<Property> {
    const resp = await axios.put<Property>(
      `/api/property/${propertyID}`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getOffers(teamID: number): Promise<any> {
    const resp = await axios.get<any>(
      `/api/offer/all/${teamID}`,
      this.withAuthorization()
    );
    return resp.data;
  }
  async propertyAlreadySearched(place_id: string): Promise<Property> {
    const resp = await axios.post<Property>(
      `/api/property/check-property`,
      { place_id },
      this.withAuthorization()
    );
    return resp.data;
  }

  async getStatusChart(teamID: number): Promise<any> {
    const resp = await axios.get(
      `api/property/statusChart/${teamID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  propertyImg(propertyID: number, view: string) {
    return `${process.env.REACT_APP_BACKEND_URL}/api/property-photos/view/${view}/${propertyID}`;
  }

  async getGooglePlaceID(address: string): Promise<string | null> {
    const resp = await axios.get(
      `/api/property/get-place-id/${address}`,
      this.withAuthorization()
    );
    return resp.data;
  }
}

export default new PropertyApi();
