import React from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import BulkImportHelp from "src/components/help/BulkImportHelp";
import BulkImportModal from "src/components/modals/BulkImportModal/BulkImportModal";
import useModal from "src/hooks/useModal";

const BulkImportButton = () => {
  const { openModalWith } = useModal();

  const handleBulkImportAddresses = () => {
    openModalWith({
      title: "Import Addresses",
      body: <BulkImportModal />,
      helpTitle: "Bulk Import",
      helpBody: <BulkImportHelp />,
      hideButtons: true,
    });
  };

  const navigate = useNavigate();

  return (
    <div className="dropdown dropdown-end dropdown-hover">
      <label
        className="btn-ghost btn flex select-none items-center justify-center text-xl font-bold text-primary hover:bg-hover-back-light dark:hover:bg-hover-back-dark "
        onClick={handleBulkImportAddresses}
      >
        <FontAwesomeIcon icon={faPlus} />
      </label>
      <ul
        tabIndex={0}
        className="dropdown-content menu rounded-box mt-0.5 w-52 border-2 border-primary bg-card-light p-2 text-primary drop-shadow-xl dark:bg-card-dark md:dark:bg-card-dark"
      >
        <li className="mb-1">
          <button
            onClick={handleBulkImportAddresses}
            className="btn-ghost btn font-bold hover:bg-hover-card-light dark:hover:bg-hover-card-dark"
          >
            Bulk Import
          </button>
        </li>
        <li className="mb-1">
          <button
            onClick={() => navigate("import-history")}
            className="btn-ghost btn font-bold hover:bg-hover-card-light dark:hover:bg-hover-card-dark"
          >
            Import History
          </button>
        </li>
      </ul>
    </div>
  );
};

export default BulkImportButton;
