export type Action = "research" | "title" | "skipTrace" | "sequence" | "offer" | "upgrade";

const actionCosts: { [key: Action | string ]: number } = {
  research: 1,
  title: 12,
  skipTrace: 8,
  sequence: 6,
  offer: 40,
  pricePerCoin: .50
}

const subscriptionNames = {
  tier1: "lite",
  tier2: "pro",
  tier3: "platinum",
}


const fuseCoinCosts = (fuseCoins: number) => {
  if(fuseCoins < 500) {
    return (fuseCoins / 4).toFixed(0);
  } else {
    return (fuseCoins / 4.16666666666666).toFixed(0);
  }
};

const subscriptions = [
  {
    tier: 1,
    name: "lite",
    initialCost: 297,
    monthlyCost: 0,
    coinsPromo: 200,
  },
  { 
    tier: 2,
    name: "pro",
    initialCost: 2497,
    monthlyCost: 50,
    coinsPromo: 100,
  },
  {
    tier: 3,
    name: "platinum",
    initialCost: 3000,
    monthlyCost: 75,
    coinsPromo: 1000,
  }
];

// Re-defined here to be more explicit
const costToUpgrade: any = {
  lite: {
    // lite to pro
    pro: 297,
    // lite to platinum
    platinum: 494
  },
  pro: {
    // pro to platinum
    platinum: 197
  }
}

const offerDefaults = {
  postCardInterrvals: 1,
  allCashARV: 55,
  sellerCarryARV: 75,
  sellerCarryDownPayment: 5,
  sellerCarryRate: 5,
  sellerCarryTerm: 4,
  sellerFinancingARV: 85,
  sellerFinancingRate: 6,
  sellerFinancingBalloon: 5,
}


export  { actionCosts, subscriptionNames, fuseCoinCosts, subscriptions, costToUpgrade, offerDefaults };
