import { actionCosts } from "src/configs/configs";
import useHelpDrawer from "src/hooks/private/useHelpDrawer";
import DebtStacksHelp from "./DebtStacksHelp";
import FuseCoinsHelp from "./FuseCoinsHelp";
import OffersHelp from "./OffersHelp";
import SequencesHelp from "./SequencesHelp";
import SkipTracesHelp from "./SkipTracesHelp";
import researchGIFDesktop from "src/assets/gifs/Desktop_Research.gif";
import researchGIFMobile from "src/assets/gifs/Mobile_Research.gif";
import useViewport from "src/hooks/useViewport";
import useApp from "src/hooks/useAppContext";
import getHostName from "src/helpers/getAppFromHost";

const ResearchingHelp = () => {
  const { helpLink } = useHelpDrawer();

  const { isMobile } = useViewport();

  const { app } = useApp();

  return (
    <>
      <div>
        <p>
          Researching can be done by entering an address in the search bar near
          the top of the page. As you type {app?.name} will give you suggestions of
          full addresses that are nearby that may be a possible match. The icon
          to the left of the address shows the state in which that address is
          located. When you see the address you are looking for click on it in
          the row of suggestions. You must choose from one of the suggestions to
          be able to research.
        </p>
        <br></br>
        <p>
          {app?.name || getHostName()} will work to research the property and show you the details.
          Sometimes our data provider doesn't have any information on a
          property. If this happens {app?.name || getHostName()} will display an alert letting you know and
          your account will not be charged. A successful research costs
          <span className="font-bold"> {actionCosts["research"]} </span>
          <span
            className="cursor-pointer font-bold text-primary hover:underline"
            onClick={() => helpLink(app?.coinName || "FuseCoins", <FuseCoinsHelp />)}
          >
            {app?.coinName || "FuseCoins™"}
          </span>{" "}
        </p>
        <br></br>
        <p>
          Once you've researched a property it will appear in your team's
          property table and you may perform other actions on it such as{" "}
          <span
            className="cursor-pointer font-bold text-primary hover:underline"
            onClick={() => helpLink("Debt Stacks", <DebtStacksHelp />)}
          >
            Debt Stacks
          </span>
          {", "}
          <span
            className="cursor-pointer font-bold text-primary hover:underline"
            onClick={() => helpLink("Skip Traces", <SkipTracesHelp />)}
          >
            Skip Traces
          </span>
          {", "}
          <span
            className="cursor-pointer font-bold text-primary hover:underline"
            onClick={() => helpLink("Sequences", <SequencesHelp />)}
          >
            Sequences
          </span>
          {", or "}
          <span
            className="cursor-pointer font-bold text-primary hover:underline"
            onClick={() => helpLink("Offers", <OffersHelp />)}
          >
            Offers
          </span>
          .
        </p>
        <br></br>
        <img
          src={isMobile ? researchGIFMobile : researchGIFDesktop}
          alt={"How To Research"}
        />
      </div>
    </>
  );
};

export default ResearchingHelp;
