import React from "react";

import ThemeSwitch from "../../buttons/ModeSwitch";
import CoinBalance from "../mobile/CoinBalance";
import SearchBar from "../../search/SearchBarDesktop";
import UserName from "../desktop/UserName";
import AlertButton from "./AlertButton";
import Upgrade from "./Upgrade";
import ThemeChooser from "src/components/buttons/ThemeChooser";
import useDrawer from "src/hooks/private/useDrawer";
import useHelpDrawer from "src/hooks/private/useHelpDrawer";
import useModal from "src/hooks/useModal";
import HelpButton from "./HelpButton";
import useAlert from "src/hooks/useAlert";
import Alert from "src/components/alert/Alert";
import { motion, AnimatePresence } from "framer-motion";
import BulkImportButton from "./BulkImportButton";
import BannerAlert from "src/components/banners/BannerAlert";

interface HeaderNavProps {
  showBanner: boolean;
}

const HeaderNav = ({ showBanner }: HeaderNavProps) => {
  const { showPropertyDrawer } = useDrawer();

  const { showHelpDrawer } = useHelpDrawer();

  const { showModal } = useModal();

  const { alert, setAlert } = useAlert();

  return (
    <header
      className={
        showHelpDrawer || showPropertyDrawer || showModal
          ? "fixed left-72 top-0 right-0 z-40 flex h-16 columns-1 flex-col bg-back-light dark:bg-back-dark"
          : "fixed left-72 top-0 right-0 z-50 flex h-16 columns-1 flex-col bg-back-light dark:bg-back-dark"
      }
    >
      <BannerAlert showBanner={showBanner} />
      <div className="flex w-full items-center justify-center bg-back-light px-6 pt-2 dark:bg-back-dark">
        <div className="flex flex-row justify-start gap-1">
          <div className="mt-[5px]">
            <SearchBar />
          </div>
          <BulkImportButton />
        </div>
        <div className="flex w-full items-center justify-end">
          <Upgrade />
          <HelpButton />
          <AlertButton />
          <ThemeSwitch />
          <ThemeChooser />
          <UserName />
          <CoinBalance isDisabled={false} />
        </div>
      </div>
      {!showPropertyDrawer && (
        <div className="px-[22px]">
          <AnimatePresence>
            {alert.display && (
              <motion.div
                initial={{ y: -20, opacity: 0.8 }}
                transition={{ duration: 0.15 }}
                animate={{ y: 2, opacity: 1 }}
                exit={{ y: -20, opacity: 0.5 }}
              >
                <Alert
                  alert={alert}
                  _className={"h-[30px] lg:h-[44px]"}
                  onClose={() =>
                    setAlert({
                      display: false,
                      type: alert.type,
                      message: alert.message,
                    })
                  }
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      )}
    </header>
  );
};

export default HeaderNav;
