import { useCallback, useEffect, useState } from "react";
import { propertyApi } from "src/api";
import useErrorHandler from "./useErrorHandler";

const useDebtStack = (propertyID: number, debtStackID: number, fetchOnMount?: boolean, propertyFullAdress?: string) => {
    const [debtStackLink, setFileLink] = useState("");
    const { handleError } = useErrorHandler();

    const getDebtStackLink = useCallback(async () => {
        if(!debtStackID) return null;
        try {
            const debtstack = await propertyApi.getDebtStack(propertyID, debtStackID);
            if (debtstack && debtstack?.id) {
                const link = propertyApi.previewDebtStackLink(debtstack.id, debtstack?.title, propertyFullAdress);
                setFileLink(link);
                return link;
            }

        } catch (err) {
            handleError(err);
        }
    }, [handleError, propertyID]);

    useEffect(() => {
        if (fetchOnMount) {
            getDebtStackLink();
        }
    }, [getDebtStackLink, fetchOnMount]);

    return { debtStackLink, getDebtStackLink }
}

export default useDebtStack;