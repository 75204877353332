import { icon } from "@fortawesome/fontawesome-svg-core";
import { faPlus, faRoute } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";

interface ViewItemProps {
  icon: any;
  label: string;
  onClick: () => void;
  isActive: boolean;
}

const ViewItem = ({ icon, label, onClick, isActive }: ViewItemProps) => {
  const handleClick = (event: any) => {
    event.stopPropagation();
    onClick();
  };

  return (
    <li>
      <div
        className={
          isActive
            ? "flex cursor-pointer items-center justify-start text-secondary hover:bg-hover-card-light dark:hover:bg-hover-card-dark"
            : "flex cursor-pointer items-center justify-start text-text-dark hover:bg-hover-card-light dark:text-text-light dark:hover:bg-hover-card-dark"
        }
        onClick={handleClick}
      >
        <FontAwesomeIcon icon={icon} className="w-4 text-base" />
        <p
          style={{
            textTransform: "none",
          }}
        >
          {label}
        </p>
      </div>
    </li>
  );
};

interface CampaignToolbarButtonProps {
  onClick: () => void;
  isActive: boolean;
}

const CampaignToolbarButton = ({
  onClick,
  isActive,
}: CampaignToolbarButtonProps) => {
  const navigate = useNavigate();

  return (
    <>
      <button
        className={
          isActive
            ? "dropdown-end dropdown-hover dropdown no-animation btn-sm btn w-1/4 bg-secondary px-1.5 text-text-light transition-none hover:bg-secondary hover:text-text-light xs:justify-center xs:gap-2"
            : "dropdown-end dropdown-hover dropdown  no-animation btn-sm btn w-1/4 bg-card-light px-1.5  text-text-dark transition-none hover:bg-secondary hover:text-text-light active:bg-primary dark:bg-card-dark dark:text-text-light xs:justify-center xs:gap-2"
        }
        onClick={onClick}
      >
        <label className="flex w-full cursor-pointer items-center justify-between text-xxs xs:justify-center xs:gap-2 sm:text-xs md:text-base">
          <FontAwesomeIcon icon={faRoute} className="" />
          <div className="">{"Campaigns"}</div>
        </label>
        <ul
          tabIndex={0}
          className="dropdown-content menu z-50 mt-2 w-48 rounded-lg border-[1px] border-icon-dark bg-card-light p-2 dark:border-icon-light dark:bg-card-dark"
        >
          <ViewItem
            icon={faPlus}
            label="New Campaign"
            onClick={() => navigate("/sequences/campaigns/new")}
            isActive={false}
          />
        </ul>
      </button>
    </>
  );
};

export default CampaignToolbarButton;
