import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { appApi } from "src/api";
import useErrorHandler from "src/hooks/private/useErrorHandler";
import App from "src/interfaces/app";

interface AppContextInterface {
  app: App | null | undefined;
  setApp: Dispatch<SetStateAction<App | undefined>>;
}

const appContextDefaults: AppContextInterface = {
  app: null,
  setApp: () => {},
};

const AppContext = React.createContext<AppContextInterface>(appContextDefaults);

interface AppProviderProps {}

export const AppProvider = ({
  children,
}: PropsWithChildren<AppProviderProps>) => {
  const [app, setApp] = useState<App>();

  const { handleError } = useErrorHandler();

  const fetchApp = useCallback(async () => {
       try {
         const app = await appApi.load();
         setApp(app);
       } catch (err: any) {
         handleError(err);
       }
  }, [handleError]);

  useEffect(() => {
     fetchApp();
  }, [fetchApp]);

  return (
    <AppContext.Provider
      value={{
        app,
        setApp,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;