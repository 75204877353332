import React from "react";
import WhatsNew1 from "./WhatsNew/WhatsNew1";

const WhatsNewModal = () => {

  return (
    <>
      <div>
          <WhatsNew1 />
      </div>
    </>
  );
};

export default WhatsNewModal;
