import { faStop } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "../tooltips/Tooltip";
import React, { useState } from "react";
import { parseMonthDay, parseMonthDayYear } from "src/helpers/parseEpochs";
import CircularActionButton from "../buttons/CircularActionButton";
import PropertyButton from "../buttons/PropertyButton";
import UserAvatar from "../buttons/UserAvatar";
import MailingStatus from "./MailingStatus";
import { Sequence1 } from "src/interfaces/sequence";
import useTheme from "src/hooks/useTheme";
import useViewport from "src/hooks/useViewport";
import { sequenceApi } from "src/api";
import useAlert from "src/hooks/useAlert";
import useModal from "src/hooks/useModal";
import CancelSequenceHelp from "../help/CancelSequenceHelp";
// import useSequenceMailingContext from "src/hooks/private/useSequenceLetterContext";
import ToggleDrawer from "../buttons/ToggleDrawer";
import TemplateSummary from "./TemplateSummary";
import { TemplateID } from "src/interfaces/campaign";
import { Mailing } from "src/interfaces/mailing";

interface SequencesRowProps {
  sequenceRow: Sequence1;
  reloadSequences: () => void;
}

const SequencesRow = ({ sequenceRow, reloadSequences }: SequencesRowProps) => {
  const { tailwindTheme } = useTheme();

  const { isLayoutChange, isMobile } = useViewport();

  const { setAlert } = useAlert();

  const { setShowModal, openModalWith } = useModal();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const buildProperty = (obj: any) => {
    return {
      id: obj.propertyID,
      fullAddress: obj.fullAddress,
      houseNumber: obj.houseNumber,
      fraction: obj.fraction,
      preDirectional: obj.preDirectional,
      street: obj.street,
      suffix: obj.suffix,
      postDirectional: obj.postDirectional,
      unit: obj.unit,
      city: obj.city,
      state: obj.state,
      zipcode: obj.zipcode,
    };
  };

  const onSequenceCancelSubmit = async (sequenceID: number) => {
    let sequence = await sequenceApi.updateSequence(sequenceID, {
      isActive: false,
    });
    if (sequence.isActive === false) {
      setShowModal(false);
      setAlert({
        type: "success",
        message: "Sequence cancelled successfully",
        display: true,
      });

      reloadSequences();
    } else {
      setShowModal(false);
      setAlert({
        display: true,
        message: "Unable to cancel sequence",
        type: "error",
      });
    }
  };

  const handleSequenceCancel = (sequence: any) => {
    openModalWith({
      title: "Cancel Sequence",
      property: buildProperty(sequence),
      body: (
        <p className="w-full text-center text-text-dark dark:text-text-light">
          Are you sure you want to stop this sequence?
        </p>
      ),
      onSubmit: () => onSequenceCancelSubmit(sequence.id),
      helpTitle: "Cancelling a Sequence",
      helpBody: <CancelSequenceHelp />,
      submitLabel: "Stop",
    });
  };

  const sendDays: string[] = Array.from(sequenceRow.mailings).map(
    (mailing: Mailing) => parseMonthDay(mailing.scheduledDate) || ""
  );

  const templateIDs: TemplateID[] = Array.from(sequenceRow.mailings).map(
    (mailing: Mailing) => {
      if (mailing.templateLettersID !== null) {
        return { id: mailing.templateLettersID, type: "letter" };
      } else if (mailing.templatePostcardsID !== null) {
        return { id: mailing.templatePostcardsID, type: "postcard" };
      } else {
        return { id: 0, type: "unknown" };
      }
    }
  );

  return (
    <>
      <tr className="relative bg-card-light text-xs text-text-dark dark:bg-card-dark dark:text-text-light sm:text-base">
        <td className="cursor-pointer bg-card-light text-center dark:bg-card-dark">
          <PropertyButton
            {...{
              ...sequenceRow.sequence,
              id: sequenceRow.sequence.propertyID,
            }}
          />
        </td>
        <td className="bg-card-light text-left dark:bg-card-dark">
          <MailingStatus mailings={sequenceRow.mailings} />
        </td>
        {!isLayoutChange && (
          <>
            <td className="bg-card-light text-center font-semibold dark:bg-card-dark">
              {parseMonthDayYear(sequenceRow.sequence.createdAt)}
            </td>
            <td className="bg-card-light text-center font-semibold dark:bg-card-dark">
              {parseMonthDayYear(
                sequenceRow.sequence.createdAt,
                sequenceRow.sequence.quantity *
                  sequenceRow.sequence.cadence *
                  86400000
              )}
            </td>
            <td className="bg-card-light text-center dark:bg-card-dark">
              <div className="flex w-full items-center justify-center">
                <UserAvatar userID={sequenceRow.sequence.userID} />
              </div>
            </td>
          </>
        )}
        <td className="bg-card-light text-center dark:bg-card-dark">
          <div className="flex items-center justify-end gap-1.5">
            {sequenceRow.sequence.isActive && (
              <div
                data-tip
                data-for={`tooltip-${sequenceRow.sequence.id}`}
                className="inline-block"
              >
                <CircularActionButton
                  icon={faStop}
                  onClick={() => handleSequenceCancel(sequenceRow.sequence)}
                  data-tip
                  data-for={`tooltip-${sequenceRow.sequence.id}`}
                />
                <Tooltip
                  id={`tooltip-${sequenceRow.sequence.id}`}
                  place="left"
                  backgroundColor={tailwindTheme.theme2}
                  content={"Stop Sequence"}
                />
              </div>
            )}
            <ToggleDrawer
              onClick={() => setIsDrawerOpen(!isDrawerOpen)}
              isToggled={isDrawerOpen}
            />
          </div>
        </td>
      </tr>
      <tr
        className={
          isDrawerOpen
            ? "relative bg-card-light transition-all duration-300 dark:bg-card-dark "
            : "absolute h-0 bg-card-light  duration-300 dark:bg-card-dark "
        }
      >
        {isDrawerOpen && (
          <td
            colSpan={isMobile ? 3 : 6}
            className=" overflow-auto border-x bg-back-light dark:border-x dark:border-card-dark dark:bg-back-dark"
            style={{
              maxHeight: "300px",
              overflow: "auto",
              maxWidth: "375px"
            }}
          >
            <TemplateSummary
              templateIDs={templateIDs}
              sendDays={sendDays}
              showSummaryHeader={false}
              propertyID={sequenceRow?.sequence?.propertyID}
            />
          </td>
        )}
      </tr>
    </>
  );
};

export default SequencesRow;
