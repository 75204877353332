import React from "react";
import getHostName from "src/helpers/getAppFromHost";
import useApp from "src/hooks/useAppContext";

const BulkImportHelp = () => {

  const { app } = useApp();
  return (
    <>
      <div>
        <p>To upload a CSV file with multiple properties or to rapidly Research more than one property at a time, click the + Icon to the right of the search bar on Desktop view.</p>
        <br></br>
        <p>Ensure your CSV file has the entire address inside a single column and has one of these formats:</p>
        <br></br>
        <p className="text-red-400">[Street Address] [City] [State code] [Zip]</p>
        <br></br>
        <p className="text-red-400">[Address] [City], [State code] [Zip]</p>
        <br></br>
        <p className="text-red-400">[Street Address], [City], [State code] [Zip], [Country]</p>
        <br></br>
        <p>The number of addresses you can import at one time is limited to a maximum of 300. If you have any duplicates {app?.name || getHostName()} will automatically remove them and they will not be counted towards this limit.</p>
      </div>
    </>
  );
};

export default BulkImportHelp;







