import { useState } from "react";
import { sequenceApi } from "src/api";
import Property from "src/interfaces/property";
import useDrawer from "src/hooks/private/useDrawer";
import useSequenceLetterContext from "src/hooks/private/useSequenceLetterContext";
import useTeamContext from "src/hooks/private/useTeamContext";
import useAlert from "src/hooks/useAlert";
import useModal from "src/hooks/useModal";
import Button from "../buttons/Button";
import InsufficientFundsModal from "./InsufficientFundsModal";
import PurchaseCoinsModal from "./PurchaseCoinsModal";
import { Option } from "../fields/SelectInput";
import useCampaignContext from "src/hooks/private/useCampaignContext";
import { useNavigate } from "react-router-dom";
import { Campaign } from "src/interfaces/campaign";
import useSubscriptionContext from "src/hooks/private/useSubscription";

interface SequenceLetterProps {
  property: Property;
}

const SequenceLetter = ({ property }: SequenceLetterProps) => {
  const { id, ownerOccupied } = property;

  const navigate = useNavigate();

  const { setAlert } = useAlert();

  const { setShowModal, openModalWith } = useModal();

  const { teamSettings, checkCoinBalance, fetchUserTeamData } =
    useTeamContext();

  const { refreshProperty } = useDrawer();

  const { fetchMailings, fetchSequences } = useSequenceLetterContext();

  const { campaigns } = useCampaignContext();

  const { subscriptionFeatures } = useSubscriptionContext();

  const filteredCampaigns = campaigns.filter(
    (campaign: Campaign) =>
      campaign.target === (ownerOccupied ? "occupied" : "absentee")
  );

  const createCampaignOptions = (): Option[] => {
    const options: Option[] = [];
    for (const campaign of filteredCampaigns) {
      options.push({
        label: campaign.name,
        value: campaign.id,
      });
    }
    return options;
  };

  const [selectedCampaign, setSelectedCampaign] = useState<Campaign>(
    filteredCampaigns[0]
  );

  const handleSelectCampaign = (e: any) => {
    const value = parseInt(e.target.value, 10);
    const selectedCampaign = campaigns.find(
      (campaign: Campaign) => campaign.id === value
    );
    if (selectedCampaign) {
      setSelectedCampaign(selectedCampaign);
    } else {
      // handle the case when selectedCampaign is not found
    }
  };

  const handleSubmit = async () => {
    if (
      selectedCampaign &&
      checkCoinBalance(
        (subscriptionFeatures?.["Sequence"].coinCost || 6) *
          selectedCampaign.quantity
      )
    ) {
      setShowModal(false);
      try {
        await sequenceApi.createSequence({
          propertyID: id,
          cadence: teamSettings?.postCardIntervals,
          numLetters: selectedCampaign.quantity,
          templateIDs: selectedCampaign.templateIDs,
          addressTo: property.owner1,
        });
        setAlert({
          type: "success",
          message: "Sequence created",
          display: true,
        });
      } catch (error: any) {
        setAlert({
          type: "error",
          message: error?.message ?? "Sequence creation failed",
          display: true,
        });
      } finally {
        fetchSequences();
        fetchMailings();
        fetchUserTeamData();
        refreshProperty();
      }
    } else {
      openModalWith({
        title: "Insufficient FuseCoins",
        body: <InsufficientFundsModal />,
        hideButtons: true,
      });
    }
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleAddCoins = () => {
    openModalWith({
      title: "Add FuseCoins",
      body: <PurchaseCoinsModal />,
      hideButtons: true,
    });
  };

  const handleCustomSequence = () => {
    setShowModal(false);
    return navigate(`/sequences/new/${property.id}`);
  };

  return (
    <div className="flex columns-1 flex-col items-center justify-center">
      <div className="my-2 flex items-center justify-between gap-4">
        <div className="font-semibold">Select a Campaign</div>
        <select
          name={"selectCampaign"}
          className="select h-12 w-48 bg-back-light text-text-dark dark:bg-back-dark dark:text-text-light"
          value={selectedCampaign.id.toString()}
          onChange={handleSelectCampaign}
        >
          {createCampaignOptions().map((opt: Option) => (
            <option key={`op-${opt.value}`} value={opt.value}>
              {opt.label}
            </option>
          ))}
          ;
        </select>
      </div>
      <div className="my-2">or</div>
      <button
        className="btn-ghost btn-sm btn mb-4 hover:bg-hover-back-light hover:dark:bg-hover-card-dark"
        onClick={handleCustomSequence}
      >
        Create a Custom Sequence
      </button>
      <div className="flex items-center justify-center">
        {selectedCampaign &&
        checkCoinBalance(
          (subscriptionFeatures?.["Sequence"].coinCost || 6) *
            selectedCampaign.quantity
        ) ? (
          <>
            <Button
              className="btn-ghost btn mr-1 w-32 text-lg font-bold text-text-dark hover:bg-blue-200 dark:text-text-light hover:dark:bg-gray-900"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className="btn ml-1 w-32 bg-secondary text-lg font-bold text-text-light hover:bg-accent hover:drop-shadow-lg dark:text-text-dark"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </>
        ) : (
          <>
            <Button
              className="btn-ghost btn mr-1 w-32 text-lg font-bold text-text-dark hover:bg-blue-200 dark:text-text-light hover:dark:bg-gray-900"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className="btn ml-1 w-32 bg-secondary text-lg font-bold text-text-light hover:bg-accent hover:drop-shadow-lg dark:text-text-dark"
              onClick={handleAddCoins}
            >
              Purchase FuseCoins
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default SequenceLetter;
