/* eslint-disable import/no-anonymous-default-export */
// @ts-nocheck
import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import FayeContext from './FayeContext';
import type { FayeContextValue, FayeListener, Cancelable, Subscription } from './FayeContext';

export default (args?: Record<string, FayeListener>, deps: any[] = []): FayeContextValue => {
  const navigate = useNavigate();
  const client: FayeContextValue = useContext<FayeContextValue>(FayeContext);
  const subscriptions: Subscription[] = [];

  useEffect(() => {
    Object.entries(args).forEach(([channel, listener]: [string, FayeListener<any>]) => {
      let subscription: Subscription = client.subscribe(channel);

      if (subscription === undefined) {
        return;
      }
      const subscriber = subscription.withChannel(listener);
      if (subscriber) {
        subscriber.then(() => {
          console.info(`[Faye] successfully subscribed to ${channel}`);
        }).catch((e: Error) => {
          console.error(`[Faye] failed to subscribe to ${channel}`, e);
          if (e.message.match(/jwt expired/i)) {
            navigate('/login');
          }
        });
      }

      subscriptions.push(subscription);
    });

    return () => {
      subscriptions.forEach((subscription: Cancelable) => {
        subscription.cancel();
      });
    };
  }, deps);

  return client;
};
