/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, useState, useEffect } from "react";
/***** Navigation ******/
import HeaderNav from "../components/navigation/desktop/HeaderNav";
import SideNav from "../components/navigation/desktop/SideNav";
import HeaderNavMobile from "../components/navigation/mobile/HeaderNavMobile";
import FooterNavMobile from "../components/navigation/mobile/FooterNavMobile";
import SearchBarMobile from "../components/search/SearchBarMobile";
import Alert from "src/components/alert/Alert";
import { Outlet } from "react-router-dom";

/***** Hooks *****/
import useViewport from "../hooks/useViewport";
import useAlert from "src/hooks/useAlert";
import useAuth from "src/hooks/useAuth";
import { listenPropertyChanges } from "src/faye/listener/properties.listener";
import useDrawer from "src/hooks/private/useDrawer";
import useConfigs from "src/hooks/useConfigs";
import { Configs } from "src/interfaces/configs";
import { listenUserPermissionsChanges } from "src/faye/listener/permissions.listener";
import useUserContext from "src/hooks/private/useUserContext";
import WhatsNewModal from "src/components/modals/WhatsNewModal";
import useModal from "src/hooks/useModal";
import { userApi } from "src/api";

interface PrivateLayoutProps {
  children: ReactNode;
}

const PrivateLayout = ({ children }: PrivateLayoutProps) => {
  let { isLayoutChange } = useViewport();

  const { alert, setAlert } = useAlert();

  const { showPropertyDrawer } = useDrawer();

  const { currentUser } = useAuth();

  const { user, fetchUser } = useUserContext();

  const { openModalWith } = useModal();

  const { setShowModal } = useModal();

  if (currentUser) {
    listenPropertyChanges(currentUser?.teamID);
    listenUserPermissionsChanges(currentUser, fetchUser);
  }

  const { configs } = useConfigs();

  const [showBanner, setShowBanner] = useState<boolean>(false);

  useEffect(() => {
    const bannerConfig = configs.find(
      (config: Configs) => config.name === "BannerAlert"
    );
    const booleanValue = bannerConfig?.value === "true" ? true : false;
    setShowBanner(booleanValue);
  }, [configs]);

  const handleModalConfirm = async () => {
    if (user) {
      try {
        await userApi.update({ ...user, hasSeenNewModal: true });
        setShowModal(false);
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (user?.hasSeenNewModal === false) {
      openModalWith({
        title: "What's New",
        body: <WhatsNewModal />,
        onSubmit: handleModalConfirm,
        submitLabel: "Lets Go",
        onCancel: handleModalConfirm,
        helpTitle: "SendFuse Updates",
        helpBody: <></>,
        hideCancelButton: true,
      });
    } else {
      return;
    }
  }, [user]);

  return (
    <>
      {isLayoutChange ? (
        <>
          <HeaderNavMobile />
          <div
            style={{
              zIndex: 0,
            }}
            className="fixed left-0 right-0 h-full overflow-auto overscroll-none bg-back-light dark:bg-back-dark"
          >
            <div className="relative top-4 z-30 mx-3">
              <SearchBarMobile />
            </div>
            <div
              className={
                alert.display && isLayoutChange && !showPropertyDrawer
                  ? "fixed top-[63px] left-0 right-0 z-50 mx-1.5  transition-all duration-700"
                  : "fixed top-0 left-0 right-0 z-50 mx-1.5 transition-all duration-700"
              }
            >
              <Alert
                alert={alert}
                onClose={() =>
                  setAlert({
                    display: false,
                    type: alert.type,
                    message: alert.message,
                  })
                }
              />
            </div>
            <div className={"relative z-20 mx-1.5 mt-[118px]"}>{children}</div>
            <FooterNavMobile />
          </div>
        </>
      ) : (
        <>
          <SideNav />
          <HeaderNav showBanner={showBanner} />
          <div
            className={
              showBanner
                ? "fixed left-72 right-0 top-[88px] bottom-0 overflow-auto overflow-x-hidden overscroll-none bg-back-light px-6 dark:bg-back-dark"
                : "fixed left-72 right-0 top-16 bottom-0 overflow-auto overflow-x-hidden overscroll-none bg-back-light px-6 dark:bg-back-dark"
            }
          >
            {children || <Outlet />}
          </div>
        </>
      )}
    </>
  );
};

export default PrivateLayout;
