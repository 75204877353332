// import useHelpDrawer from "src/hooks/private/useHelpDrawer";
// import useTeamContext from "src/hooks/private/useTeamContext";
import getHostName from "src/helpers/getAppFromHost";
import Upgrade from "../navigation/desktop/Upgrade";
// import SubscriptionDescription from "../stripe/SubscriptionChoice";
// import useAuth from "src/hooks/useAuth";
import useSubscriptionContext from "src/hooks/private/useSubscription";
import useApp from "src/hooks/useAppContext";

const SubscriptionsHelp = () => {
  // const { helpLink } = useHelpDrawer();

  const { app } = useApp();

  const { appSubscriptions } = useSubscriptionContext();

  let subscriptionLevels = "";
  if (appSubscriptions) {
    const levels = appSubscriptions.map((sub) => sub.name);
    if (levels.length > 1) {
      const lastLevel = levels.pop();
      subscriptionLevels = levels.join(", ") + " and " + lastLevel;
    } else {
      subscriptionLevels = levels[0];
    }
  }

  return (
    <>
      <div>
        <p>
          {app?.name || getHostName()} has {appSubscriptions?.length} different
          subscription levels, {subscriptionLevels}. The chart below shows
          features available with each level.
        </p>
        <br></br>
        {/* <SubscriptionDescription
          activeSub={team?.subscription || "pro"}
          setActiveSub={() => {}}
          displayLite={false}
        /> */}
        <div>
          <Upgrade />
        </div>
      </div>
    </>
  );
};

export default SubscriptionsHelp;
