import React, { useEffect, useState } from "react";
import Property from "src/interfaces/property";
import useOffers from "src/hooks/private/useOffers";
import useTimeAgo from "src/hooks/useTimeAgo";
import useViewport from "src/hooks/useViewport";
import DownloadButton from "../buttons/DownloadButton";
import { offerApi, propertyApi } from "src/api";
import useDebtStacks from "src/hooks/private/useDebtStacks";

interface DocumentsTableProps {
  property: Property;
}

const DocumentsTable = ({ property }: DocumentsTableProps) => {
  const { id } = property;

  const { isMobile } = useViewport();

  const { timeAgo } = useTimeAgo();

  const { offers, fetchAOffers } = useOffers();

  const { debtStacks } = useDebtStacks(property.id, true);

  const [documents, setDocuments] = useState<any[]>([]);

  useEffect(() => {
    if (id) {
      fetchAOffers(id);
    }
  }, [property, id, fetchAOffers]);

  useEffect(() => {
    const newDebtStacks = debtStacks.map((debt) => {
      debt.type = "Debt Stack";
      return debt;
    });
    const newOffers = offers.map((offer) => {
      offer.type = "Offer";
      return offer;
    });
    const mergeDocuments = [...newDebtStacks, ...newOffers];
    setDocuments(mergeDocuments);
  }, [offers, debtStacks]);
 
  const handleDownload = (doc: any) => {
    if(doc.type === "Offer"){
      const link = offerApi.preview(doc.id, "pdf", property);
      window.open(link, "_blank");
    } else if(doc.type === "Debt Stack") {
      const link = propertyApi.previewDebtStackLink(doc.id, doc?.title, property?.fullAddress);
      window.open(link, "_blank");
    }
  }

  return (
    <>
      <table
        className={
          isMobile || offers.length > 5
          ? "block table table-compact w-full shadow-lg rounded-lg"
          : "block table w-full shadow-lg rounded-lg"
        }
      >
        <thead className="">
          <tr className="sticky top-0 text-secondary border-b-2 border-secondary">
            <th className="sticky top-0 bg-card-light dark:bg-card-dark text-xs sm:text-sm">
              When
            </th>
            <th className="sticky top-0 bg-card-light dark:bg-card-dark text-xs sm:text-sm">
              Type
            </th>
            <th className="sticky top-0 bg-card-light dark:bg-card-dark m">
              Document
            </th>
          </tr>
        </thead>
        <tbody>
          {documents.length ? (
            documents?.sort(
              (a: any, b: any) =>
                new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            )?.map((doc) => (
              <tr key={doc.id}>
                <td className="bg-card-light dark:bg-card-dark text-text-dark dark:text-text-light text-left font-semibold text-xs sm:text-sm">
                  <small className="italic">{timeAgo(doc.createdAt)}</small>
                </td>
                <td className="bg-card-light dark:bg-card-dark text-text-dark dark:text-text-light text-left font-semibold text-xs sm:text-sm">
                  {doc.isThreeTiered ? "Three Tiered Offer" : doc?.type}
                </td>
                <td className="bg-card-light dark:bg-card-dark text-left text-xs sm:text-sm">
                  <DownloadButton onClick={() => handleDownload(doc)} />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td
                className="text-center bg-card-light dark:bg-card-dark text-text-dark dark:text-text-light text-xs xs:text-sm sm:text-base font-semibold"
                colSpan={3}
              >
                No documents for this property yet
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default DocumentsTable;
