import React, { useState } from "react";
import iPhone_install from "src/assets/videos/iOS_install.mp4";
import Android_install from "src/assets/videos/android_install.mp4";
import "./MobileHelp.css";

const MobileHelp = () => {
  const [mobileType, setMobileType] = useState<"android" | "iphone">("android");

  const selectAndroid = () => {
    setMobileType("android");
  };

  const selectiPhone = () => {
    setMobileType("iphone");
  };

  return (
    <>
      <div className="mb-4 flex w-full items-center justify-center gap-3">
        <button
          value="android"
          onClick={selectAndroid}
          className={
            mobileType === "android"
              ? "btn-secondary btn-sm btn normal-case text-text-light hover:bg-secondary"
              : "btn-ghost btn-sm btn normal-case text-base-100 dark:text-text-light"
          }
        >
          Android
        </button>
        <button
          value="iphone"
          onClick={selectiPhone}
          className={
            mobileType === "iphone"
              ? "btn-secondary btn-sm btn normal-case text-text-light hover:bg-secondary"
              : "btn-ghost btn-sm btn normal-case text-base-100 dark:text-text-light"
          }
        >
          iPhone
        </button>
      </div>
      <div
        style={{ position: "relative", width: "100%", paddingTop: "" }}
      >
        <video
          className={`video ${
            mobileType === "android" ? "video-visible" : "video-hidden-right"
          }`}
          controls
          style={{ position: "absolute", top: 0, left: 0 }}
        >
          <source src={Android_install} type="video/mp4" />
          Your browser does not support this video format.
        </video>
        <video
          className={`video ${
            mobileType === "iphone" ? "video-visible" : "video-hidden-left"
          }`}
          controls
          style={{ position: "absolute", top: 0, left: 0 }}
        >
          <source src={iPhone_install} type="video/mp4" />
          Your browser does not support this video format.
        </video>
      </div>
    </>
  );
};

export default MobileHelp;
