import { faCalendarDays } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface SequenceCadenceProps {
  cadence: number;
  onChange: any;
}

const SequenceCadence = ({ cadence, onChange }: SequenceCadenceProps) => {
  return (
    <>
      <div className="relative h-full rounded-md bg-card-light py-2 px-4 shadow-xl dark:bg-card-dark lg:w-1/2">
        <div className="mb-2 flex w-full items-center justify-between md:justify-around lg:justify-center lg:gap-12">
          <div className="">
            <span className="text-sm font-light  text-text-dark dark:text-text-light xs:text-base sm:text-xl">
              Send mail how often?
            </span>
          </div>
          <div className="flex gap-2 text-xl text-text-dark dark:text-text-light">
            <div>
              <FontAwesomeIcon
                icon={faCalendarDays}
                className="text-neutral-400 dark:text-neutral-400"
              />
            </div>
            <div className="w-4">
              <span className="font-semibold">{cadence}</span>
            </div>
          </div>
        </div>
        <div className="mb-2 flex w-full items-center justify-between md:justify-around lg:justify-center lg:gap-12">
          <input
            name="numDays"
            type="range"
            min={1}
            max={8}
            value={cadence}
            onChange={onChange}
            className="range range-secondary"
          />
        </div>
      </div>
    </>
  );
};

export default SequenceCadence;
