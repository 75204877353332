import { Link } from "react-router-dom";

import logoLight from "src/assets/images/SendFuse_Logo_Light.png";
import logoDark from "src/assets/images/SendFuse_Logo_Dark.png";

import SideNavItems from "./SideNavItems";
import useTheme from "src/hooks/useTheme";
import useApp from "src/hooks/useAppContext";
import getHostName from "src/helpers/getAppFromHost";

const SideBarNav = () => {

  const { mode } = useTheme();

  const { app } = useApp();
  return (
    <>
      <nav className="fixed left-0 top-0 bottom-0 bg-card-light dark:bg-card-dark w-72 py-4 px-6">
        <div>
          <Link to="/dashboard">
            <div className="mx-auto flex items-center justify-center">
              <img src={mode === "light" ? logoLight : logoDark} alt={`${app?.name || getHostName()} Logo`} className="my-2"/>
            </div>
          </Link>
          <hr className="my-4 min-w-full border-icon-light dark:border-icon-dark" />
          <SideNavItems />
        </div>
      </nav>
    </>
  );
};

export default SideBarNav;
