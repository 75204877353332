import axios from 'axios';

const axiosInt = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});

axiosInt.interceptors.response.use(
  (response) => {
    // console.log(response.data);
    if (
      Array.isArray(response.data) &&
      response.data[0] === "DENY" &&
      response.data[1]?.code === 401 &&
      response.data[1]?.type === "invalid token") {
        forceLogout();
        return false;
    }
    return response;
  },
  (error) => {
    console.log(error.response);
    if (
      error?.response?.data?.code === 401 &&
      error?.response?.data?.type === 'invalid token' && 
      (error?.response?.data?.message.includes('jwt expired') || error?.response?.data?.message.includes('invalid signature'))) {
      forceLogout();
    }
    return Promise.reject((error.response && error.response.data) || 'Service Unavailable: Our servers are currently down. Please try again later.');
  }
);

const forceLogout = () => {
    localStorage.setItem("x-access-token", "");
    window.location.href = "/login";
}

export default axiosInt;
