import { useState } from "react";
import { UserType } from "src/interfaces/user";
import UserAvatar from "../buttons/UserAvatar";
import EmailTeammate from "src/components/buttons/EmailTeammate";
import ResendInvite from "../buttons/ResendInvite";
import useUserContext from "src/hooks/private/useUserContext";
import EditButton from "../buttons/EditButton";
import EditTeammateModal from "./EditTeammateModal";
interface TeammateModalProps {
  teammate: UserType;
}

const TeammateModal = ({ teammate }: TeammateModalProps) => {
  const { user } = useUserContext();

  const [isEditing, setIsEditing] = useState<boolean>(false);

  return (
    <>
      <div>
        {isEditing ? (
          <EditTeammateModal teammate={teammate} />
        ) : (
          <>
            <div className="mb-5 flex w-full items-center justify-center gap-2">
              <UserAvatar
                userID={teammate.id}
                disabledClick={true}
                trim={false}
              />
              {user?.isLeader && teammate.id !== user.id && (
                <EditButton onClick={() => setIsEditing(true)} />
              )}
            </div>
            <div className="flex w-full items-center justify-center gap-1">
              <EmailTeammate teammate={teammate} />
            </div>

            {!teammate.hasAcceptedInvite && (
              <div className="mt-3 flex w-full items-center justify-center gap-1">
                <ResendInvite email={teammate.email} />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default TeammateModal;
