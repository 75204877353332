import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";
import useHelpDrawer from "src/hooks/private/useHelpDrawer";
import useModal from "src/hooks/useModal";


interface QuestionButtonProps {
  helpTitle: string;
  helpBody: ReactNode;
}

const QuestionButton = ({ helpTitle, helpBody }: QuestionButtonProps) => {
  const { openHelpDrawerWith } = useHelpDrawer();

  const { setShowModal } = useModal();

  const handleHelp = () => {
    setShowModal(false)
    openHelpDrawerWith({
      title: helpTitle,
      body: helpBody,
    });
  };

  return (
    <button
      className="btn btn-circle btn-sm bg-card-dark dark:bg-back-dark text-text-light hover:bg-black hover:dark:bg-black  dark:text-gray-500 cursor-pointer"
      onClick={handleHelp}
    >
      <FontAwesomeIcon className="text-lg cursor-pointer" icon={faInfo} />
    </button>
  );
};

export default QuestionButton;
