import { skiptraceApi } from "src/api";
import useDrawer from "src/hooks/private/useDrawer";
import useSkipTraceContext from "src/hooks/private/useSkipTraceContext";
import useSubscriptionContext from "src/hooks/private/useSubscription";
import useTeamContext from "src/hooks/private/useTeamContext";
import useAlert from "src/hooks/useAlert";
import useModal from "src/hooks/useModal";
import useViewport from "src/hooks/useViewport";
import Property from "src/interfaces/property";
import Button from "../buttons/Button";
import SubscriptionsHelp from "../help/SubscriptionsHelp";
import UpgradeModal from "./UpgradeModal";

interface SkipTraceModalProps {
  property: Property;
}

const SkipTraceModal = ({ property }: SkipTraceModalProps) => {
  const { id } = property;

  const { isMobile } = useViewport();

  const { setShowModal, openModalWith } = useModal();

  const { setAlert } = useAlert();

  const { showPropertyDrawer, refreshProperty } = useDrawer();

  const { fetchUserTeamData } = useTeamContext();

  const { fetchSkipTraces } = useSkipTraceContext();

  const { subscriptionFeatures } = useSubscriptionContext();

  const extra =
    subscriptionFeatures?.["SkipTrace"].accessType === "fiat"
      ? subscriptionFeatures?.["SkipTrace"].fiatCost.toFixed(2)
      : 0;

  const handleSubmit = async () => {
    setShowModal(false);
    setAlert({
      display: true,
      message:
        isMobile || showPropertyDrawer
          ? `Searching for Skip Trace`
          : `Searching for Skip Trace, please stand by`,
      type: "info",
    });
    try {
      await skiptraceApi.orderSkipTrace(id);
      setAlert({
        display: true,
        message:
          isMobile || showPropertyDrawer
            ? "Skip Trace Successful"
            : `Successfully found Skip Trace Data for ${property.fullAddress}`,
        type: "success",
      });
    } catch (err) {
      setShowModal(false);
      setAlert({
        display: true,
        message:
          isMobile || showPropertyDrawer
            ? "Unable to Skip Trace"
            : `Unable to find Skip Trace info for ${property.fullAddress}`,
        type: "error",
      });
    } finally {
      // fetchProperties();
      fetchUserTeamData();
      fetchSkipTraces();
      refreshProperty();
    }
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleSubscriptionUpgrade = () => {
    openModalWith({
      title: "Upgrade Subscription",
      body: <UpgradeModal mustFullyUpgrade={true} featureClicked={"SkipTrace"} />,
      hideButtons: true,
      helpTitle: "Subscriptions",
      helpBody: <SubscriptionsHelp />,
    });
  };

  return (
    <>
      {extra !== 0 && (
        <div className="mb-4 flex items-center justify-center">
          <div>Request the Skip Trace for ${extra} or &nbsp;</div>
          <p
            onClick={handleSubscriptionUpgrade}
            className="cursor-pointer underline hover:opacity-70"
          >
            upgrade your subscription
          </p>
        </div>
      )}
      <div className="flex items-center justify-center">
        {subscriptionFeatures?.["SkipTrace"].accessType === "fiat" ? (
          <>
            <Button
              className="btn-ghost btn mr-1 w-32 text-lg font-bold text-text-dark hover:bg-blue-200 dark:text-text-light hover:dark:bg-gray-900"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className="btn ml-1 w-32 bg-secondary text-lg font-bold text-text-light hover:bg-accent hover:drop-shadow-lg dark:text-text-dark"
              onClick={handleSubmit}
            >
              Submit {extra !== 0 ? `$${extra}` : ""}
            </Button>
          </>
        ) : (
          <>
            <Button
              className="btn-ghost btn mr-1 w-32 text-lg font-bold text-text-dark hover:bg-blue-200 dark:text-text-light hover:dark:bg-gray-900"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className="btn ml-1 w-32 bg-secondary text-lg font-bold text-text-light hover:bg-accent hover:drop-shadow-lg dark:text-text-dark"
              onClick={handleSubmit}
            >
              Submit {extra !== 0 ? `$${extra}` : ""}
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default SkipTraceModal;
