import React from "react";
import useWatchlist from "src/hooks/private/useWatchlist";
import useModal from "src/hooks/useModal";
import useViewport from "src/hooks/useViewport";
import { Watchlist as WatchlistType } from "src/interfaces/watchlist";
import DeleteButton from "src/components/buttons/DeleteButton";
import WatchlistHelp from "src/components/help/WatchlistHelp";
import useAlert from "src/hooks/useAlert";
import { watchlistApi } from "src/api";
import ResearchAddressButton from "src/components/buttons/ResearchAddressButton";
import useTimeAgo from "src/hooks/useTimeAgo";
import useUserContext from "src/hooks/private/useUserContext";


const WatchlistBirddog = () => {

  const { openModalWith, setShowModal } = useModal();

  const { watchlist, fetchWatchlist } = useWatchlist();

  const { setAlert } = useAlert();

  const { timeAgo } = useTimeAgo();

  const { isLayoutChange } = useViewport();

  const { isMobile, width } = useViewport();

  const { user } = useUserContext();

  const birddogsWatchlist = watchlist?.filter((item: any) => (
    item.userID === user?.id
  ))

  const handleDelete = (id: number) => {
    openModalWith({
      title: "Remove From Watchlist",
      body: "Are you sure you want to delete this address from your Watchlist?",
      onSubmit: () => handleDeleteSubmit(id),
      submitLabel: "Delete",
      helpTitle: "Watchlist",
      helpBody: <WatchlistHelp />,
    });
  };

  const handleDeleteSubmit = async (id: number) => {
    try {
      setShowModal(false);
      const result = await watchlistApi.deleteWatchlist(id);
      if (result) {
        setAlert({
          display: true,
          message: "Watchlist Item deleted",
          type: "success",
        });
      }
    } catch (err: any) {
      setAlert({
        display: true,
        message: "An error occurred deleting this Watchlist item",
        type: "error",
      });
    } finally {
      return fetchWatchlist();
    }
  };

  return (
    <>
      <div>
        <table
          className={
            isLayoutChange
              ? "table-compact block table w-full shadow-xl"
              : "block table w-full shadow-xl"
          }
        >
          <thead>
            <tr className="sticky top-0 border-separate border-b-2 border-secondary text-secondary">
              {width > 440 && (
                <th className="sticky top-[115px] select-none bg-card-light text-center text-xxs  dark:bg-card-dark md:text-base lg:top-0">
                  Created
                </th>
              )}
              <th className="sticky top-[115px] select-none bg-card-light text-left text-xxs  dark:bg-card-dark md:text-base lg:top-0">
                Address
              </th>
              <th className="sticky top-[115px] select-none bg-card-light text-center text-xxs  dark:bg-card-dark md:text-base lg:top-0">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="">
            {birddogsWatchlist?.length ? (
              birddogsWatchlist?.map((address: WatchlistType) => (
                <tr
                  key={address.id}
                  className="bg-card-light dark:bg-card-dark"
                >
                  {width > 440 && (
                    <td className="bg-card-light text-center text-xxs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light md:text-base">
                      <span className="">
                        {timeAgo(address.createdAt)}
                      </span>
                    </td>
                  )}
                  <td className="bg-card-light text-left font-bold text-text-dark dark:bg-card-dark dark:text-text-light">
                    <ResearchAddressButton
                      address={address.fullAddress}
                      placeID={address.placeID}
                      isSmall={isMobile}
                    />
                  </td>
                  <td className="flex items-center justify-center gap-2 bg-card-light text-center dark:bg-card-dark">
                    <DeleteButton
                      onClick={() => handleDelete(address.id)}
                      isSmall={isMobile}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  className="bg-card-light text-center text-xs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light xs:text-sm sm:text-base"
                  colSpan={4}
                >
                  No Watchlist Items Yet
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default WatchlistBirddog;