import { HelpDrawerOptionsProps } from "src/contexts/private/HelpDrawerContext";
import useHelpDrawer from "src/hooks/private/useHelpDrawer";
import useOnclickOutside from "react-cool-onclickoutside";
import BackButton from "../buttons/BackButton";
import logoLight from "src/assets/images/SendFuse_Logo_Light.png";
import logoDark from "src/assets/images/SendFuse_Logo_Dark.png";
import useTheme from "src/hooks/useTheme";
import { motion, AnimatePresence } from "framer-motion";
import useApp from "src/hooks/useAppContext";
import getHostName from "src/helpers/getAppFromHost";

const HelpDrawer = ({ title, body }: HelpDrawerOptionsProps) => {
  const {
    showHelpDrawer,
    setShowHelpDrawer,
    helpHistory,
    setHelpHistory,
    backLink,
  } = useHelpDrawer();

  const ref = useOnclickOutside(() => {
    setShowHelpDrawer(false);
    setHelpHistory([]);
  });

  const backGroundColor = "rgba(0,0,0,0.6)";

  const { mode } = useTheme();
  const { app } = useApp();

  return (
    <>
      <input
        type="checkbox"
        id="help-modal"
        checked={showHelpDrawer}
        onChange={() => {}}
        className="modal-toggle"
      />
      <label
        htmlFor="help-modal"
        className="modal justify-start"
        style={{
          backgroundColor: backGroundColor,
        }}
      >
        <div
          ref={ref}
          className="modal-box max-w-none w-10/12 sm:w-9/12 md:w-8/12 lg:w-7/12 xl:w-5/12 2xl:w-4/12 h-full max-h-screen rounded-none bg-back-light dark:bg-back-dark"
        >
          {" "}
          <div className="flex items-center justify-between">
            <img
              src={mode === "light" ? logoLight : logoDark}
              alt={`${app?.name || getHostName()} Logo`}
              className="w-1/2"
            />
            <div className="h-8 md:h-11 w-0.5 bg-primary"></div>
            <div className="flex flex-col columns-1 justify-end items-end h-full mt-2.5">
              <span className="text-text-dark dark:text-text-light text-sm sm:text-base md:text-[24px] font-medium italic">
                Resource Center
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <h3 className="text-lg lg:text-xl font-bold text-primary capitalize">
              {title}
            </h3>
            <div className="invisible">
              <BackButton onClick={() => {}} />
            </div>
            <AnimatePresence>
              {helpHistory.length > 1 && (
                <motion.div
                  initial={{ x: 15 }}
                  animate={{ x: 0 }}
                  exit={{ opacity: 0 }}
                  className={""}
                >
                  <BackButton onClick={backLink} />
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <div className="divider my-0.5"></div>
          <div className="text-sm text-text-dark dark:text-text-light font-normal">
            {body}
          </div>
        </div>
      </label>
    </>
  );
};

export default HelpDrawer;
