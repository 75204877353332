import {
  faDollarSign,
  faEnvelope,
  faFileInvoiceDollar,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import Property from "src/interfaces/property";
import ActionButton from "src/components/buttons/ActionButtons";
import CogoCapitalButton from "src/components/buttons/CogoCapitalButton";
import useModal from "src/hooks/useModal";
import InsufficientFundsModal from "src/components/modals/InsufficientFundsModal";
import DebtStacksHelp from "src/components/help/DebtStacksHelp";
import FuseCoinsHelp from "src/components/help/FuseCoinsHelp";
import SkipTracesHelp from "src/components/help/SkipTracesHelp";
import SkipTraceResults from "src/components/modals/SkipTraceResultsModal";
import SequencesHelp from "src/components/help/SequencesHelp";
import OffersHelp from "src/components/help/OffersHelp";
import UpgradeModal from "src/components/modals/UpgradeModal";
import SubscriptionsHelp from "src/components/help/SubscriptionsHelp";
import OfferModal from "src/components/modals/OfferModal";
import SequenceModal from "src/components/modals/SequenceModal";
import useTeamContext from "src/hooks/private/useTeamContext";
import DebtStackModal from "src/components/modals/DebtStackModal";
import useAuth from "src/hooks/useAuth";
import useSubscriptionContext from "src/hooks/private/useSubscription";
import { SubscriptionFeature } from "src/interfaces/subscriptions";
import { ModalOptionsProps } from "src/contexts/ModalContext";
import SkipTraceModal from "../modals/SkipTraceModal";

interface ActionPanelProps {
  property: Property;
  isFullWidth: boolean;
}

const ActionPanel = ({ property, isFullWidth }: ActionPanelProps) => {
  const { isSkipTrace } =
    property;


  const { openModalWith } = useModal();

  const { checkCoinBalance } = useTeamContext();

  const { currentUser } = useAuth();

  const { subscriptionFeatures } = useSubscriptionContext();
  
  // const { campaigns } = useCampaignContext();
  
  /* const filteredCampaigns = campaigns.filter(
    (campaign: Campaign) =>
      campaign.target === (ownerOccupied ? "occupied" : "absentee")
  ); */
  
  /*const insufficientFunds = () => {
    openModalWith({
      title: "Insufficient FuseCoins",
      body: <InsufficientFundsModal />,
      hideButtons: true,
      helpTitle: "FuseCoins",
      helpBody: <FuseCoinsHelp />,
    });
  }; */

  const checkFeatureAvailability = (
    featureName: string,
    modal: ModalOptionsProps
  ) => {
    const feature: SubscriptionFeature | undefined = subscriptionFeatures?.[featureName];

    if (!feature || feature.accessType === "none") {
      openModalWith({
        title: "Upgrade Subscription",
        body: (
          <UpgradeModal hideExplanation={true} featureClicked={featureName} />
        ),
        hideButtons: true,
        helpTitle: "Subscriptions",
        helpBody: <SubscriptionsHelp />,
      });
    } else if (!checkCoinBalance(feature.coinCost)) {
      if (feature.accessType === "fiat") {
        openModalWith(modal);
      } else {
        openModalWith({
          title: `Insufficient ${currentUser?.coinName}`,
          body: <InsufficientFundsModal />,
          hideButtons: true,
          helpTitle: `${currentUser?.coinName}`,
          helpBody: <FuseCoinsHelp />,
        });
      }
    } else {
      openModalWith(modal);
    }
  };

  const handleDebtStack = () => {
    checkFeatureAvailability("DebtStack", {
      title: "Order Debt Stack",
      property,
      body: <DebtStackModal property={property} />,
      hideButtons: true,
      helpTitle: "Debt Stacks",
      helpBody: <DebtStacksHelp />,
    });
  };

  const handleSkipTrace = () => {
    if (isSkipTrace) {
      openModalWith({
        title: "Skip Trace Results",
        property,
        body: <SkipTraceResults property={property} />,
        hideButtons: true,
        helpTitle: "Skip Traces",
        helpBody: <SkipTracesHelp />,
      });
    } else {
      checkFeatureAvailability("SkipTrace", {
        title: "Request Skip Trace",
        property,
        body: <SkipTraceModal property={property} />,
        hideButtons: true,
        helpTitle: "Skip Traces",
        helpBody: <SkipTracesHelp />,
      });
    }
  };

  const handleSequence = () => {
    checkFeatureAvailability("Sequence", {
      title: "Start Sequence",
      property,
      hideButtons: true,
      body: <SequenceModal property={property} />,
      helpTitle: "Sequences",
      helpBody: <SequencesHelp />,
    });
  };

  const handleOffer = async () => {
    checkFeatureAvailability("Offer", {
      title: "Create An Offer",
      property,
      hideButtons: true,
      body: <OfferModal property={property} />,
      helpTitle: "Offers",
      helpBody: <OffersHelp />,
    });
  };

  return (
    <>
      <div className="h-full w-full rounded-xl ">
        <div
          className={
            isFullWidth
              ? "flex w-full items-center justify-between gap-0.5"
              : "grid grid-cols-2 grid-rows-3 gap-0.5"
          }
        >
          <div className={isFullWidth ? "w-1/5" : "w-full"}>
            <ActionButton
              icon={faFileInvoiceDollar}
              label={"Debt Stack"}
              onClick={handleDebtStack}
              className={
                "btn-sm h-12 w-full  border-none bg-theme5 font-bold text-text-light hover:bg-theme5 dark:text-text-dark "
              }
            />
          </div>
          <div className={isFullWidth ? "w-1/5" : "w-full"}>
            <ActionButton
              icon={faPhone}
              label={"Skip Trace"}
              onClick={handleSkipTrace}
              className={
                "btn-sm h-12 w-full border-none bg-theme4 font-bold text-text-light hover:bg-theme4 dark:text-text-dark"
              }
            />
          </div>
          <div className={isFullWidth ? "w-1/5" : "w-full"}>
            <ActionButton
              icon={faEnvelope}
              label={"Sequence"}
              onClick={handleSequence}
              className={
                "btn-sm h-12 w-full border-none  bg-theme2 font-bold text-text-light hover:bg-theme2 dark:text-text-dark "
              }
            />
          </div>
          <div className={isFullWidth ? "w-1/5" : "w-full"}>
            <ActionButton
              icon={faDollarSign}
              label={"Offer"}
              onClick={handleOffer}
              className={
                "btn-sm h-12 w-full border-none bg-theme3 font-bold text-text-light hover:bg-theme3 dark:text-text-dark"
              }
            />
          </div>
          <CogoCapitalButton
            className={isFullWidth ? "w-1/5" : "col-span-2 w-full"}
          />
        </div>
      </div>
    </>
  );
};

export default ActionPanel;
